import React, { useContext } from "react";
import Router from "./router/Router";
import { LanguageContext } from "./context/LanguageContext";
import "./Styles/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "antd/dist/antd.css";
import { Route, Routes } from "react-router-dom";
import PaymentResult from "./layout/PaymentResult";

function App() {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  return (
    <div
      className={
        userLanguage === "en"
          ? "App__en"
          : userLanguage === "fa"
          ? "App__fa"
          : "App__fa"
      }
    >
      {/* <Register /> */}
      <Router />
    </div>
  );
}

export default App;
