import axios from "axios";
const API_URL = "https://aytameamiralmomenin.com/api/";
class RegionService {
  getProvinces = (province_id) => {
    return axios.get(API_URL + `${province_id}/provinces`).catch((error) => {
      if (error) {
        return error;
      }
    });
  };
  getCities = (city_id) => {
    return axios.get(API_URL + `${city_id}/cities`).catch((error) => {
        if (error) {
          return error;
        }
      });
  }
}




export default new RegionService();
