import React from "react";
import Purple from "../assets/img/icons/user-purple.svg";
import blue from "../assets/img/icons/users-blue.svg";
import pink from "../assets/img/icons/heart-red.svg";
import { Text } from "../context/LanguageContext";
import Button from "../Components/shared/Button";
const DashboardHome = () => {
  return (
    <div className="">
      <div className="container">
        <div className="grid grid-cols-3 gap-[20px] grid-cols-1-responsive ">
          <div className="HomeNum__box DashboardHome__box DashboardHome__box--1">
            <div className="HomeNum__circle  ">
              <img src={Purple} alt="" />
            </div>
            <div className="HomeNum__box--num font-bold">1252</div>
            <div className="HomeNum__box--title"> <Text tid='Active users' /> </div>
            <Button varient="primary"><Text tid="Read more"/></Button>
          </div>
          <div className="HomeNum__box  DashboardHome__box DashboardHome__box--2 ">
            <div className="HomeNum__circle ">
              <img src={pink} alt="" />
            </div>
            <div className="HomeNum__box--num font-bold">741</div>
            <div className="HomeNum__box--title"><Text tid='number of orphans' /></div>
            <Button varient="primary"><Text  tid="Read more" /></Button>

          </div>
          <div className="HomeNum__box  DashboardHome__box DashboardHome__box--3  ">
            <div className="HomeNum__circle ">
              <img src={blue} alt="" />
            </div>
            <div className="HomeNum__box--num font-bold">110</div>
            <div className="HomeNum__box--title"><Text tid='number of sponsors' /> </div>
            <Button varient="primary"><Text  tid="Read more"/></Button>

          </div>
        </div>
      </div>
    </div>
  );
};


export default DashboardHome