import React from "react";
import Slider from "react-slick";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
const HomeNewsSlider = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "0",

    //initialSlide: 1,

    responsive: [
      {
        breakpoint: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        settings: {},
      },
      {
        breakpoint: 400,
        settings: {
          centerPadding: "100px",
        },
      },
    ],
  };

  return (
    <div className="HomeNewsSlider mb-8">
      <Slider {...settings}>
        {slides.map((slide, index) => {
          return (
            <div
            key={slide.id}
              className="HomeNewsSlider__slides"
            >
              <img src={slide.path} alt={slide.title} />
            </div>
          )
        } 
        )}
      </Slider>
    </div>
  );
};

export default HomeNewsSlider;
