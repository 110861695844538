import React from 'react'
import warnin from "../../../../assets/img/icons/redwarning.svg";
import profile from "../../../../assets/img/icons/person-people-girl-portrait-model-child-730652-pxhere.com.png";

const KafilTablePopuprow = () => {
    return (
      <div className='d-flex-space p-[10px] border-b-solid border-b-[1px]  border-b-[#D8E7EF]'>
        <div className='d-flex-align'>
        <div className=" relative ">
          <div className="rounded-[50%] w-[20px] center h-[20px] bg-[#FF1D25] text-[#fff] absolute right-0 top-0">
            <div>1</div>
          </div>
          <img
            className="rounded-[50%] w-[50px] h-[50px] border-[2px] border-[#fff] overflow-hidden"
            src={profile}
          />
        </div>
        <div className="mr-4 text-right">
          <div
            className={`
             text-[#313E45] text-[20px]`}
          >
            سادات یتیم شماره یک
          </div>
          <div
            className={` text-[#6F838E] text-[16px]`}
          >
            من سه ماه پول دادم
          </div>
        </div>
        </div>
        <div className='d-flex-space'>
            <img src={warnin} className='ml-8'/>
            <div>سه ماه است که حق سرپرستی پرداخت نشده است</div>
        </div>
      </div>
    )
  }
const KafilTablePopup = () => {
  return (
    <div>
        <h3 className="text-[#313E45] text-[26px]">کودکان بی سرپرست منتظر کمک شما هستند</h3>
        <KafilTablePopuprow />
        <KafilTablePopuprow />
        <KafilTablePopuprow />
<div className='center'>        <button className=' Tag mt-[20px] KafilTablePopup--button'>فهمیدم</button>
</div>
    </div>
  )
}

export default KafilTablePopup