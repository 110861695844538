import React,{useState,useEffect} from 'react';
import CodeRagister from '../Components/layouts/register/CodeRagister';
import PhoneRegister from '../Components/layouts/register/PhoneRegister';
import axios from 'axios';

const Register = ({setOpen}) => {
    const [step, setStep] = useState(1);
    const [username , setUsername] = useState("");
    const [message , setMessage] = useState("");
    useEffect(() => {
      setOpen(false)
    }, [])
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  return (
   <div className='center my-5'>
     <div className='registerBox'>
       {
           step === 1 ? 
              <PhoneRegister setStep={setStep} setUsername = {setUsername} setMessage={setMessage} />
              : <CodeRagister setStep={setStep} username={username} message={message} />
       }
     </div>

   </div>
  )
}

export default Register