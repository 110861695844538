import { useState } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { useEffect } from "react";
import { useContext } from "react";

const useMultiDate = (date , yearFormat , monthFormat , dayFormat ) => {
    const { userLanguage } = useContext(LanguageContext);
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    useEffect(() =>{
        let locale;
        switch (userLanguage) {
          case "fa":
            locale = "fa-IR";
            break;
          case "en":
            locale = "en-US";
            break;
          case "ar":
            locale = "ar-IQ";
            break;
    
          default:
            locale = "fa-IR";
            break;
        }
        const _date = new Date(date)
        setYear(_date.toLocaleDateString(locale, { year: yearFormat }));
        setMonth(_date.toLocaleDateString(locale, { month: monthFormat }));
        setDay(_date.toLocaleDateString(locale, { day: dayFormat}));

    },[date])

    return(`${year}/${month}/${day}`)
}

export default useMultiDate;