import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "../Components/shared/Input";
import UploadProfile from "../Components/shared/UploadProfile";
import copy from "../assets/img/icons/copy.svg";
import { SyncOutlined } from "@ant-design/icons";
import Button from "../Components/shared/Button";
import { LanguageContext, Text, TextFunc } from "../context/LanguageContext";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import SponsorService from "../Services/SponsorService";
import SelectSearchable from "../Components/shared/SelectSearchable";
import RegionService from "../Services/RegionService";
import AuthService from "../Services/AuthService ";
import { useNavigate } from "react-router-dom";
// import calende from "../assets/img/icons/calender.svg";
const EditSponsor = () => {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const codeRef = useRef();
  const [copied, setCopied] = useState(false);
  const [dateType, setDateType] = useState({ calender: "", locale: "" });
  const [data, setData] = useState(null);
  const [provinces, setProvinces] = useState(null);
  const [cities, setCities] = useState(null);
  const [userLanguages, setUserLanguages] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [groupList, setGroupList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({});

  useEffect(() => {
    window.scrollTo(0, 0);
    const getSponsor = async () => {
      setLoading(true);
      const res = await SponsorService.getSponsor();
      if (res?.status === 200) {
        setData(res?.data?.data);
        setProvinces(res?.data?.data?.prvinceList);
        setCities(res?.data?.data?.cityList);
        setUserLanguages(res?.data?.data?.user_languages);
        setLanguages(res?.data?.data?.languages);
        setGroupList(JSON.parse(res?.data?.data?.groupList.value));
        setValue("country", {
          value: res?.data?.data?.country?.id,
          label: res?.data?.data?.country?.name,
        });
        setValue("province", {
          value: res?.data?.data?.province?.id,
          label: res?.data?.data?.province?.name,
        });
        setValue("city", {
          value: res?.data?.data?.city?.id,
          label: res?.data?.data?.city?.name,
        });
        setValue("lang", {
          value: res?.data?.data?.language?.id,
          label: `${res?.data?.data?.language?.name}`,
        });
        setValue("user_language", {
          value: res?.data?.data?.user_language.id,
          label: res?.data?.data?.user_language?.name,
        });
        setValue("group", {
          value: res?.data?.data?.group,
          label: res?.data?.data?.group,
        });
        setLoading(false);
      } else {
        toast(res?.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    getSponsor();
  }, []);

  const onSubmit = async (sponsorData) => {
    const user = AuthService.getCurrentUser();

    const newData = {
      sponsor_id: data.id,
      user_id: user.user_id,
      user_language: sponsorData.user_language.value,
      email: sponsorData.email,
      avatar: sponsorData.avatar[0] || null,
      city: sponsorData.city.value,
      address: sponsorData.address,
      group: sponsorData.group.value,
      lang: sponsorData.lang.value,
    };
    setBtnLoading(true);
    const res = await SponsorService.editSponsor(newData);
    if (res?.status === 200) {
      toast(res?.data?.message, {
        position: "top-right",
        type: "success",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setBtnLoading(false);
      navigate(0)
    } else {
      toast(res?.message, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setBtnLoading(false);
    }
  };

  const coppyCode = (event) => {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(codeRef.current.innerText);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  };

  const handleChangeCountry = async (country) => {
    const res = await RegionService.getProvinces(country?.value);
    if (res?.status === 200) {
      setProvinces(res?.data?.data);
    } else {
      toast(res?.message, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setValue("province", "");
    setValue("city", "");
  };

  const handleChangeProvince = async (province) => {
    const res = await RegionService.getCities(province?.value);
    if (res?.status === 200) {
      setCities(res?.data?.data);
    } else {
      toast(res?.message, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setValue("city", "");
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : data ? (
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="DashboardInfo-grid grid grid-cols-1-responsive">
              <UploadProfile
                defaultImage={data?.avatar}
                {...register("avatar")}
              />
              <div>
                <Input
                  name="name"
                  control={control}
                  errors={errors}
                  disabled
                  className="Footer__input"
                  defaultValue={`${data?.name} ${data?.surname}`}
                  placeholder={TextFunc("first name and last name")}
                />
                <div className="grid grid-cols-3 grid-cols-1-responsive gap-[5px]">
                  <SelectSearchable
                    name="country"
                    selectClassName={"select_searchable"}
                    handleChange={handleChangeCountry}
                    placeholder={TextFunc("Country")}
                    noOptionsMessage={() => <Text tid="Nothing found" /> }
                    control={control}
                    errors={errors}
                    register={{
                      required: TextFunc("The country field is required"),
                    }}
                    isClearable={true}
                    defaultValue={{
                      value: data?.country?.id,
                      label: data?.country?.name,
                    }}
                    options={data?.countryList.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />

                  <SelectSearchable
                    name="province"
                    selectClassName={"select_searchable"}
                    handleChange={handleChangeProvince}
                    placeholder={TextFunc("State")}
                    noOptionsMessage={() => "موردی یافت نشد."}
                    control={control}
                    errors={errors}
                    register={{
                      required: TextFunc("The province field is required") ,
                    }}
                    isClearable={true}
                    defaultValue={{
                      value: data?.province?.id,
                      label: data?.province?.name,
                    }}
                    options={provinces?.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />

                  <SelectSearchable
                    name="city"
                    selectClassName={"select_searchable"}
                    placeholder={TextFunc("City")}
                    noOptionsMessage={() => "موردی یافت نشد."}
                    control={control}
                    errors={errors}
                    register={{
                      required: TextFunc("The city field is required"),
                    }}
                    isClearable={true}
                    defaultValue={{
                      value: data?.city?.id,
                      label: data?.city?.name,
                    }}
                    options={cities?.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-3 grid-cols-1-responsive gap-[5px]">
              <Input
                name="email"
                control={control}
                errors={errors}
                register={{
                  required: TextFunc("Email field is required") ,
                }}
                className="Footer__input"
                defaultValue={data?.email}
                placeholder={ TextFunc("Email") }
              />
              <SelectSearchable
                name="user_language"
                selectClassName={"select_searchable"}
                placeholder={TextFunc("Language")}
                noOptionsMessage={() => "موردی یافت نشد."}
                control={control}
                errors={errors}
                register={{
                  required: TextFunc("The language field is required") ,
                }}
                isClearable={true}
                defaultValue={{
                  value: data?.user_language?.id,
                  label: `${data?.user_language?.native_name} - ${data?.user_language?.name}`,
                }}
                options={userLanguages?.map((item) => ({
                  value: item.id,
                  label: `${item.native_name}-${item.name}`,
                }))}
              />

              <SelectSearchable
                name="lang"
                selectClassName={"select_searchable"}
                placeholder={TextFunc("Dramatic language")}
                noOptionsMessage={() => "موردی یافت نشد."}
                control={control}
                errors={errors}
                register={{
                  required: TextFunc("Dramatic language field is required"),
                }}
                isClearable={true}
                defaultValue={{
                  value: data?.language?.id,
                  label: `${data?.language?.name}-${data?.language?.symbol}`,
                }}
                options={languages?.map((item) => ({
                  value: item.id,
                  label: `${item.name}-${item?.symbol}`,
                }))}
              />
            </div>
            <div>
              <Input
                name="address"
                control={control}
                errors={errors}
                register={{
                  required: TextFunc("The address field is required"),
                }}
                className="Footer__input"
                defaultValue={data?.address}
                placeholder={
                  userLanguage === "fa"
                    ? "آدرس"
                    : userLanguage === "en"
                    ? "Address"
                    : "عنوان"
                }
              />
            </div>
            <div className="grid grid-cols-2 gap-[5px] my-[5px] grid-cols-1-responsive">
              <SelectSearchable
                name="group"
                selectClassName={"select_searchable"}
                placeholder={TextFunc("group")}
                noOptionsMessage={() => "موردی یافت نشد."}
                control={control}
                errors={errors}
                register={{
                  required: TextFunc("The group field is required"),
                }}
                isClearable={true}
                defaultValue={{
                  value: data?.group,
                  label: data?.group,
                }}
                options={groupList?.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
              <div>
                <div className="flex">
                  <div className="UploadFile__button flex justify-between">
                    <div><Text tid="your code" /> </div>
                    <div ref={codeRef} className="text-[#1375A3] ">
                      {data?.code}
                    </div>
                  </div>

                  <div
                    className="UploadIcon center "
                    onClick={coppyCode}
                    style={{ position: "relative" }}
                  >
                    <img src={copy} />
                    {copied && (
                      <span
                        className="tooltipCopy"
                        style={{ position: "absolute" }}
                      >
                        Copied !
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end my-[20px] res-center">
              <button
                type="reset"
                className={`text-[#6F838E] ${
                  userLanguage == "en" ? "mr-[20px]" : "ml-[20px]"
                } `}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Text tid="Cancel" />
              </button>
              <Button
                varient="primary"
                className="button--green"
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <SyncOutlined style={{ fontSize: "22px" }} spin />
                ) : (
                  <Text tid="Save changes" />
                )}
              </Button>
            </div>
          </form>
        </div>
      ) : null}
      <ToastContainer />
    </>
  );
};

export default EditSponsor;
