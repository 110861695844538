import React from "react";
import { useEffect } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
const LastNewsBox = ({lastnews}) => {
  const { userLanguage } = useContext(LanguageContext);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const navigate = useNavigate()
  useEffect(() => {
    let locale;
    switch (userLanguage) {
      case "fa":
        locale = "fa-IR";
        break;
      case "en":
        locale = "en-US";
        break;
      case "ar":
        locale = "ar-SA";
        break;

      default:
        locale = "fa-IR";
        break;
    }
    if (lastnews) {
      const date = new Date(lastnews.publish_date);
      
      setYear(date.toLocaleDateString(locale, { year: "numeric" }));
      setMonth(date.toLocaleDateString(locale, { month: "long" }));
      setDay(date.toLocaleDateString(locale, { day: "numeric" }));
    }
  } , [])

  const navigateToDetailNews = (id) => {
    navigate({
      pathname: "/news/detail",
      search: `?id=${id}`
    })
  }


  return (
    <div onClick={()=>navigateToDetailNews(lastnews.id)} className="LastNewsBox">
      <img src={lastnews.picture.path} alt="" />
      <div>
        <p className="px-2" style={{overflowWrap : "anywhere" }} > {lastnews.summary} </p>
        <div className="LastNewsBox__date px-2"> <span>{month}</span> <span>{year}</span> </div>
      </div>
    </div>
  );
};

export default LastNewsBox;
