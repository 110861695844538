import React, { useState } from "react";
import { useForm } from "react-hook-form";
import remove from "../assets/img/icons/remove.png";
import { Card, Pagination, Select } from "antd";
import { ReactComponent as Arrow } from "../assets/img/icons/arrow-down.svg";
import MessageTable from "../Components/layouts/Dashboard/Message/MessageTable";
import Button from "../Components/shared/Button";
import { Text } from "../context/LanguageContext";
import Modal from "../Components/shared/ModalStrap";
import MessageDeleteModal from "../Components/layouts/Dashboard/Message/MessageDeleteModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import MessagesService from "../Services/MessagesService";
import { useEffect } from "react";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import AuthService from "../Services/AuthService ";

const { Option } = Select;

const Message = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState({});
  const [current, setCurrent] = useState(1);
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [message, setMessage] = useState("")

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getMessages = async () => {
      setLoading(true);
      const res = await MessagesService.getMessages(searchParams.get("page"));
      if (res?.status === 200) {
        setData(res?.data?.data?.data);
        setPage(res?.data?.data);
        setMessage(res?.data?.message);
        setLoading(false);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    setCurrent(
      searchParams.get("page")
        ? Number(Object.fromEntries([...searchParams]).page)
        : 1
    );
    getMessages();
  }, [searchParams.get("page")]);

  const onChangePage = (pageNumber) => {
    setCurrent(pageNumber);
    navigate({
      pathname: "/dashboard/message",
      search: `?page=${pageNumber}`,
    });
  };

  const handleConfirmDeleteMessage = async () => {
    setIsOpen(false);
    const selectedItemsInPage = selectedItems.filter((selectedItem) =>
      data.find((item) => item.id == selectedItem)
    );
    // setLoading(true);
    // const res = await MessagesService.deleteMessage({messages : selectedItems });
    // if (res?.status === 200) {
    //   setData(res?.data?.data?.data);
    //   setPage(res?.data?.data);
    //   setLoading(false);
    // } else {
    //   toast(res.message, {
    //     position: "top-right",
    //     type: "error",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     rtl: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    //   setLoading(false);
    // }
  };

  return (
    <>
      <div className="mt-5">
        <div className="d-flex-space">
          <h3 className="text-[#313E45] title">
            <Text tid="Messages" />{" "}
          </h3>
          <Button
            varient="primary"
            className="message-button"
            onClick={() => navigate("/dashboard/addMessage")}
          >
            <span>
              <Text tid="Create a new message" /> +
            </span>
          </Button>
        </div>
        {loading ? (
          <LoadingSpinner />
        ) : data?.length > 0 ? (
          <div>
            <div className="d-flex-space my-[20px]">
              <h3 className="text-[#313E45] title">
                <Text tid="Incoming messages (inbox)" />
              </h3>
              {/* <div > */}
              {/* <button
                  className="blue-back mx-4 center w-[54px] h-[54px]"
                  onClick={() => setIsOpen(true)}
                >
                  <img src={remove} />
                </button> */}
              {/* <div>
                  <div className="primary-select">
                    <Select
                      suffixIcon={<Arrow />}
                      placeholder="مرتب سازی بر اساس زمان انتشار"
                    >
                      <Option className="Select--option" value="farsialpabet">
                        <Text tid="Messages" />
                      </Option>

                      <Option className="Select--option" value="enalphabet">
                        فهرست نام های علمی یا انگلیسی
                      </Option>
                      <Option className="Select--option" value="Yiminghe">
                        فهرست خانواده ها
                      </Option>
                      <Option className="Select--option" value="cat">
                        دسته بندی بیماری ها
                      </Option>
                    </Select>
                  </div>
                </div> */}
              {/* </div> */}
            </div>
            <MessageTable
              messages={data}
              setIsOpen={setIsOpen}
              setSelectedItems={setSelectedItems}
            />

            {page.per_page ? (
              <div className="Pagination my-3">
                <Pagination
                  current={current}
                  pageSize={page.per_page}
                  total={page.total}
                  onChange={onChangePage}
                />
              </div>
            ) : null}
          </div>
        ) :  <div className="my-5">
        <Card>
          <h4> {message} </h4>
        </Card>
      </div>}

        <MessageDeleteModal
          isOpen={isOpen}
          toggle={() => setIsOpen(false)}
          text=" آیا مطمئنید می خواهید حذف کنید؟ "
          confirmButtonText="حذف پیام"
          onOk={handleConfirmDeleteMessage}
        />
      </div>
    </>
  );
};

export default Message;
