import React, { useContext, useRef } from "react";
import Button from "../Components/shared/Button";
import Edit from "../assets/img/icons/edit-white.png";
import pic from "../assets/img/dashboard/picture.svg";
import copy from "../assets/img/dashboard/copy.svg";
import warning from "../assets/img/icons/alamattajob.png";
import { LanguageContext, Text } from "../context/LanguageContext";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import OrphanService from "../Services/OrphanService";
const OrphanInfos = () => {
  const codeRef = useRef();
  const ref = useRef(true);
  const { userLanguage } = useContext(LanguageContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getOrphan = async () => {
      setLoading(true);
      const res = await OrphanService.getOrphanInfo();
      if (res?.status === 200) {
        setData(res?.data?.data);
        setLoading(false);
      } else {
        toast(res?.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };

    getOrphan();

  }, []);

  const coppyCode = (event) => {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(codeRef.current.innerText);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="d-flex-space">
            <h3 className="text-[#313E45] title mb-0"><Text tid="your information" /></h3>
          </div>
          <div className="grid grid-cols-2 gap-x-[40px] gap-y-[20px] mt-8 grid-cols-1-responsive">
            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="name and family" />:
              </div>
              <div className="mr-8">{`${data?.name} ${data?.surname}`} </div>
            </div>
            <div className="d-flex-align text-[#313E45] text-[14px]">
              <img
                className={userLanguage === "en" ? "mr-8" : "ml-8"}
                src={warning}
              />{" "}
              <div>
                <Text tid="User type" />:
              </div>
              <div className="mr-8 text-[#1375A3]">
                {data?.sayyed ? (
                  <Text tid="I am descendant of the prophet" />
                ) : (
                  <Text tid="I am not descendant of the prophet" />
                )}
              </div>
            </div>
            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                {" "}
                <Text tid="First class family phone" />:
              </div>
              <div className="mr-8">{data?.first_class_family_phone}</div>
            </div>

            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="International Code" />:
              </div>
              <div className="mr-8">{data?.international_code}</div>
            </div>
            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="First class family" />:{" "}
              </div>
              <div className="mr-8">{data?.first_class_family}</div>
            </div>
            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="Date of birth" />:
              </div>
              <div className="mr-8">{data?.birth_date} </div>
            </div>

            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="The economic situation" /> :
              </div>
              <div className="mr-8">{data?.economic_situation}</div>
            </div>
            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="Identifier" /> :
              </div>
              <div className="mr-8">{`${data?.identifier?.name}  ${data?.identifier?.surname}`}</div>
            </div>
            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="health status" />:
              </div>
              <div className="mr-8"> {data?.health_status} </div>
            </div>
            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="Identification code" /> :
              </div>
              <div className="mr-8">{data?.identification_code}</div>
            </div>
            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="Educational status" />:
              </div>
              <div className="mr-8"> {data?.educational_status} </div>
            </div>
            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="phoneNumber" /> :
              </div>
              <div className="mr-8">{data?.phone_number}</div>
            </div>
            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="Email" /> :
              </div>
              <div className="mr-8">{data?.email ? data?.email : `---`}</div>
            </div>

            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="Gender" />:
              </div>
              <div className="mr-8"> {data?.gender} </div>
            </div>

            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                {" "}
                <Text tid="Upload a profile photo" />:
              </div>
              {data.avatar ? (
                <Link
                  to={data.avatar}
                  target="_blank"
                  style={{ display: "flex" }}
                >
                  <div className="mr-8  text-[#1375A3] underline">Picture</div>
                  <div>
                    <img src={pic} className="mx-8" />
                  </div>
                </Link>
              ) : (
                "---"
              )}
            </div>
            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="Country" /> :
              </div>
              <div className="mr-8">{data?.region?.parent?.parent.name}</div>
            </div>
            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="State" /> :
              </div>
              <div className="mr-8">{data?.region?.parent?.name}</div>
            </div>
            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="your code" /> :
              </div>
              <div className="d-flex relative" onClick={coppyCode}>
                <div className="mr-8 text-[#1375A3]" ref={codeRef}>
                  {data?.identification_code}
                </div>
                <img src={copy} className="mx-3" />
                {copied && (
                  <span
                    className="tooltipCopy"
                    style={{ position: "absolute" }}
                  >
                    Copied !
                  </span>
                )}
              </div>
            </div>

            <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                <Text tid="Date of introduce" /> :
              </div>
              <div className="mr-8">{data.date_of_introduce}</div>
            </div>

            <div className="text-[#313E45] text-[14px]">
              <div>
                <Text tid="Description" /> :
              </div>
              <div className="font-bold">
                {data?.description ? data?.description : "---"}
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default OrphanInfos;
