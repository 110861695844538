import React, { useContext, useRef, useState } from "react";
import arrow from "../assets/img/icons/RIGHT-ARROW.png";
import delet from "../assets/img/icons/DELETE-balck.png";
import Payam from "../assets/img/icons/message.png";
import clock from "../assets/img/icons/clock.png";
import user from "../assets/img/icons/user-black.png";
import cal from "../assets/img/icons/cal.png";
import { LanguageContext, Text, TextFunc } from "../context/LanguageContext";
import Modal from "../Components/shared/ModalStrap";
import MessageDeleteModal from "../Components/layouts/Dashboard/Message/MessageDeleteModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import MessagesService from "../Services/MessagesService";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import useMultiDate from "../CustomHooks/useMultiDate";
import AuthService from "../Services/AuthService ";
import Textarea from "../Components/shared/Textarea";
import { useForm } from "react-hook-form";
import Button from "../Components/shared/Button";
import { SyncOutlined } from '@ant-design/icons';

const MessageDetail = () => {
  const { userLanguage } = useContext(LanguageContext);
  const [removes, setremove] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const navigate = useNavigate();
  const loginedUser = AuthService.getCurrentUser();
  const { id } = useParams();
  const messageBox  = useRef(null);
  // const date = useMultiDate(
  //   data?.ChatSession?.created_at,
  //   "numeric",
  //   "numeric",
  //   "numeric"
  // );

  const {
    control,
    handleSubmit,
    watch,
    register,
    reset,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });

  useEffect(() => {
    const getMessage = async () => {
      setLoading(true);
      const res = await MessagesService.getMessage({
        chatSessionId: id,
      });
      if (res.status == 200) {
        setData(res.data?.data);
        setLoading(false);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
      getMessage();
    
  }, []);

  const scrollToLastMessage= () => {
    const lastChildElement = messageBox.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToLastMessage();
  },[]);

  const onSubmit = async (formData) => {
    reset({
      receiver_id: "",
      message: "",
      title: "",
    });
    const receiver_id =
      data?.ChatSession?.receiver_id == loginedUser?.user_id
        ? data?.ChatSession?.sender_id
        : data?.ChatSession?.receiver_id;
    
    if(formData.message.replace(/(\r\n|\n|\r)/gm, '') != ""){
      setLoadingBtn(true);
      const res = await MessagesService.sendMessage({
        ...formData,
        title: data?.ChatSession?.title,
        chatSessionId: data?.ChatSession?.id,
        receiver_id,
      });
      if (res?.status == 200) {
        // toast(res?.data?.message, {
        //   position: "top-right",
        //   type: "success",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
        setData((prevState) => ({...prevState , Message : [...prevState.Message , res?.data?.data.Message] }))
        setLoadingBtn(false);
        scrollToLastMessage()
      } else {
        toast(res?.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoadingBtn(false);
      }
    }

  };

  // const onKeyUp = (e) => {
  //  e.keyCode == 13 && e.preventDefault()
  // }

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="flex justify-between text-[#313E45]">
            <button
              className="blue-back center w-[50px] h-[50px]"
              onClick={() => navigate(-1)}
            >
              <img src={arrow} />
            </button>
            {/* <button
              onClick={() => setremove(true)}
              className="blue-back center w-[50px] h-[50px]"
            >
              <img src={delet} />
            </button> */}
          </div>
          <div className="MessageDetailtable ">
            {/* <div className=" text-[#6F838E] MessageDetailtable--header MessageDetailtable--grid">
              <div />
              <div>
                <Text tid="Sender" />
              </div>
              <div>
                <Text tid="shipping time" />
              </div>
              <div>
                <Text tid="Conditions" />
              </div>
            </div> */}
            <div className="my-8">
              <div className="flex items-center">
                <div className="icon-box">
                  <img src={Payam} />
                </div>
                <h4 className="mx-4 font-bold text-[18px] mb-0">
                  {data?.ChatSession?.title}
                </h4>
              </div>
              <div>{data?.ChatSession?.sender?.username}</div>
              {/* <div>{date}</div> */}
              {/* <div>
                {data?.ChatSession?.is_read ? "خوانده شده" : "خوانده نشده"}
              </div> */}
            </div>
          </div>
          <div className="message_wrapper">
            <div className="messages_box"  ref={messageBox}>
              {data?.Message?.map((message) =>
                loginedUser?.user_id == message.sender_id ? (
                  <div
                    key={message.id}
                    className="d-flex flex-column align-items-start my-[15px]"
                  >
                    <span className="text-[12px] mx-2">{`${message?.senderData?.name} ${message?.senderData?.surname}`}</span>
                    <div className="d-flex">
                      <div className="message user_message">
                        <p className="text-[#313E45] mb-3 ">
                          {message?.message}{" "}
                        </p>
                        <p
                          className={`mx-2 my-0  text-[12px] ${
                            userLanguage == "en" ? "text-right" : "text-left"
                          }`}
                        >
                          {message?.created_date}
                          {/* {` ${new Date(
                            message.created_date
                          ).getMinutes()}  : ${new Date(
                            message.created_date
                          ).getHours()} `} */}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={message.id}
                    className="d-flex flex-column align-items-end my-[15px]"
                  >
                    <div>
                      <p
                        className={`text-[12px] mx-2 my-0  ${
                          userLanguage == "en" ? "text-left" : "text-right"
                        } `}
                      >{`${message?.senderData?.name} ${message?.senderData?.surname}`}</p>
                      <div className="message responder_message">
                        <p className="text-[#313E45] mb-3 ">
                          {message?.message}
                        </p>
                        <p
                          className={`mx-2 my-0  text-[12px] ${
                            userLanguage == "en" ? "text-right" : "text-left"
                          }`}
                        >
                          {message?.created_date}
                          {/* {` ${new Date(
                            message.created_date
                          ).getMinutes()}  : ${new Date(
                            message.created_date
                          ).getHours()} `} */}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className="relative">
              <form onSubmit={handleSubmit(onSubmit)} >
                <Textarea
                  name="message"
                  control={control}
                  errors={errors}
                  register={{
                    required: true,
                  }}
                  className="Footer__input message_input"
                  placeholder={ TextFunc("Message text") }
                />
                <Button
                  varient="primary"
                  className="button--green absolute send_msg_btn "
                  disabled={loadingBtn}
                >
                  {loadingBtn ? (
                  <SyncOutlined style={{ fontSize: "22px" }} spin />
                ) : (
                 <Text tid="Send"  />
                  )}
                </Button>
              </form>
            </div>
          </div>

          {/* <div className="text-[#313E45]">
            <div className="flex">
              <img src={user} className="ml-4" />
              <div className="mx-4">
                <Text tid="Sender" /> :
              </div>
              <div className="font-bold">ادمین</div>
            </div>
            <div className="flex my-8">
              <img src={cal} className="ml-4" />
              <div className="mx-4">
                {" "}
                <Text tid="shipping time" /> :
              </div>
              <div className="font-bold">1400/12/12</div>
            </div>
            <div className="flex">
              <img src={user} className="ml-4" />
              <div className="mx-4">
                {" "}
                <Text tid="Conditions" /> :
              </div>
              <div className="font-bold">PM 14 : 20 </div>
            </div>
          </div> */}
          {/* {data.replies?.length > 0
            ? data.replies.map((reply) => (
                <div key={reply.id} className="mr-[80px] my-[40px] res-mr-0">
                  <div className=" MessageDetailtable--grid my-8">
                    <div className="flex items-center">
                      <div className="icon-box">
                        <img src={Payam} />
                      </div>
                      <h4 className="mx-4 font-bold text-[18px] mb-0">
                        {reply.title}
                      </h4>
                    </div>
                    <div>{reply.sender.username}</div>
                    <div>{reply.created_at}</div>
                    <div>{reply.read ? "خوانده شده" : " خوانده نشده "}</div>
                  </div>
                  <p className="text-[#313E45] ">{reply.message}</p>
                </div>
              ))
            : null} */}

          {removes && (
            <Modal remove={() => setremove(false)}>
              <MessageDeleteModal
                onOk={() => {
                  navigate("/dashboard/reportlist");
                  setremove(false);
                }}
                onCancel={() => setremove(false)}
              />
            </Modal>
          )}
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default MessageDetail;
