import React ,{useEffect}from 'react'
import { useSearchParams } from 'react-router-dom';
import NewsDetailinfo from '../Components/layouts/News/NewsDetail'
import NewsSidebar from '../Components/layouts/News/NewsSidebar'

const NewsDetail = ({setOpen}) => {

  useEffect(() => {
    setOpen(false)
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
        <div className="NewsContainer__detail">
         <NewsDetailinfo />
        </div>
  )
}

export default NewsDetail