import React from "react";
import {Bars} from "react-loader-spinner";

const LoadingSpinner = ({className}) => {
  return (
    <>
      <Bars
        height="80"
        width="80"
        color="#074979"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass= {`spinner_wrapper ${className} ` }
        visible={true}
      />
    </>
  );
};

export default LoadingSpinner;
