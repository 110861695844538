import React from "react";
import Facebook from "../../assets/img/icons/facebook.svg";
import linkdin from "../../assets/img/icons/linkdin.svg";
import telegram from "../../assets/img/icons/telegram.svg";
import tweeter from "../../assets/img/icons/Mask Group 544.svg";
import instagram from "../../assets/img/icons/instagram.svg";
const Social = () => {
  return (
    <div className="Social">
      <div className="icon-box">
        <img src={Facebook} alt='' />
      </div>
      <div className="icon-box">
        <img src={linkdin} alt='' />
      </div>
      <div className="icon-box">
        <img src={telegram} alt='' />
      </div>
      <div className="icon-box">
        <img src={tweeter} alt='' />
      </div>
      <div className="icon-box">
        <img src={instagram} alt='' />
      </div>
    </div>
  );
};

export default Social;
