import Cookies from "js-cookie";

export default function authHeader(){
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user') ) : undefined;
  
    if (user && user.token) {
      return { Authorization: 'Bearer ' + user.token };
    } else {
      return {};
    }
  }