import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowButtonRight } from "../../../assets/img/icons/slider-right.svg";
import { ReactComponent as ArrowButtonLeft } from "../../../assets/img/icons/Slider-left.svg";
import HomeNewsCard from "./HomeNewsCard";
import { Text } from "../../../context/LanguageContext";
const HomeNews = ({news}) => {
  const settings = {
    dots: false,
    infinite: news && news.length > 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <ArrowButtonRight />,
    prevArrow: <ArrowButtonLeft />,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: news && news.length > 3,
          dots: true
        }
      }
    ]
  };
  return (
    news && news.length > 0 ?  <div className="container"> 
      <div>
        <div className="HomeNews__title center-column">
          <div className="Tag my-4">
            {" "}<Text tid="latest news" />
          </div>
          <h2 className="mb-5" >
            <Text tid="Read the latest news together" />
          </h2>
        </div>
        <Slider {...settings}>
        {news.map(n => <HomeNewsCard key={n.id} news = {n} /> ) }
        </Slider>
        <div className="center show-all mt-5">
        <Link to="/news">
         <Text tid="show all" />
        </Link>
      </div>
      </div>
      
    </div> : null
  );
};

export default HomeNews;
