import React from "react";
import { useForm } from "react-hook-form";
import Input from "../../shared/Input";
import Select from "../../shared/Select";
import leftArrow from "../../../assets/img/icons/left-small-arrow.svg";
import Button from "../../shared/Button";
import HomeHelpForm from "./HomeHelpForm";
import { Text } from "../../../context/LanguageContext";
const HomeHelp = () => {
  const { control, handleSubmit, formState: { errors, isValid } } = useForm();
  const onSubmit = data => {};

  return (
    <div className="HomeHelp">
      <div className="container">
        <div className="HomeHelp__con">
          <div className="HomeHelp__con-infobox">
            <div className="HomeHelp__con-info d-flex-space">
              <div>
                <h4 className="HomeHelp__con-info--title HomeHelp__con-info--title--1 ">
                  {" "}<Text tid="financial aid" />
                </h4>
                <p className="HomeHelp__con-info--p">
                  <Text tid='lorem' />
                </p>
              </div>
              <img src={leftArrow} className="leftArrow" alt="" />
            </div>
            <div className="HomeHelp__con-info d-flex-space">
              <div>
                <h4 className="HomeHelp__con-info--title HomeHelp__con-info--title--2 ">
                 <Text tid='wisdom' />
                </h4>
                <p className="HomeHelp__con-info--p">
                <Text tid='lorem' />
                </p>
              </div>
              <img src={leftArrow} className="leftArrow" alt="" />
            </div>
          </div>
          <div className="HomeHelp__con-box">
            <h3> <Text tid="financial aid" /></h3>
            <HomeHelpForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHelp;
