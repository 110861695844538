import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Controller } from "react-hook-form";
import { Text } from "../../context/LanguageContext";
import { useEffect } from "react";
const Input = ({
  name,
  value,
  message,
  control,
  register,
  label,
  absolute,
  className,
  errors,
  defaultValue = "",
  LabelIcon,
  inputIcon,
  iconClassName,
  inputClassName,
  onKeyUp,
  autocomplete,
  inputIconStyle,
  minNumberValue = 0,
  onClickIcon = () => {},
  ...rest
}) => {
  const error = errors[name];

  return (
    <div className={`Input relative ${className}`}>
      <label className="d-flex">
        {label && <Text tid={label} />}
        {LabelIcon && (
          <div className={`${label ? "mx-4" : ""}`}>{LabelIcon}</div>
        )}
      </label>

      <Controller
        name={name}
        control={control}
        rules={register}
        defaultValue={defaultValue}
        value={value}
        render={({ field }) => (
          <input
            autoComplete={autocomplete}
            onKeyUp={onKeyUp}
            className={inputClassName}
            {...rest}
            {...field}
          />
        )}
      />
      {inputIcon && (
        <div
          onClick={onClickIcon}
          style={inputIconStyle}
          className={`${iconClassName} input__icon `}
        >
          {inputIcon}
        </div>
      )}
      {error && (
        <span className="input__message text-[red]">{error.message}</span>
      )}
    </div>
  );
};

Input.propTypes = {
  classes: PropTypes.any,
  control: PropTypes.any,
  errors: PropTypes.any,
  label: PropTypes.string,
  message: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.any,
  value: PropTypes.any,
  autoComplete: PropTypes.string,
};
export default Input;
