import React, { useState } from "react";
import mosbat from "../assets/img/icons/mosbat.png";
import user from "../assets/img/dashboard/blank.png";
import heart from "../assets/img/icons/red-heart.png";
import tajob from "../assets/img/icons/tajob-circle.png";
import tajobabi from "../assets/img/icons/tajob-abi.png";
import Button from "../Components/shared/Button";
import { LanguageContext, Text } from "../context/LanguageContext";
import check from "../assets/img/icons/Check-green.svg";
import Modal from "../Components/shared/ModalStrap";
import KafilPopup from "../Components/layouts/Dashboard/kafil/KafilPopup";
import KafilTablePopup from "../Components/layouts/Dashboard/kafil/KafilTablePopup";
import KafilPayPopoup from "../Components/layouts/Dashboard/kafil/KafilPayPopoup";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "../Services/AuthService ";
import { useEffect } from "react";
import SponsorService from "../Services/SponsorService";
import { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import { Popover } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useAlertPayment from "../CustomHooks/useAlertPayment";

const OrphanRow = ({ setkafilpopup, orphan, setOrphanId, setIsOpen }) => {
  const navigate = useNavigate();
  const { userLanguage } = useContext(LanguageContext);
  const alertMessage = useAlertPayment(
    new Date(),
    new Date(orphan.payment_due)
  );
  const handleDetailClick = (e, orphan_id) => {
    e.stopPropagation();
    setkafilpopup(true);
    setOrphanId(orphan_id);
  };
  return (
    <div
      className={`payment-table-grid rounded-[10px] ${"bg-[#F4F8FA] text-[#313E45]"} p-8 my-2 DashboardMainPage-res`}
    >
      <div className="d-flex-align">
        <div className="relative">
          {alertMessage ? (
            <Popover
              placement={userLanguage == "en" ? "topLeft" : "topRight"}
              content={
                <div
                  style={{
                    direction: `${userLanguage == "en" ? "ltr" : "rtl"}`,
                  }}
                >
                  <span className="d-flex align-items-center">
                    {" "}
                    <ExclamationCircleOutlined
                      style={{ fontSize: "16px", color: "#FF1D25" }}
                    />{" "}
                    <span className="mx-2"> {alertMessage} </span>{" "}
                  </span>
                </div>
              }
              trigger="click"
            >
              <div
                className={`rounded-[50%] w-[20px] h-[20px]  text-[#fff] absolute right-0 badge-res cursor-pointer ${
                  new Date().getTime() <= new Date(orphan.payment_due).getTime()
                    ? `bg-[#ff9900]`
                    : `bg-[#FF1D25]`
                } `}
                onClick={(e) => e.stopPropagation()}
              >
                1
              </div>
            </Popover>
          ) : null}
          <img
            className="rounded-[50%] w-[50px] h-[50px] border-[2px] border-[#fff] overflow-hidden res-profile"
            src={orphan?.avatar || user}
          />
        </div>

        <div className="mx-3 text-right">
          <div
            className={`
              "text-[#313E45]"
           text-[14px] font-res-header f-w-500`}
          >
            {`${orphan?.name}`}
          </div>
          {orphan.paid_months > 0 ? (
            <div
              className={`text-[#6F838E] text-[12px] font-res-header ${userLanguage == "en" ? "text-start" : "text-end" } `}
            >
              {userLanguage == "fa"
                ? `من ${orphan.paid_months}  ماه پول دادم `
                : userLanguage == "en"
                ? `I paid for ${orphan.paid_months} months`
                : userLanguage == "ar"
                ? `لقد دفعت لمدة ${orphan.paid_months} أشهر`
                : null}
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex items-center">
        <img src={heart} className="mx-1" /> {orphan?.priority}
      </div>
      <div className="identifire_code">{orphan.identification_code}</div>
      <div className="dir-ltr">
        <span className="yearly_salary">$ {orphan.yearly_salary} / </span>
        <span>
          $ {parseFloat(Number(orphan?.yearly_salary) / 12).toFixed(2)}
        </span>
      </div>
      <div className="flex justify-between DashboardMainPage-res--actions">
        <div
          className="flex items-center cursor-pointer"
          onClick={(e) => handleDetailClick(e, orphan.id)}
        >
          <img src={tajobabi} alt="" className="mx-2 w-[20px] h-[20px]" />
          <div className=" underline text-[#1375A3] details"> <Text tid="Details"  /> </div>
        </div>
        {orphan.has_report ? (
          <Button
            varient="primary"
            onClick={() =>
              navigate({
                pathname: `/dashboard/orphanReports/${orphan.id}`,
              })
            }
          >
            {" "}
            <Text tid="Show Report" />{" "}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const Kafil = ({ setkafilpopup, setOrphanId }) => {
  const [popup, setpopup] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({});
  const [message, setMessage] = useState("");
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();

  useEffect(() => {
    if (!currentUser?.token && currentUser?.role != "کفیل") {
      navigate("/register");
    }
  }, []);

  useEffect(() => {
    const getAssignOrphans = async () => {
      setLoading(true);
      const res = await SponsorService.getAssignOrphans(
        searchParams.get("page")
      );
      if (res?.status === 200) {
        setData(res.data?.data?.data);
        setPage(res?.data?.data);
        setMessage(res.data?.message);
        setLoading(false);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    getAssignOrphans();
  }, [searchParams.get("page")]);

  return (
    <div>
      <div>
        <h3 className="text-[#313E45] text-[30px]  mt-3 mt-xl-0 header-text ">
         <Text tid="Orphans under your care"  />
        </h3>
      </div>
      {data && data == undefined ? (
        <div className="flex items-center">
          <img src={mosbat} className="mx-8" />
          <div className="font-bold text-[20px] unselexted_orphan_text text-[#313E45]">
            <Text tid="assigned list is empty" />
          </div>
        </div>
      ) : null}

      <div className="center flex-column flex-sm-row my-[40px]">
        <Button
          varient="primary"
          className="mx-4 text-[23px]"
          onClick={() => {
            navigate("/dashboard/addkafil");
          }}
        >
          <Text tid="Start babysitting" /> +
        </Button>
        <button className="Tag text-[23px] mt-3 mt-sm-0"><Text tid="Monitoring conditions" /> </button>
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : (
        data?.length > 0 && (
          <div className="KafilTable my-[40px]">
            <div className="payment-table-grid text-[#6F838E] DashboardMainPage-res">
              <div />
              <div><Text tid="Priority" /></div>
              <div className="identifire_code"> <Text tid="ID Code" /> </div>
              <div>
                <span className="yearly_salary"><Text tid="yearly" /> / </span>
                <span> <Text tid="Monthly fee" /> </span>
              </div>
              <div />
            </div>

            <div className="row_wrapper">
              {data &&
                data.length > 0 &&
                data.map((item) => (
                  <OrphanRow
                    key={item.id}
                    setkafilpopup={setkafilpopup}
                    orphan={item}
                    setOrphanId={setOrphanId}
                  />
                ))}
            </div>
            <div className="Tag mt-5">
              {" "}
              <Text tid="Warranty Terms" />
            </div>
          </div>
        )
      )}
      <h4 className="text-[#313E45] text-[28px] md:text-[34px]">
        {" "}
        <Text tid="Be happy Be happy" />{" "}
      </h4>
      <div className="grid gap-[20px]  md:grid-cols-2">
        <p className="HomeKefalat__info--p">
          <span>
            <img src={check} alt="" />
          </span>
          <Text tid="lorem" />
        </p>
        <p className="HomeKefalat__info--p">
          <span>
            <img src={check} alt="" />
          </span>
          <Text tid="lorem" />
        </p>
        <p className="HomeKefalat__info--p">
          <span>
            <img src={check} />
          </span>
          <Text tid="lorem" />
        </p>
        <p className="HomeKefalat__info--p">
          <span>
            <img src={check} alt="" />
          </span>
          <Text tid="lorem" />
        </p>
      </div>
      {popup && (
        <Modal remove={() => setpopup(false)}>
          <KafilTablePopup />
        </Modal>
      )}
      <ToastContainer />
    </div>
  );
};

export default Kafil;
