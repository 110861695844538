import axios from "axios";

const API_URL = "https://aytameamiralmomenin.com/api/"

class ContactService {
    contact = (userLanguage , formData) => {
        return axios
          .post(API_URL + `${userLanguage}/contact/submit`, formData )
          .catch((error) => {
            if (error) {
              return error;
            }
          });
      };
}

export default new ContactService();