import React, { useEffect } from "react";
import Selectss from "../Components/shared/Select";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Pagination, Select } from "antd";
import { useForm } from "react-hook-form";
import arrow from "../assets/img/icons/footer-arrow.svg";
import HomeNewsCard from "../Components/layouts/Home/HomeNewsCard";
import NewsSidebar from "../Components/layouts/News/NewsSidebar";
import { LanguageContext, Text } from "../context/LanguageContext";
import axios from "axios";
import { useState } from "react";
import NewsService from "../Services/NewsService";
import { useContext } from "react";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import { toast, ToastContainer } from "react-toastify";

const NewsCategory = ({ setOpen }) => {
  const { Option } = Select;
  const { userLanguage } = useContext(LanguageContext);
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({});
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    setOpen(false);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getNewsByCategory = async () => {
      setLoading(true);
      const res = await NewsService.getNewsByCategory(
        userLanguage,
        searchParams.get("id"),
        searchParams.get("page")
      );
      if (res.status == 200) {
        setData(res.data.data.data);
        setPage(res.data.data);
        if (res.data && res.data.data.length == 0) {
          navigate("/news");
        }

        setLoading(false);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // setError(true);
        setLoading(false);
      }
    };
    setCurrent(
      searchParams.get("page")
        ? Number(Object.fromEntries([...searchParams]).page)
        : 1
    );
    getNewsByCategory();
  }, [searchParams.get("page") , searchParams.get("id")]);

  const onChangePage = async (pageNumber) => {
    setCurrent(pageNumber);
    navigate({ pathname: "news/category" , search: `?id=${searchParams.get("id")}&page=${pageNumber}`})
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : data && data.length > 0 ? (
        <div className="row" >
          {/* <div className="NewsContainer__grid"> */}
            {data.map((news) => (
              <div key={news.id} className="col-md-4 mb-5 NewsContainer__grid p-0">
              <HomeNewsCard key={news.id} news={news} />
              </div>
            ))}
          {/* </div> */}
        </div>
      ) : null}

      {page.per_page ? (
        <div className="Pagination my-3" >
          <Pagination
            current={current}
            pageSize={page.per_page}
            total={page.total}
            onChange={onChangePage}
          />
        </div>
      ) : null}

      <ToastContainer />
    </>
  );
};

export default NewsCategory;
