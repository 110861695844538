import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://aytameamiralmomenin.com/api/";
class SponsorService {
  getSponsor = () => {
    return axios
      .get(API_URL + `sponsor/information`, { headers: authHeader() })
      .catch((error) => {
        if (error) {
          return error;
        }
      });
  };

  editSponsor = (data) => {
    return axios
      .post(API_URL + `sponsor/edit`, data, {
        headers: {
          ...authHeader(),
          "content-type": "multipart/form-data",
        },
      })
      .catch((error) => {
        if (error) {
          return error;
        }
      });
  };

  getSelectedOrphans = (data) => {
    return axios
      .post(API_URL + `sponsor/get-orphans-data`, data, {
        headers: {
          ...authHeader(),
        },
      })
      .catch((error) => {
        if (error) {
          return error;
        }
      });
  };

  saveAssignOrphans = (data) => {
    return axios
      .post(API_URL + `sponsor/orphans/assign`, data, {
        headers: {
          ...authHeader(),
        },
      })
      .catch((error) => {
        if (error) {
          return error;
        }
      });
  };

  getAssignOrphans = (pageNumber) => {
    return axios
      .get(API_URL + `sponsor/orphans/assigned?page=${pageNumber || 1}`, {
        headers: authHeader(),
      })
      .catch((error) => {
        return error;
      });
  };

  getAssignableOrphans = (pageNumber) => {
    return axios
      .get(API_URL + `sponsor/get-assignable-orphans?page=${pageNumber || 1}`, {
        headers: authHeader(),
      })
      .catch((error) => {
        return error;
      });
  };

  transactions = (pageNumber) => {
    return axios
      .get(API_URL + `transactions/sponsor?page=${pageNumber || 1}`, {
        headers: authHeader(),
      })
      .catch((error) => {
        return error;
      });
  };

  filterTransActions = (pageNumber, data) => {
    return axios
      .post(API_URL + `search-sponsor-payments?page=${pageNumber || 1}`, data, {
        headers: authHeader(),
      })
      .catch((error) => {
        return error;
      });
  };
}

export default new SponsorService();
