import React, { useEffect, useState } from "react";
import Accordion from "../../../shared/Accordion";
import Payam from "../../../../assets/img/icons/message.png";
import remove from "../../../../assets/img/icons/bleck-remove.png";
import { ReactComponent as Arrow } from "../../../../assets/img/icons/arrow-down.svg";
import { Checkbox, Pagination } from "antd";
import { Text } from "../../../../context/LanguageContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import useMultiDate from "../../../../CustomHooks/useMultiDate";


const selectedItems = [];
const MessageRow = ({ message , setIsOpen , setSelectedItems  }) => {
  const [checked , setChecked] = useState(false);
  const [searchParams] = useSearchParams()
  const navigate = useNavigate();

  const date = useMultiDate(
    message.created_date,
    "numeric",
    "numeric",
    "numeric"
  );

  useEffect(() => {
    if(searchParams.get("page") && selectedItems.includes(message.id)){
      setChecked(true);
    }
  } , [])

  const handleChangeCheckBox = (e) => {
    e.stopPropagation();
    setChecked(e.target.checked);
    if (e.target.checked && selectedItems.indexOf(e.target.id) == -1) {
      selectedItems.push(e.target.id);
    } else {
      selectedItems.splice(selectedItems.indexOf(e.target.id), 1);
    }
    setSelectedItems(selectedItems)
  };

  return (
    <>
      <div className="">
        <div
          className="blue-back my-[10px]"
          onClick={() => navigate(`/dashboard/message/${message.id}`)}
          key={message.id}
        >
          <div className={`TarakoneshTable--body  message-res ${!message.is_read ? "fw-bold" : ""} `}>
            <div className="flex items-center">
              <div className="icon-box">
                <img src={Payam} />
              </div>
              <h4 className={`mx-2 text-[14px] mb-0 message-title ${!message.is_read ? "fw-bold" : ""}`}>
                {message.title}
              </h4>
            </div>
            <div>{`${message.senderData.name} ${message.senderData.surname}`}</div>
            <div>{date}</div>
            <div>{message.is_read ? <Text tid="read" /> : <Text tid="Unread" />}</div>
            <div className="flex items-center justify-end">
              {/* <img
                src={remove}
                className="mx-4"
                onClick={(e) => removemessage(e, message.id)}
              /> */}
              {/* <div className="mx-3" onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  id={message.id}
                  checked = {checked}
                  onChange={(e) => handleChangeCheckBox(e)}
                />
              </div> */}
            </div>
          </div>
          <div
            className="px-5"
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {message.message}
          </div>
        </div>
      </div>
    </>
  );
};

const MessageTable = ({ messages , setIsOpen , setSelectedItems  }) => {

  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  return (
    <>
        <div className="TarakoneshTable">
          <div className="TarakoneshTable--heade message-res text-[#6F838E] mb-2 ">
            <div />
            <div>
              <Text tid="Sender" />
            </div>
            <div>
              <Text tid="shipping time" />
            </div>
            <div>
              <Text tid="Conditions" />
            </div>
            <div />
          </div>
          <div className="row_wrapper" >
          {messages.map((message) => (
            <MessageRow
              key={message.id}
              message={message}
              setIsOpen = {setIsOpen}
              setSelectedItems = {setSelectedItems}
            />
          ))}
          </div>
       
          {/* <MessageDeleteModal
            isOpen={isOpen}
            toggle={() => setIsOpen(false)}
            backdrop="static"
            // onOk={handleConfirmDeleteMessage}
          /> */}
        </div>
    
    </>
  );
};

export default MessageTable;
