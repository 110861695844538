import React, { Fragment, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logos/logo-typo.svg";
import user from "../../assets/img/icons/Mask Group 577.svg";
import notification from "../../assets/img/icons/notification.svg";
import arrow from "../../assets/img/icons/arrow-down.svg";
import search from "../../assets/img/icons/search.svg";
import { LanguageContext, Text, TextFunc } from "../../context/LanguageContext";
import Button from "../shared/Button";
import styled from "styled-components";
import userblue from "../../assets/img/icons/blue-user.svg";
import Home from "../../assets/img/icons/footer/user.svg";
import News from "../../assets/img/icons/responsiveMenu/news.svg";
import contactus from "../../assets/img/icons/responsiveMenu/contactus.svg";
import wisdom from "../../assets/img/icons/responsiveMenu/wisdom.svg";
import profile from "../../assets/img/icons/responsiveMenu/profile.svg";
import helpaid from "../../assets/img/icons/responsiveMenu/subvention.svg";
import gallery from "../../assets/img/icons/responsiveMenu/gallery.svg";
import AuthService from "../../Services/AuthService ";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 99999;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.15rem;
    background: ${({ open }) => (open ? "#313e45" : "#313e45")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger className="Burger" open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

const ResponsiveMenu = ({ open }) => {
  return (
    <div
      className={`ResponsiveMenu ${
        open ? "ResponsiveMenu__open" : "ResponsiveMenu__close"
      }`}
      open={open}
    >
      <div className="ResponsiveMenu__div" />
      <ul className="list ResponsiveMenu__ul">
        {/* <li className="ResponsiveMenu-li">
          <div className="d-flex-align">
            <img className="ml-4" src={search} />
            <div>جستجو</div>
          </div>
        </li> */}
        <li className="ResponsiveMenu-li">
          <Link to="/">
            <div className="d-flex-align">
              <img className="ml-4" src={Home} />
              <Text tid="Home" />
            </div>
          </Link>
        </li>
        <li className="ResponsiveMenu-li">
          <Link to="/">
            <div className="d-flex-align">
              <img className="ml-4" src={wisdom} />
              <Text tid="Wisdom" />
            </div>
          </Link>
        </li>
        <li className="ResponsiveMenu-li">
          <Link to="news">
            <div className="d-flex-align">
              <img className="ml-4" src={News} />
              <Text tid="News" />
            </div>
          </Link>
        </li>
        <li className="ResponsiveMenu-li">
          <Link to="/gallery">
            <div className="d-flex-align">
              <img className="ml-4" src={gallery} />
              <Text tid="Gallery" />
            </div>
          </Link>
        </li>
        <li className="ResponsiveMenu-li">
          <Link to="/gallery-video">
            <div className="d-flex-align">
              <img className="ml-4" src={gallery} />
              <Text tid="Video Gallery" />
            </div>
          </Link>
        </li>

        <li className="ResponsiveMenu-li">
          <Link to="/Contact-us">
            <div className="d-flex-align">
              <img className="ml-4" src={contactus} />
              <Text tid="contact us" />
            </div>
          </Link>
        </li>
        <li className="ResponsiveMenu-li">
          <Link to="/About-us">
            <Text tid="about us" />
          </Link>
        </li>
      </ul>
    </div>
  );
};

const SubMenu = ({ open, setOpen }) => {
  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const userToggle = () => setUserDropdownOpen((prevState) => !prevState);

  const onChangeLanguage = (name) => {
    userLanguageChange(name);
    navigate("/");
  };

  const handleLogOut = async () => {
    setLoading(true);
    const res = await AuthService.logout(userLanguage);
    if (res) {
      if (res.status == 200) {
        setLoading(false);
        navigate("/");
      } else {
        toast(res || "خطایی رخ داده است", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    }
  };

  return (
    <div className="SubMenu__back">
      <ResponsiveMenu open={open} />
      <div className="container p-0 d-small-shadow ">
        <div className="SubMenu">
          <div className="d-flex-space SubMenu-row">
            <img
              onClick={() => navigate("/")}
              className="responsivelogo"
              src={Logo}
              alt="logo"
            />
            <div className="d-flex-space SubMenu__responsiv-div">
              <select
                value={userLanguage}
                onChange={(e) => onChangeLanguage(e.target.value)}
                className="d-flex-align Menu__info--select select-box mx-3"
              >
                <option value="fa">fa</option>

                <option value="en">En</option>
                <option value="ar">ar</option>
              </select>
              {currentUser ? (
                <button>
                  <img alt="" width="20" src={notification} />
                </button>
              ) : null}

              <Dropdown
                className="dropdown-custom"
                size="lg"
                isOpen={userDropdownOpen}
                toggle={userToggle}
              >
                <DropdownToggle className="dropdown-toggle-custom" color="link">
                  <img width="20" alt="" src={user} />
                </DropdownToggle>

                <DropdownMenu
                  className={` ${
                    userLanguage == "en"
                      ? `dropdown-menu-custom-ltr`
                      : `dropdown-menu-custom-rtl`
                  } dropdown-menu-custom  `}
                  container="body"
                  dark={false}
                >
                  {!currentUser ? (
                    <DropdownItem onClick={() => navigate("/register")}>
                      <Text tid="signin" />
                      <span className="mx-4">|</span>
                      <Text tid="login" />
                    </DropdownItem>
                  ) : (
                    <DropdownItem onClick={handleLogOut}>
                      خروج از حساب کاربری
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>

              {/* <button
                className="mx-4"
                onClick={() => {
                  currentUser?.role == "کفیل"
                    ? navigate("/dashboard/kafil")
                    : currentUser?.role == "یتیم"
                    ? navigate(`/dashboard/tarakonesh`)
                    : currentUser?.role == "ناظر" ? navigate("/dashboard") : navigate("/قثلهسفثق") ;
                }}
              >
                <img width="20" alt="" src={user} />
              </button> */}
              <Burger setOpen={setOpen} open={open} />
            </div>
            <ul className="d-flex-space list SubMenu-ul">
              <li className="SubMenu-li">
                <Link to="/">
                  <Text tid="Home" />
                </Link>
              </li>
              <li className="SubMenu-li">
                <Link to="/">
                  <Text tid="Wisdom" />
                </Link>
              </li>
              <li className="SubMenu-li">
                <Link to="news">
                  <Text tid="News" />
                </Link>
              </li>
              <li className="SubMenu-li">
                <Dropdown
                  className="dropdown-custom"
                  size="lg"
                  isOpen={dropdownOpen}
                  toggle={toggle}
                >
                  <DropdownToggle
                    className="dropdown-toggle-custom"
                    color="link"
                  >
                    <span className="mx-2">{TextFunc("Gallery")}</span>
                    <FontAwesomeIcon icon={faAngleDown} />
                  </DropdownToggle>

                  <DropdownMenu
                    className={` ${
                      userLanguage == "en"
                        ? `dropdown-menu-custom-ltr`
                        : `dropdown-menu-custom-rtl`
                    } dropdown-menu-custom  `}
                    container="body"
                    dark={false}
                  >
                    <DropdownItem onClick={() => navigate("/gallery")}>
                      {TextFunc("Gallery")}
                    </DropdownItem>
                    <DropdownItem onClick={() => navigate("/gallery-video")}>
                      {TextFunc("Video Gallery")}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                {/* <select
                      className="select-box"
                      
                    >
                      <option   value="gallery" onClick={handleSelectGallery} > {TextFunc("Gallery")}  </option>
                      <option value="gallery-video"> {TextFunc("Video Gallery")}</option>
                    </select>   */}
              </li>
              <li className="SubMenu-li">
                <Link to="/About-us">
                  <Text tid="about us" />
                </Link>
              </li>
              <li className="SubMenu-li">
                <Link to="/Contact-us">
                  <Text tid="contact us" />
                </Link>
              </li>
            </ul>
            <div className="d-flex-align SubMenu__btn">
              {/* <div className="mx-8">
                <img src={search} alt="" />
              </div> */}
              {!currentUser ? (
                <Button
                  onClick={() => navigate("/register")}
                  varient="primary"
                  className="SubMenu__btn--button"
                >
                  <Text tid="signin" />
                  <span className="mx-4">|</span>
                  <Text tid="login" />
                </Button>
              ) : (
                <Link
                  to={
                    currentUser?.role == "کفیل"
                      ? "/dashboard/kafil"
                      : currentUser?.role == "یتیم"
                      ? `/dashboard/tarakonesh`
                      : currentUser?.role == "ناظر"
                      ? "/dashboard"
                      : "/register"
                  }
                  className="blue-back d-flex-space w-[170px]"
                >
                  <div className="d-flex">
                    <img src={userblue} className="ml-4" />
                    <div className="text-[#1375A3]">
                      <Text tid="profile" />
                    </div>
                  </div>
                  <div>
                    <img src={arrow} />
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubMenu;
