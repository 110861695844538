import { Card, Pagination } from "antd";
import React, { useContext, useState } from "react";
import profile from "../assets/img/icons/person-people-girl-portrait-model-child-730652-pxhere.com.png";
import heart from "../assets/img/icons/red-heart.png";
import tajob from "../assets/img/icons/tajob-circle.png";
import tajobabi from "../assets/img/icons/tajob-abi.png";
import checks from "../assets/img/icons/check.png";
import { ReactComponent as Arrow } from "../assets/img/icons/arrow-down.svg";
import Button from "../Components/shared/Button";
import { LanguageContext, Text, TextFunc } from "../context/LanguageContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import Auth from "../Services/AuthService ";
import { useEffect } from "react";
import OrphanService from "../Services/OrphanService";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import { ToastContainer, toast } from "react-toastify";
import { InfoCircleOutlined } from "@ant-design/icons";
import user from "../assets/img/dashboard/blank.png";
import Select from "../Components/shared/Select";
import { useForm } from "react-hook-form";
import AuthService from "../Services/AuthService ";

const { Option } = Select;
const OrphanRow = ({ setkafilpopup, orphan, setOrphanId }) => {
  const [check, setCheck] = useState(false);
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const navigate = useNavigate();

  const handleDetailClick = (orphan_id) => {
    setkafilpopup(true);
    setOrphanId(orphan_id);
  };

  return (
    <div
      className={`tarakonesh-table-grid rounded-[10px] ${
        check ? "bg-[#1375A3] text-[#fff]" : "bg-[#F4F8FA] text-[#313E45]"
      } p-8 my-2 DashboardMainPage-res DashboardMainPage-res-1 `}
    >
      <div className="d-flex-align">
        <div className=" relative orphan-list-img ">
          {/* <div className="rounded-[50%] w-[20px] h-[20px] bg-[#FF1D25] text-[#fff] absolute right-0 top-0 badge-res">
            1
          </div> */}
          {
            <img
              className="rounded-[50%] w-[50px] h-[50px] border-[2px] border-[#fff] overflow-hidden res-profile"
              src={orphan?.avatar || user}
              alt={orphan?.name}
            />
          }
        </div>
        <div className="mr-4 text-right">
          <div
            className={` font-res-header f-w-500 ${
              check ? "text-[#fff] " : "text-[#313E45]"
            } text-[14px]`}
          >
            {`${orphan?.name} ${orphan?.surname}`}
          </div>
          {/* <div
            className={`${
              check ? "text-[#fff]" : "text-[#6F838E]"
            }  text-[12px] font-res-header  `}
          >
            من سه ماه پول دادم
          </div> */}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <img src={heart} className="ml-4" /> {orphan?.priority}
      </div>
      <div className="identifire_code">{orphan.identification_code}</div>
      <div className="dir-ltr">
        <span className="yearly_salary">$ {orphan.yearly_salary} / </span>
        <span>
          $ {parseFloat(Number(orphan?.yearly_salary) / 12).toFixed(2)}
        </span>
      </div>
      <div className="flex justify-between DashboardMainPage-res--actions">
        <div
          className="flex items-center cursor-pointer detail-btn"
          onClick={() => handleDetailClick(orphan.id)}
        >
          <InfoCircleOutlined
            style={{ color: "#1375A3", margin: "0 5px", fontSize: "16px" }}
          />
          {/* <img
            src={check ? tajob : tajobabi}
            alt=""
            className="mx-4 w-[20px] h-[20px]"
          /> */}
          <div
            className={`underline text ${
              !check ? "text-[#1375A3]" : "text-[#fff] "
            } details`}
          >
            <Text tid="Details" />
          </div>
        </div>
        <div className="d-flex-align">
          {orphan.has_report ? (
            <Button
              varient="primary"
              onClick={() =>
                navigate({
                  pathname: `/dashboard/orphanReports/${orphan.id}`,
                })
              }
            >
              {" "}
              <Text tid="Show Report" />{" "}
            </Button>
          ) : null}
          <Button
            varient="primary"
            className={`button--green 
            ${userLanguage === "en" ? "ms-2" : "me-2"}
            `}
            onClick={() =>
              navigate({
                pathname: "/dashboard/report",
                search: `?orphanId=${orphan.id}`,
              })
            }
          >
           <Text tid="Record report" />
          </Button>
        </div>
      </div>
    </div>
  );
};
const DashboardMainPage = ({ setkafilpopup, setOrphanId }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({});
  const [current, setCurrent] = useState(1);
  const [filterData, setFilterData] = useState(null);
  const [searchParams] = useSearchParams();
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const getOrphans = async () => {
      setLoading(true);
      const res = getValues().filter_orphan
        ? await OrphanService.searchOrphan(filterData, searchParams.get("page"))
        : await OrphanService.getOrphans(
            userLanguage,
            searchParams.get("page")
          );
      if (res?.status === 200) {
        setData(res?.data?.data?.data);
        setPage(res?.data?.data);
        setLoading(false);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    setCurrent(
      searchParams.get("page")
        ? Number(Object.fromEntries([...searchParams]).page)
        : 1
    );
    getOrphans();
  }, [searchParams.get("page"), filterData]);

  const onChangePage = (pageNumber) => {
    setCurrent(pageNumber);
    navigate({ pathname: "/dashboard", search: `?page=${pageNumber}` });
  };

  const handleChangeFilter = async () => {
    const filterValue = getValues();
    const data = { filter_orphans: filterValue.filter_orphan, assignable: 0 };
    setFilterData(data);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : data && data.length > 0 ? (
        <div>
          <div className="flex items-center justify-between res-center primary-select-flex-column  ">
            <div className="flex-col-responsive">
              <div className="d-flex-align ">
                <h3 className="text-[#313E45] title mb-0">
                  <Text tid="List of orphans" />
                </h3>
                <div
                  className="blue-back center  text-[#1375A3] mx-[30px] w-[40px] h-[40px]"
                  style={{ fontWeight: "900", fontSize: "18px" }}
                >
                  {page.total}
                </div>
              </div>
              <div className=" res-display-block ">
                <div className="center my-4 font-bold ">
                  {" "}
                  پنل کاربری / کفالت{" "}
                </div>
              </div>
            </div>
            <div className="primary-select res-mr-20">
              <form>
                <Select
                  name="filter_orphan"
                  control={control}
                  errors={errors}
                  className="Footer__input filter-select"
                  value=""
                  handleChange={handleChangeFilter}
                >
                  <option value="" hidden disabled>
                    {TextFunc("Sort By")}
                  </option>
                  <option className="Select--option" value="">
                    {TextFunc("all")}
                  </option>
                  <option className="Select--option" value="is_sayyed">
                    {TextFunc("being a sayyed")}
                  </option>

                  <option className="Select--option" value="not_sayyed">
                    {TextFunc("not being a sayyed")}
                  </option>

                  <option className="Select--option" value="high_priority">
                    {TextFunc("Highest priority")}
                  </option>
                  <option className="Select--option" value="low_priority">
                    {TextFunc("Lowest priority")}
                  </option>
                  <option className="Select--option" value="female">
                    {TextFunc("being a girl")}
                  </option>
                  <option className="Select--option" value="male">
                    {TextFunc("being a boy")}
                  </option>
                </Select>
              </form>
              {/* <Select suffixIcon={<Arrow />} placeholder="مرتب سازی بر اساس">
                <Option className="Select--option" value="farsialpabet">
                 سید بودن
                </Option>

                <Option className="Select--option" value="enalphabet">
                   اولویت
                </Option>
                <Option className="Select--option" value="Yiminghe">
                  جنسیت
                </Option>
              </Select> */}
            </div>
          </div>
          <div className="KafilTable tabel my-[40px]">
            <div className="tarakonesh-table-grid text-[#6F838E] DashboardMainPage-res DashboardMainPage-res-1 mb-2">
              <div />
              <div>
                <Text tid="Priority" />
              </div>
              <div className="identifire_code">
                <Text tid="ID Code" />{" "}
              </div>
              <div>
                <span>
                  <Text tid="Monthly fee" />
                </span>{" "}
                <span className="yearly_salary">
                  /<Text tid="yearly" />
                </span>
              </div>
              <div />
            </div>
            <div className="row_wrapper">
              {data &&
                data.length > 0 &&
                data.map((item) => (
                  <OrphanRow
                    key={item.id}
                    setkafilpopup={setkafilpopup}
                    orphan={item}
                    setOrphanId={setOrphanId}
                  />
                ))}
            </div>
          </div>
          <div className="center">
            <div className="res-mr-20">
              {page.per_page ? (
                <div className="Pagination my-3">
                  <Pagination
                    current={current}
                    pageSize={page.per_page}
                    total={page.total}
                    onChange={onChangePage}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div className="my-5">
          <Card>
            <h4> {data?.message} </h4>
          </Card>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default DashboardMainPage;
