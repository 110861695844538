import React, { useContext, useRef } from "react";
import Button from "../Components/shared/Button";
import Edit from "../assets/img/icons/edit-white.png";
import pic from "../assets/img/dashboard/picture.svg";
import copy from "../assets/img/dashboard/copy.svg";
import warning from "../assets/img/icons/alamattajob.png";
import { LanguageContext, Text } from "../context/LanguageContext";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import SponsorService from "../Services/SponsorService";
import AuthService from "../Services/AuthService ";
const DashboardInfos = () => {
  const codeRef = useRef();
  const { userLanguage } = useContext(LanguageContext);
  const [data , setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
 const navigate = useNavigate()

 useEffect(() => {
  window.scrollTo(0, 0);
  const getSponsor = async () => {
    setLoading(true);
    const res = await SponsorService.getSponsor();
    if (res?.status === 200) {
      setData(res?.data?.data)
      setLoading(false);
    } else {
      toast(res?.message, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    }
  };
    getSponsor();
 },[]);

 const coppyCode = (event) => {
  if ("clipboard" in navigator) {
    navigator.clipboard.writeText(codeRef.current.innerText);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }
};


  return (
    <>
     {loading ? 
        <LoadingSpinner /> : 
     <div>
      <div className="d-flex-space">
        <h3 className="text-[#313E45] title mb-0">
          <Text tid="Sponsored information" />
        </h3>
        <Button varient="primary" className="flex" onClick={()=>navigate("/dashboard/editprofile")}>
         <div className="res-displaynone">
         <Text tid="edit information" />
         </div>
          <img
            src={Edit}
            alt="edit"
            className={userLanguage === "en" ? "ml-8 res-ml-0" : "mr-8 res-mr-0"}
          />
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-x-[40px] gap-y-[20px] mt-8 grid-cols-1-responsive">
      <div className="d-flex-align text-[#313E45] text-[14px]">
          <div>
           <Text tid="name and family"  />:
          </div>
          <div className="mr-8">{`${data.name} ${data.surname}` }  </div>
        </div>
        <div className="d-flex-align text-[#313E45] text-[14px]">
          <div>
              <Text tid='Email'/> :</div>
          <div className="mr-8">{data.email}</div>
        </div>
        <div className="d-flex-align text-[#313E45] text-[14px]">
          <div>
              <Text tid='phoneNumber'/> :</div>
          <div className="mr-8">{data.phone_number}</div>
        </div>
      
        <div className="d-flex-align text-[#313E45] text-[14px]">
              <div>
                {" "}
                <Text tid="Upload a profile photo" />:
              </div>
              {data.avatar ? (
                <Link
                  to={data.avatar}
                  target="_blank"
                  style={{ display: "flex" }}
                >
                  <div className="mr-8  text-[#1375A3] underline">
                    Picture
                  </div>
                  <div>
                    <img src={pic} className="mx-8" />
                  </div>
                </Link>
              ) : (
                "---"
              )}
            </div> 

        <div className="d-flex-align text-[#313E45] text-[14px]">
          <div><Text tid='Country' />  :</div>
          <div className="mr-8">{data?.country?.name}</div>
        </div>
        <div className="d-flex-align text-[#313E45] text-[14px]">
          <div>
              <Text tid='group' /> :</div>
          <div className="mr-8">{data?.group}</div>
        </div>
        <div className="d-flex-align text-[#313E45] text-[14px]">
          <div>
              <Text tid='State' /> :</div>
          <div className="mr-8">{data?.province?.name}</div>
        </div>
        <div
              className="d-flex-align text-[#313E45] text-[14px]"
            >
              <div>
                <Text tid="your code" /> :
              </div>
              <div className="d-flex relative" onClick={coppyCode} >
                <div className="mr-8 text-[#1375A3]" ref={codeRef}>
                  {data?.code}
                </div>
                <img src={copy} className="mx-3" />
                {copied && (
                  <span
                    className="tooltipCopy"
                    style={{ position: "absolute" }}
                  >
                    Copied !
                  </span>
                )}
              </div>
            </div>
        <div className="d-flex-align text-[#313E45] text-[14px]">
          <div> 
             <Text tid='lawyer'  /> : </div>
          <div className="mr- "> { data?.creator?.username} </div>
        </div>
        <div className=" text-[#313E45] text-[14px]">
          <div>
              <Text tid='complete address' /> :</div>
          <div className="font-bold">
         {data.address}
          </div>
        </div>

      </div>
    </div> }
    </>
   
  )
}

export default DashboardInfos;
