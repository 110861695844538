import React from "react";
import user from "../../assets/img/icons/footer/user.svg";
import vasighe from "../../assets/img/dashboard/vasighe.svg";
import garantee from "../../assets/img/icons/footer/garantee.svg";
import moamele from "../../assets/img/icons/footer/moamele.svg";
import more from "../../assets/img/icons/footer/more.svg";
import plus from "../../assets/img/icons/footer/plus.svg";
import { Link } from "react-router-dom";
import AuthService from "../../Services/AuthService ";
import { useEffect } from "react";
import { Text } from "../../context/LanguageContext";
import info from "../../assets/img/dashboard/userinfo.svg";
import tablighat from "../../assets/img/dashboard/tablighat.svg";
import hokm from "../../assets/img/dashboard/hokm.svg";
const ResponsiveFootermenu = () => {
  const currentUser = AuthService.getCurrentUser();

  return (
    <div className="responsiveFootermenu">
      <div className="responsiveFootermenu-plus center">
        <Link
          to={
            currentUser?.role == "یتیم"
              ? "/dashboard/art"
              : currentUser?.role == "کفیل"
              ? "/dashboard/kafil"
              : currentUser?.role == "ناظر"
              ? "/dashboard"
              : "/register"
          }
        >
          {" "}
          <img src={plus} />
        </Link>
      </div>
      <div className="container center">
        <div className="d-flex-space w-[100%] sm:w-[80%]">
          {/* <div className="d-flex-align"> */}

          <div className="center-column mx-8">
            {currentUser?.role == "کفیل" ? (
              <Link to="/dashboard/info" className="mt-1">
                <img src={info} className="icon" />
                <span className="text-[12px] sm:text-[14px] text">
                  <Text tid="Account" />
                </span>
              </Link>
            ) : currentUser?.role == "یتیم" ? (
              <Link to="/dashboard/orphanInfo" className="mt-1">
                <img src={info} className="icon" />
                <span className="text-[12px] sm:text-[14px] text">
                  <Text tid="Account" />
                </span>
              </Link>
            ) : currentUser?.role == "ناظر" ? (
              <Link to="/dashboard/reportlist" className="mt-1">
                <img src={hokm} className="icon" />
                <span className="text-[12px] sm:text-[14px] text">
                <Text tid="Reports" />
                </span>
              </Link>
            ) : null}
          </div>
          <div className="center-column mx-8">
            {
              currentUser?.role == "کفیل" || currentUser?.role == "یتیم" ? <Link to="/dashboard/tarakonesh" className="mt-1">
              <img src={moamele} className="icon" />
              <span className="text-[12px] sm:text-[14px] text">
                {currentUser?.role == "کفیل" ? (
                  <Text tid="Transactions" />
                ) : (
                  " وجوه دریافتی"
                )}
              </span>
            </Link> : null
            }
          </div>
          {/* </div> */}
          {/* <div style={{ width: "59px", height: "59px" }} /> */}
          {/* <div className="d-flex-align"> */}
          <div className="center-column mx-8">
            {currentUser?.role == "کفیل" ? (
              <Link to="/dashboard/payment2" className="mt-1">
                <img src={garantee} className="icon" />
                <span className="text-[12px] sm:text-[14px] text">
                  {" "}
                  <Text tid="Donations" />
                </span>
              </Link>
            ) : currentUser?.role == "یتیم" ? (
              <Link to={`/dashboard/orphanReports/${currentUser.user_id}`} className="mt-1">
                <img src={hokm} className="icon" />
                <span className="text-[12px] sm:text-[14px] text">
                 <Text tid="Reports" />
                </span>
              </Link>
            ) : null}
          </div>
          <div className="center-column mx-8">
            <Link to="/dashboard/message" className="mt-1">
              <img src={tablighat} className="icon" />
              <span className="text-[12px] sm:text-[14px] text">
                <Text tid="Messages" />
              </span>
            </Link>
          </div>
          {/* <div className="center-column mx-8">
              <img src={more} />
              <div className="mt-1">سایر</div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveFootermenu;
