import React, { useContext, useEffect, useState } from 'react'
import { LanguageContext } from '../../../context/LanguageContext'
import { ToastContainer, toast } from 'react-toastify';
import NewsService from '../../../Services/NewsService';
import { useNavigate } from 'react-router-dom';

const NewsPeivast = () => {

  const searchNewsByTags = (e,tag) => {
    navigate({
      pathname : "news/tags",
      search : `?tag=${tag}`
    })
  }

  const{userLanguage} = useContext(LanguageContext);
  const [data , setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const getTags = async () => {
      const res = await NewsService.getTagsOfNews(userLanguage);
      if (res && res.status == 200) {
        setData(res.data.data);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    getTags();
  }, [userLanguage]);

 
  return (
<>
    {
      data && data.length>0 ? <div >
      <h4 className='News__title'> پیوست</h4>
      <div className='NewsPeivast'  >
       { data.map(( tag , index  ) =>  tag ? <div key={index} onClick={(e) => searchNewsByTags(e ,tag)} className='Tag'>{tag }</div> : null)}
      </div>
   </div> : null
    }
    <ToastContainer />
</>
  )
}

export default NewsPeivast