import React from "react";
import img from "../../../assets/img/Home/Group 68462.png";
import check from "../../../assets/img/icons/Check-green.svg";
import { Text } from "../../../context/LanguageContext";
import Button from "../../shared/Button";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../Services/AuthService ";
const HomeKefalat = () => {
  const navigate = useNavigate()
  return (
    <div className="">
      <div className="container">
        <div className="HomeKefalat d-flex-space">
          <div className="HomeKefalat__img ">
            <img src={img} alt=''/>
          </div>
          <div className="HomeKefalat__info">
            <div className="Tag"> <Text tid='Warranty Terms' /></div>
            <h4> <Text tid='Be happy Be happy' />  </h4>
            <div>
              <p className="HomeKefalat__info--p">
                <span>
                  <img src={check} alt='' />
                </span>
                <Text  tid='lorem'/>
              </p>
              <p className="HomeKefalat__info--p">
                <span>
                  <img src={check} alt='' />
                </span>
                <Text tid='lorem' />
              </p>
              <p className="HomeKefalat__info--p">
                <span>
                  <img src={check} />
                </span>
                <Text tid='lorem' />
              </p>
            </div>
      
         <div className="d-flex-align">
           <Button varient="primary" onClick = {() => navigate("/dashboard/kafil")}  ><Text tid="Become A Sponsor" /> </Button>
           <Button varient="secondary" style={{background : "transparent" }} ><Text tid="More Information"  /></Button>

         </div> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeKefalat;
