import React from "react";
import { useForm } from "react-hook-form";
import remove from "../../../../assets/img/icons/remove.png";
import Select from "../../../../Components/shared/Select";
import { Pagination } from "antd";
import { ReactComponent as Arrow } from "../../../../assets/img/icons/arrow-down.svg";
import TarakoneshTableOrphan from "./TarakoneshTableOrphan";
import AuthService from "../../../../Services/AuthService ";
import { Text, TextFunc } from "../../../../context/LanguageContext";
import { useState } from "react";
import TarakoneshTableSponsor from "./TarakoneshTableSponsor";
import { useNavigate } from "react-router-dom";

const GiftTarakonesh = () => {
  const [filterData , setFilterData] = useState();
  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const handleChangeFilter = async () => {
    const filterValue = getValues();
    const data = { filter : filterValue.filter_transaction};
    navigate(`/dashboard/tarakonesh?page=${1}`)
    setFilterData(data);
  };
  return (
    <div className="transaction_section" >
      <div className="d-flex-space transaction_header flex-col-responsive mt-5">
        <h3 className="text-[#313E45] title">
          {" "}
          {currentUser.role == "یتیم" ? <Text tid="Funds received" /> : <Text tid="Transactions" />}
        </h3>
        <div className="flex">
          {/* <button className="blue-back mx-4 center w-[54px] h-[54px]" onClick={setremove}>
            <img src={remove} />
          </button> */}
          <div>
          <div className="primary-select res-mr-20">
              <form>
                <Select
                  name="filter_transaction"
                  control={control}
                  errors={errors}
                  className="Footer__input filter-select"
                  value=""
                  handleChange={handleChangeFilter}
                >
                  <option value="" hidden disabled>
                    {TextFunc("Sort By")}
                  </option>
                  <option className="Select--option" value="">
                   {TextFunc("all")}
                  </option>
                  <option className="Select--option" value="dateDesc">
                    {TextFunc("last payment")}
                  </option>
                  <option className="Select--option" value="dateAsc">
                  {TextFunc("first payment")}
                  </option>

                  <option className="Select--option" value="priceDesc">
                    {TextFunc("The maximum amount")}
                  </option>
                  <option className="Select--option" value="priceAsc">
                  {TextFunc("The minimum amount")}

                  </option>
               
                </Select>
              </form>
            </div>
          </div>
        </div>
      </div>
      { currentUser.role == "یتیم"  ? <TarakoneshTableOrphan filterData = {filterData} /> : <TarakoneshTableSponsor filterData = {filterData} /> }
    </div>
  );
};

export default GiftTarakonesh;
