import React, { useContext } from "react";
import Logo from "../../assets/img/logos/logo-typo.svg";
import location from "../../assets/img/icons/location.svg";
import mail from "../../assets/img/icons/mail.svg";
import phone from "../../assets/img/icons/phone-green.svg";
import { Link } from "react-router-dom";
import Arrow from "../../assets/img/icons/footer-arrow.svg";
import ersal from "../../assets/img/icons/footer/ersal.svg";

import Input from "../shared/Input";
import { useForm } from "react-hook-form";
import Social from "./Social";
import { LanguageContext, Text, TextFunc } from "../../context/LanguageContext";
import whitimg from "../../assets/img/Home/white.png";
const Footer = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  return (
    <footer className="Footer">
      <div className="container">
        <div className="Footer__con">
          <div className="Footer__con--first">
            <img src={Logo} className="footer__logo mb-8" />
            <div className="d-flex-align  ">
              <img src={location} className="ml-4" />

              <p className="Footer-address">
                <Text tid="lorem" />
              </p>
            </div>
            <div className="d-flex-align my-4">
              <img src={mail} className="ml-4" />

              <p className="Footer-mail"><a href="mailto:Sj.modarresi133@gmail.com"><span className="ml-4">Sj.modarresi133@gmail.com</span></a> </p>
            </div>
            <div className="d-flex-align">
              <img src={phone} className="ml-4" />

              <p className="Footer-phone">
              <a href="tel:+989354884885" className="d-flex-align dir-rtl " >
                <span>9354884885</span>
                <span className="ml-4">(+98)</span>
                </a>
              </p>
            </div>
            <div className="mt-8">
              <Social />
            </div>
          </div>
          {/* {userLanguage === "fa" && */}
          <div className="Footer__con--sec">
            <h6 className="Footer__title">
              <Text tid="What should we do" />
            </h6>
            <ul className="Footer__list p-0">
              <li>
                <img src={Arrow} className="arrow" />
                <Link to="/">
                  {" "}
                  <Text tid="Protection of children" />{" "}
                </Link>
              </li>
              <li>
                <img src={Arrow} className="arrow" />
                <Link to="/">
                  <Text tid="baby care" />{" "}
                </Link>
              </li>
              <li>
                <img src={Arrow} className="arrow" />
                <Link to="/">
                  <Text tid="Education" />
                </Link>
              </li>
              <li>
                <img src={Arrow} className="arrow" />
                <Link to="/">
                  <Text tid="Health and life" />
                </Link>
              </li>
              <li>
                <img src={Arrow} className="arrow" />
                <Link to="/">
                  <Text tid="Religious centers" /> 
                </Link>
              </li>
              <li>
                <img src={Arrow} className="arrow" />
                <Link to="/">
                  <Text tid="Immediate actions" />
                </Link>
              </li>
            </ul>
          </div>
          {/* } */}
          {/* {userLanguage === "fa" && */}
          <div className="Footer__con--third">
            <h6 className="Footer__title">
              <Text tid="Donations" />
            </h6>
            <ul className="Footer__list">
              <li>
                <img src={Arrow} className="arrow" />
                <Link to="/">
                  {" "}
                  <Text tid="Islamic funds" />
                </Link>
              </li>
              <li>
                <img src={Arrow} className="arrow" />
                <Link to="/">
                  {" "}
                  <Text tid="Charitable donations" />
                </Link>
              </li>
              <li>
                <img src={Arrow} className="arrow" />
                <Link to="/">
                  {" "}
                  <Text tid="Monthly allowance" />
                </Link>
              </li>
              <li>
                <img src={Arrow} className="arrow" />
                <Link to="/">
                  {" "}
                  <Text tid="Gift" />
                </Link>
              </li>
              <li>
                <img src={Arrow} className="arrow" />
                <Link to="/">
                  {" "}
                  <Text tid="Other methods" />
                </Link>
              </li>
            </ul>
          </div>
          {/* } */}
          {/* {
              userLanguage !== "fa" &&  <div className="Footer__con--third">
              <h6 className="Footer__title"><Text tid="Quick access" /> </h6>
              <ul className="Footer__list">
                <li>
                  <img src={Arrow} />
                  <Link to="/"> <Text tid='Homepage' /> </Link>
                </li>
                <li>
                  <img src={Arrow} />
                  <Link to="/"> <Text tid='Services' /> </Link>
                </li>
                <li>
                  <img src={Arrow} />
                  <Link to="/"> <Text tid='News' /></Link>
                </li>
                <li>
                  <img src={Arrow} />
                  <Link to="/">  <Text tid='Gallery' /></Link>
                </li>
                <li>
                  <img src={Arrow} />
                  <Link to="/"> <Text tid='about us' /></Link>
                </li>
                <li>
                  <img src={Arrow} />
                  <Link to="/"><Text tid='Contact us' /> </Link>
                </li>
              </ul>
            </div>
            } */}
          <div className="Footer__con--fourth">
            <h6 className="Footer__title">
              {" "}
              <Text tid="Newsletters" />
            </h6>
            <form>
              <div className="relative">
                <Input
                  name="email"
                  control={control}
                  errors={errors}
                  register={{
                    required: true,
                  }}
                  className="Footer__input"
                  placeholder={TextFunc("Enter your email address")}
                />
                <button className="ersal-btn">
                  <img src={ersal} />
                </button>
              </div>
            </form>
            <div className="d-flex-align">
              <div className="center-column ml-6">
                <img src={whitimg} className="namad-img" />
                <p>تاییدیه مراجع</p>
              </div>
              <div className="center-column">
                <img src={whitimg} className="namad-img" />
                <p>نماد اعتماد </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* { userLanguage === "fa" &&  */}
          <ul className="d-flex-space FooterMenu-ul p-0">
            <li className="FooterMenu-li">
              <img src={Arrow} className="arrow" />
              <Link to="/">
                <Text tid="Home" />
              </Link>
            </li>
            <li className="FooterMenu-li">
              <img src={Arrow} className="arrow" />
              <Link to="/">
                <Text tid="Wisdom" />
              </Link>
            </li>
            <li className="FooterMenu-li">
              <img src={Arrow} className="arrow" />
              <Link to="/news">
                <Text tid="News" />
              </Link>
            </li>
            <li className="FooterMenu-li">
              <img src={Arrow} className="arrow" />
              <Link to="/gallery">
                <Text tid="Gallery" />
              </Link>
            </li>
            <li className="FooterMenu-li">
              <img src={Arrow} className="arrow" />
              <Link to="/About-us">
                <Text tid="about us" />
              </Link>
            </li>
            <li className="FooterMenu-li">
              <img src={Arrow} className="arrow" />
              <Link to="/Contact-us">
                <Text tid="contact us" />
              </Link>
            </li>
          </ul>
          {/* } */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
