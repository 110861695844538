import React, { useEffect } from "react";
import Selectss from "../Components/shared/Select";
import { Pagination, Select } from "antd";
import { useForm } from "react-hook-form";
import arrow from "../assets/img/icons/footer-arrow.svg";
import NewsSidebar from "../Components/layouts/News/NewsSidebar";
import { LanguageContext, Text, TextFunc } from "../context/LanguageContext";
import { useState } from "react";
import { useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";

const News = ({ setOpen, newsLinkStatus, setNewsLinkStatus }) => {
  const [searchParams] = useSearchParams();
  let location = useLocation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm();

  useEffect(() => {
    setOpen(false)
  }, [])

  const handleChangeFilter = async () => {
    const filterValue = getValues();
    // setFilterValue(filterValue.news_filter)
    navigate({ pathname: "/news" , search:`?sort_by=${filterValue.news_filter}` })
  };

  useEffect(() => { !searchParams.get("sort_by") && reset({"news_filter" : "" })  } , [searchParams.get("sort_by")])

  return (
   
    <div className="News">
      { location.pathname == "/news" ? (
        <div className="center-column ">
          <h2 className="AboutUs__title">
            {" "}
            <Text tid="latest news" />{" "}
          </h2>
          <div className="breadcrumbs">
            <div className="d-flex-align">
              <div>
                <Text tid="home" />
              </div>
              <img src={arrow} alt="" className="breadcrumbs_arrow" />
              <div>
                {" "}
                <Text tid="latest news" />{" "}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="container">
        
          { location.pathname == "/news" || location.pathname == "/News" ? (
            <div className="News__container">
            <Selectss
              name="news_filter"
              control={control}
              errors={errors}
              register={{
                required: true,
              }}
              className="Footer__input"
              handleChange={handleChangeFilter}
              defaultValue=""
            >
              <option value="" disabled>
              {TextFunc("Sort By")}
              </option>
              <option value="count_view">
              {TextFunc("View Count")}
              </option>
            </Selectss>
            </div>
          ) : null}
        

        <div className="NewsContainer">
          <div className="NewsContainer__column">
            <NewsSidebar />
          </div>
          <div>
            {/* Outlet------ */}
            <Outlet />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default News;
