import React, { useEffect, useState } from "react";
import { Text } from "../../../../context/LanguageContext";
import Phase from "../../../shared/phase";
import KafilStep1 from "./KafilStep1";
import KafilStep2 from "./KafilStep2";
import KafilStep3 from "./KafilStep3";
import KafilStep4 from "./KafilStep4";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../../Services/AuthService ";

const KafilSteps = ({
  setkafilpopup,
  setremove,
  setOrphanId,
  handleconfirmDelete,
}) => {
  const [PhaseNum, setPhaseNum] = useState(1);
  const [Success, setSuccess] = useState(false);
  const [selectedOrphansList, setSelectedOrphansList] = useState([]);
  const [ finalSelectedOrphansList, setFinalSelectedOrphansList] = useState([])
  const navigate = useNavigate();
  const PhaseList = [
    {
      PhaseNumber: 1,
      phaseName: <Text tid="Select orphans for maintenance" />,
    },
    {
      PhaseNumber: 2,
      phaseName: <Text tid="Confirm orphan selection" />,
    },
    {
      PhaseNumber: 3,
      phaseName: <Text tid="Submit for review" />,
    },
  ];

  const nextStep = () => {
    setPhaseNum((prevState) => prevState + 1);
  };
  const prevStep = () => {
    setPhaseNum((prevState) => prevState - 1);
  };
  const changeContentsForm = () => {
    switch (PhaseNum) {
      case 1:
        return (
          <KafilStep1
            setkafilpopup={setkafilpopup}
            setOrphanId={setOrphanId}
            nextStep={nextStep}
            setSelectedOrphansList={setSelectedOrphansList}
          />
        );
      case 2:
        return (
          <KafilStep2
            setremove={setremove}
            setkafilpopup={setkafilpopup}
            setOrphanId={setOrphanId}
            nextStep={nextStep}
            prevStep={prevStep}
            selectedOrphansList={selectedOrphansList}
            setFinalSelectedOrphansList = {setFinalSelectedOrphansList}
            handleconfirmDelete={handleconfirmDelete}
          />
        );
      case 3:
        return (
          <KafilStep3
            setkafilpopup={setkafilpopup}
            nextStep={nextStep}
            prevStep={prevStep}
            finalSelectedOrphansList = {finalSelectedOrphansList}
          />
        );
      case 4:
        return <KafilStep4 finalSelectedOrphansList = {finalSelectedOrphansList} />;

      default:
        break;
    }
  };
  return (
    <>
      <div className=" KafilSteps">
        <div className="">
          <Phase PhaseNum={PhaseNum} PhaseList={PhaseList} />
        </div>
        <div>{changeContentsForm()}</div>
      </div>
    </>
  );
};

export default KafilSteps;
