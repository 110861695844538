import React from 'react'
import NewsCat from './NewsCat'
import NewsLast from './NewsLast'
import NewsPeivast from './NewsPeivast'
import NewsSearch from './NewsSearch'

const NewsSidebar = ({handleKeyUp , searchNewsByTags}) => {
  return (
    <div>
        <NewsSearch />
        <NewsCat />
        <NewsLast />
        <NewsPeivast/>
    </div>
  )
}

export default NewsSidebar