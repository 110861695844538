import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Button from "../../shared/Button";
import Input from "../../shared/Input";
import { country } from "./CounryCode";
import * as yup from "yup";
import {
  LanguageContext,
  Text,
  TextFunc,
} from "../../../context/LanguageContext";
import { SyncOutlined } from "@ant-design/icons";
import AuthService from "../../../Services/AuthService ";
import { toast, ToastContainer } from "react-toastify";

const PhoneRegister = ({ setStep, setUsername , setMessage }) => {
  const [loading, setLoading] = useState(false);
  const { userLanguage } = useContext(LanguageContext);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });

  const onSubmit = async (data) => {
    setLoading(true);
    const username = data.country_code.substr(1) + parseInt(data.phone_number.replace(/\s+/g, ''));
    const res = await AuthService.register(userLanguage, username);
    if (res?.status == 200) {
      setMessage(res?.data?.message)
      setUsername(username);
      setStep(2);
    } else {
      toast(res, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    }
  };

  return (
    <div className="PhoneRegister">
      <h4>
        <Text tid="signin" /> | <Text tid="login" />
      </h4>
      {/* <PhoneInput
              country={"us"}
              enableSearch={true}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
            /> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="PhoneRegister__box">
          {/* <input type="text" className='PhoneRegister__box-input' pattern="[6789][0-9]{9}" placeholder='شماره همراه خود را وارد نمایید' /> */}
          <Input
            name="phone_number"
            control={control}
            errors={errors}
            inputClassName="phone_number_input"
            register={{
              required: {
                value: true,
                message: <Text tid="Insert Your Number" />,
              },
              pattern: {
                //value: /^\(?([0-9]{4})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                // message:
                //" فرمت درست(۱۲۱۲ ۱۲۳ ۰۹۱۳) شماره تماس وارد شده اشتباه است!",
              },
            }}
            className="PhoneRegister__box-input"
            placeholder={TextFunc("Insert Your Number")}
          />
          {/* <PhoneInput
      placeholder="Enter phone number"
      value={value}
      onChange={setValue}
      country='ir'
      disableCountryCode/> */}

          <Controller
            name="country_code"
            control={control}
            defaultValue="+98"
            render={({ field }) => (
              <select {...field} className="PhoneRegister__box-select">
                {country.map((i) => (
                  <option key={i.code} value={i.dial_code}>{i.dial_code}</option>
                ))}
              </select>
            )}
          />
        </div>
        <div className="center my-8">
          <Button varient="primary" disabled={loading}>
            {loading ? (
              <SyncOutlined style={{ fontSize: "22px" }} spin />
            ) : (
              <div>
                <Text tid="signin" /> | <Text tid="login" />
              </div>
            )}
          </Button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default PhoneRegister;
