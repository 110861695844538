import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://aytameamiralmomenin.com/api/";

class ReportsService {
  getReports = (userLanguage, pageNumber) => {
    return axios
      .get(API_URL + `${userLanguage}/reports?page=${pageNumber || 1}`, {
        headers: authHeader(),
      })
      .catch((error) => {
        if (error) {
          return error;
        }
      });
  };

  getReportById = (id) => {
    return axios
      .get(API_URL + `report/${id}`, {
        headers: authHeader(),
      })
      .catch((error) => {
        if (error) {
          return error;
        }
      });
  };

  getReportsType = (userLanguage) => {
    return axios
      .get(API_URL + `${userLanguage}/reports/types`)
      .catch((error) => {
        if (error) {
          return error;
        }
      });
  };

  getOrphanReports = (userLanguage, pageNumber, orphan_id) => {
    return axios
      .get(
        API_URL +
          `${userLanguage}/reports/${orphan_id}?page=${pageNumber || 1}`,
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        return error;
      });
  };

  PostReport = (userLanguage, data) => {
    return axios
      .post(API_URL + `${userLanguage}/reports/store`, data, {
        headers: {
          ...authHeader(),
          "content-type": "multipart/form-data",
        },
      })
      .catch((error) => error);
  };
}

export default new ReportsService();
