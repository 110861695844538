import axios from "axios";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Arrow from "../../../assets/img/icons/footer-arrow.svg";
import { LanguageContext, Text } from "../../../context/LanguageContext";
import NewsService from "../../../Services/NewsService";

const NewsCat = () => {
  const {userLanguage } = useContext(LanguageContext)
  const [data, setData] = useState([]);

  useEffect(() => {
    const getCategoriesNews = async () => {
      const res = await NewsService.getCategoriesNews(userLanguage);
      if (res.status == 200) {
        setData(res.data.data);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    getCategoriesNews();
  }, [userLanguage]);

  return (
    <>
    {data.length > 0 ? <div className="NewsCat blue-box p-10">
      <h4 className="News__title">
        <Text tid="Classification of items" />
      </h4>
      <ul className="NewsCat__ul">
        {data.map((NewsCat) => (
          <li key={NewsCat.id} className="d-flex-space">
            <div className="d-flex-align">
              <img src={Arrow} />{" "}
              <Link to={`/news/category?id=${NewsCat.id}`}>
                {NewsCat.name}
              </Link>
            </div>
            <div className="Number-box">{NewsCat.count}</div>
          </li>
        ))}
      </ul>
    </div> : null}
    <ToastContainer />
    </>
   
  );
};

export default NewsCat;
