import React from "react";
import Slider from "react-slick";
import { ReactComponent as ArrowButtonRight } from "../../../assets/img/icons/Group 67934.svg";
import { ReactComponent as ArrowButtonLeft } from "../../../assets/img/icons/Group 67933.svg";
import Purple from "../../../assets/img/icons/user-purple.svg";
import blue from "../../../assets/img/icons/users-blue.svg";
import pink from "../../../assets/img/icons/heart-red.svg";
const HomeNumResponsive = ({ counts }) => {
  const settings = {
    adaptiveHeight: true,
    dots: false,
    infinite: true,
    // className: "HomeNumResponsive__center",
    centerMode: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    centerPadding: "300px",
    nextArrow: <ArrowButtonRight />,
    prevArrow: <ArrowButtonLeft />,

    responsive: [
      {
        breakpoint: 850,
        settings: {
          centerPadding: "250px",
        },
      },
      {
        breakpoint: 720,
        settings: {
          centerPadding: "205px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: "170px",
        },
      },
      {
        breakpoint: 500,
        settings: {
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 450,
        settings: {
          centerPadding: "130px",
        },
      },
      {
        breakpoint: 390,
        settings: {
          centerPadding: "120px",
        },
      },
      {
        breakpoint: 360,
        settings: {
          centerPadding: "50px",
        },
      }
    ],
  };

  return (
    <div className="HomeNumResponsive">
      <div className="container mt-5">
        <Slider {...settings}>

          <div className="HomeNum__box ">
            <div className="HomeNum__circle  HomeNum__circle--1">
              <img src={Purple} alt="" />
            </div>
            <div className="HomeNum__box--num">{counts ? counts.users : 0}</div>
            <div className="HomeNum__box--title">کاربران فعال </div>
          </div>
          <div className="HomeNum__box  ">
            <div className="HomeNum__circle HomeNum__circle--2">
              <img src={pink} alt="" />
            </div>
            <div className="HomeNum__box--num">
              {counts ? counts.orphans : 0}
            </div>
            <div className="HomeNum__box--title">تعداد ایتام</div>
          </div>
          <div className="HomeNum__box">
            <div className="HomeNum__circle HomeNum__circle--3">
              <img src={blue} alt="" />
            </div>
            <div className="HomeNum__box--num">
              {counts ? counts.sponsors : 0}
            </div>
            <div className="HomeNum__box--title">تعداد حامیان</div>
          </div>
          
        </Slider>
      </div>
    </div>
  );
};

export default HomeNumResponsive;
