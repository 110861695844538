import React, { useContext, useEffect, useState } from "react";
import { LanguageContext, Text } from "../../../context/LanguageContext";
import LastNewsBox from "../../Common/LastNewsBox";
import NewsService from "../../../Services/NewsService";
import { ToastContainer, toast } from "react-toastify";

const NewsLast = () => {
  const { userLanguage } = useContext(LanguageContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getLastNews = async () => {
      const res = await NewsService.getNews(userLanguage);
      if (res && res.status === 200) {
        setData(res.data.data.data.slice(0 , 3));
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    getLastNews();
  }, [userLanguage]);

  return (
    <>
      {data && data.length > 0 ? (
        <div className="NewsLast ">
          <h4 className="News__title">
            <Text tid="latest news" />
          </h4>
          <div className="NewsLast__box">
           { data.map(item => <LastNewsBox key={item.id} lastnews={item} /> ) }
          </div>
        </div>
      ) : null}
      <ToastContainer />
    </>
  );
};

export default NewsLast;
