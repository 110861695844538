import React,{useEffect} from 'react'
import arrow from "../assets/img/icons/footer-arrow.svg";
import FaqContainer from '../Components/layouts/Faq.js/FaqContainer';

const Faq = ({setOpen}) => {
  useEffect(() => {
    setOpen(false)
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (

        <div className="AboutUs">
          <div className="center-column ">
            <h2 className="AboutUs__title">
                سوالات متداول
            </h2>
            <div className="breadcrumbs">
              <div className="d-flex-align">
                <div>صفحه ی اصلی</div>
                <img src={arrow} className="breadcrumbs_arrow" />
                <div>سوالات متداول</div>
              </div>
            
            </div>
          </div>
         <div className='container'>
         <FaqContainer /></div>
        </div>
  )
}

export default Faq