import React, { useContext, useEffect } from "react";
import { Text, LanguageContext } from "../../context/LanguageContext";
import phone from "../../assets/img/icons/phone-green.svg";
import arrow from "../../assets/img/icons/arrow-down.svg";
import email from "../../assets/img/icons/E-mail.svg";
import { Link, useNavigate } from "react-router-dom";
import HomeService from "../../Services/HomeService";
import { useState } from "react";
const Menu = () => {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getMenus = async () => {
      setLoading(true);
      const res = await HomeService.getHomeData(userLanguage);
      if (res && res.status === 200) {
        setMenus(res.data?.data?.menus?.header);
        setLoading(false);
      }
    };
    getMenus();
  }, [userLanguage]);

  const onChangeLanguage = (name) => {
    userLanguageChange(name);
    navigate("/");
  };

  return (
    <div className="Menu">
      <div className="container px-5">
        <div className="d-flex-space">
          {loading ? null : (
            <ul className="Menu__ul list d-flex-align">
              {menus && menus.length > 0
                ? menus.map((menu) => (
                    <li key={menu.id} className="Menu__li">
                      <a href={menu.link}>{menu.name}</a>
                    </li>
                  ))
                : null}
            </ul>
          )}

          <div className="d-flex-align Menu__info">
            <select
              onChange={(e) => onChangeLanguage(e.target.value)}
              value={userLanguage}
              className="d-flex-align Menu__info--select select-box"
            >
              <option value="fa">fa</option>
              <option value="en">En</option>

              <option value="ar">ar</option>
            </select>
            <div className="d-flex-align dir-rtl mx-10">
            <a href="mailto:Sj.modarresi133@gmail.com"><span className="ml-4">Sj.modarresi133@gmail.com</span></a> 
              <img src={email} />
            </div>
            <div >
            <a href="tel:+989354884885" className="d-flex-align dir-rtl " >
              <span>9354884885</span>
              <span className="mx-4">(+98)</span>
              <img src={phone} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
