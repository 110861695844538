import { Card, Pagination, Popover } from "antd";
import React, { useContext, useState } from "react";
import User from "../assets/img/dashboard/blank.png";
import heart from "../assets/img/icons/red-heart.png";
import tajob from "../assets/img/icons/tajob-circle.png";
import tajobabi from "../assets/img/icons/tajob-abi.png";
import checks from "../assets/img/icons/check.png";
import Button from "../Components/shared/Button";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import ModalStrap from "../Components/shared/ModalStrap";
import KafilPayPopoup from "../Components/layouts/Dashboard/kafil/KafilPayPopoup";
import { LanguageContext, Text } from "../context/LanguageContext";
import { useEffect } from "react";
import SponsorService from "../Services/SponsorService";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import AuthService from "../Services/AuthService ";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useAlertPayment from "../CustomHooks/useAlertPayment";

let selectedOrphansArray = [];
const OrphanRow = ({
  setPay,
  setkafilpopup,
  orphan,
  setOrphanId,
  setKafilPayInfo,
  setOrphanChosenToPay,
}) => {
  const [check, setCheck] = useState(false);
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const alertMessage = useAlertPayment(
    new Date(),
    new Date(orphan.payment_due)
  );

  const handlePaymentOption = (e) => {
    e.stopPropagation();
    setKafilPayInfo(orphan);
    setPay(true);
  };

  const handleDetailClick = (e, orphan_id) => {
    e.stopPropagation();
    setkafilpopup(true);
    setOrphanId(orphan_id);
  };

  const handleSelectRow = (id) => {
    if (selectedOrphansArray.indexOf(id) === -1) {
      selectedOrphansArray = [...selectedOrphansArray, id];
    } else {
      selectedOrphansArray.splice(selectedOrphansArray.indexOf(id), 1);
    }
    setCheck(!check);
  };

  return (
    <div
      className={`tarakonesh-table-grid rounded-[10px] ${
        selectedOrphansArray.indexOf(orphan.id) != -1
          ? "bg-[#1375A3] text-[#fff]"
          : "bg-[#F4F8FA] text-[#313E45]"
      } p-8 my-2 DashboardMainPage-res`}
      onClick={() => handleSelectRow(orphan.id)}
    >
      <div className="d-flex-align">
        <div className="relative">
          {alertMessage ? (
            <Popover
              placement={userLanguage == "en" ? "topLeft" : "topRight"}
              content={
                <div
                  style={{
                    direction: `${userLanguage == "en" ? "ltr" : "rtl"}`,
                  }}
                >
                  <span className="d-flex align-items-center">
                    {" "}
                    <ExclamationCircleOutlined
                      style={{ fontSize: "16px", color: "#FF1D25" }}
                    />{" "}
                    <span className="mx-2"> {alertMessage} </span>{" "}
                  </span>
                </div>
              }
              trigger="click"
            >
              <div
                className={`rounded-[50%] w-[20px] h-[20px]  text-[#fff] absolute right-0 badge-res cursor-pointer ${
                  new Date().getTime() <= new Date(orphan.payment_due).getTime()
                    ? `bg-[#ff9900]`
                    : `bg-[#FF1D25]`
                } `}
                onClick={(e) => e.stopPropagation()}
              >
                1
              </div>
            </Popover>
          ) : null}
          <img
            className="rounded-[50%] w-[50px] h-[50px] border-[2px] border-[#fff] overflow-hidden res-profile"
            src={orphan?.avatar || User}
          />
        </div>

        <div className="mx-3">
          <div
            className={`${
              selectedOrphansArray.indexOf(orphan.id) != -1
                ? "text-[#fff] "
                : "text-[#313E45]"
            } text-[14px] font-res-header f-w-500`}
          >
            {`${orphan.name}`}
          </div>
          {orphan.paid_months > 0 ? (
            <div
              className={`${
                selectedOrphansArray.indexOf(orphan.id) != -1
                  ? "text-[#fff]"
                  : "text-[#6F838E]"
              } text-[12px] font-res-header ${userLanguage == "en" ? "text-start" : "text-end" } `}
            >
              {userLanguage == "fa"
                ? `من ${orphan.paid_months}  ماه پول دادم `
                : userLanguage == "en"
                ? `I paid for ${orphan.paid_months} months`
                : userLanguage == "ar"
                ? `لقد دفعت لمدة ${orphan.paid_months} أشهر`
                : null}
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <img src={heart} className="mx-2" />
        {orphan.priority}
      </div>
      <div className="identifire_code">{orphan.identification_code}</div>
      <div className="dir-ltr">
        <span className="yearly_salary">$ {orphan.yearly_salary} / </span>
        <span>
          $ {parseFloat(Number(orphan?.yearly_salary) / 12).toFixed(2)}
        </span>
      </div>
      <div className="flex justify-between DashboardMainPage-res--actions">
        <div
          className="flex  cursor-pointer"
          onClick={(e) => handleDetailClick(e, orphan.id)}
        >
          <img
            src={
              selectedOrphansArray.indexOf(orphan.id) != -1 ? tajob : tajobabi
            }
            alt=""
            className="mx-2 w-[20px] h-[20px]"
          />
          <div
            className={`underline ${
              selectedOrphansArray.indexOf(orphan.id) == -1
                ? "text-[#1375A3]"
                : "text-[#fff]"
            } details`}
          >
            <Text tid="Details" />
          </div>
        </div>
        {/* {orphan.has_report ? (
            <Button
              varient="primary"
              onClick={() =>
                navigate({
                  pathname: `/dashboard/orphanReports/${orphan.id}`
                })
              }
            >
              {" "}
              <Text tid="Show Report" />{" "}
            </Button>
          ) : null} */}
        <div className="d-flex-align cursor-pointer">
          <div
            className="text-[#2DCA73]"
            onClick={(e) => handlePaymentOption(e)}
          >
            <Text tid="Payment option" />
          </div>
          {selectedOrphansArray.indexOf(orphan.id) != -1 ? (
            <img className="mx-2" src={checks} />
          ) : (
            <span className="mx-2 uncheck"></span>
          )}
        </div>
      </div>
    </div>
  );
};
const Payment2 = ({ setkafilpopup, setOrphanId, setOrphanChosenToPay }) => {
  const navigate = useNavigate();
  const [pay, setPay] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({});
  const [current, setCurrent] = useState(1);
  const [message, setMessage] = useState("");
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const [searchParams] = useSearchParams();
  const [kafilPayInfo, setKafilPayInfo] = useState([]);

  const currentUser = AuthService.getCurrentUser();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const toggle = () => setPay(false);

  useEffect(() => {
    if (!currentUser?.token && currentUser?.role != "کفیل") {
      navigate("/register");
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getAssignOrphans = async () => {
      setLoading(true);
      const res = await SponsorService.getAssignOrphans(
        searchParams.get("page")
      );
      if (res?.status === 200) {
        setData(res.data?.data?.data);
        setPage(res?.data?.data);
        setMessage(res.data.message);
        setLoading(false);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    setCurrent(
      searchParams.get("page")
        ? Number(Object.fromEntries([...searchParams]).page)
        : 1
    );
    if (currentUser) {
      getAssignOrphans();
    }
  }, [searchParams.get("page")]);

  const onChangePage = (pageNumber) => {
    setCurrent(pageNumber);
    navigate({
      pathname: "/dashboard/payment2",
      search: `?page=${pageNumber}`,
    });
  };

  // const handleChangeFilter = async () => {
  //   const filterValue = getValues();
  //   const data = { filter_orphans: filterValue.filter_orphan };
  //   setFilterData(data);
  // };

  const handleNext = () => {
    if (selectedOrphansArray.length > 0) {
      setOrphanChosenToPay(selectedOrphansArray);
      navigate("/dashboard/payment");
    } else {
      toast("لطفا یتیمی را  انتخاب کنید", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="flex items-center justify-between flex-col-responsive ">
            <div className="d-flex-align ">
              <h3 className="text-[#313E45] title text-[30px] mb-0 text-header">
              <Text tid="Orphans under your care"  />   
              </h3>
              <div className="blue-back text-center text-[#1375A3] mx-[30px] w-[40px] h-[40px]">
                {page?.total || 0}
              </div>
            </div>
            {/* <div className="primary-select res-mr-20 res-display-none">
              <form>
                <Select
                  name="filter_orphan"
                  control={control}
                  errors={errors}
                  className="Footer__input filter-select"
                  value=""
                  handleChange={handleChangeFilter}
                >
                  <option value="" hidden disabled>
                    {TextFunc("Sort By")}
                  </option>
                  <option className="Select--option" value="">
                    همه
                  </option>
                  <option className="Select--option" value="is_sayyed">
                    سید بودن
                  </option>

                  <option className="Select--option" value="not_sayyed">
                    سید نبودن
                  </option>

                  <option className="Select--option" value="high_priority">
                    بیشترین اولویت
                  </option>
                  <option className="Select--option" value="low_priority">
                    کمترین اولویت
                  </option>
                  <option className="Select--option" value="female">
                    دختر بودن
                  </option>
                  <option className="Select--option" value="male">
                    پسر بودن
                  </option>
                </Select>
              </form>
            </div> */}
          </div>
          {data && data.length > 0 ? (
            <div>
              <div className="KafilTable my-[40px]">
                <div className="tarakonesh-table-grid text-[#6F838E] DashboardMainPage-res">
                  <div />
                  <div><Text tid="Priority" /></div>
                  <div className="identifire_code"><Text tid="ID Code" /></div>
                  <div>
                    <span className="yearly_salary"><Text tid="yearly"  />/</span>
                    <span><Text tid="Monthly fee"  /></span>
                  </div>
                  <div />
                </div>

                <div className="row_wrapper">
                  {data.map((item) => (
                    <OrphanRow
                      key={item.id}
                      setkafilpopup={setkafilpopup}
                      setPay={setPay}
                      orphan={item}
                      setOrphanId={setOrphanId}
                      setKafilPayInfo={setKafilPayInfo}
                      setOrphanChosenToPay={setOrphanChosenToPay}
                    />
                  ))}
                </div>
              </div>
              <div className="d-flex-space flex-col-responsive">
                <div className="d-flex-align">
                  <Button
                    varient="primary"
                    onClick={handleNext}
                    className={`button--green ${
                      userLanguage === "en" ? "mr-4" : "ml-4"
                    }`}
                  >
                   <Text tid="next" />
                  </Button>
                  <button
                    className="Tag"
                    onClick={() => {
                      navigate("/dashboard/kafil");
                    }}
                  >
                    <Text tid="Cancel" />
                  </button>
                </div>
                <div className="res-mr-20">
                  <div className="Pagination my-3">
                    {page.per_page ? (
                      <Pagination
                        current={current}
                        pageSize={page?.per_page}
                        total={page?.total}
                        onChange={onChangePage}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="my-5">
              <Card>
                <h4> {message} </h4>
              </Card>
            </div>
          )}

          <ModalStrap isOpen={pay} toggle={toggle} centered={true}>
            <KafilPayPopoup toggle={toggle} kafilPayInfo={kafilPayInfo} />
          </ModalStrap>
        </div>
      )}
      {/* <ToastContainer /> */}
    </>
  );
};

export default Payment2;
