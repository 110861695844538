import React, { useState } from "react";
import calender from "../../../assets/img/icons/calender.svg";
import eye from "../../../assets/img/icons/eye.svg";
import Button from "../../shared/Button";
import { useNavigate } from "react-router-dom";
import { LanguageContext, Text } from "../../../context/LanguageContext";
import { useEffect } from "react";
import { useContext } from "react";

const HomeNewsCard = ({ news }) => {
  const { userLanguage } = useContext(LanguageContext);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    let locale;
    switch (userLanguage) {
      case "fa":
        locale = "fa-IR";
        break;
      case "en":
        locale = "en-US";
        break;
      case "ar":
        locale = "ar-SA";
        break;

      default:
        locale = "fa-IR";
        break;
    }
    if (news) {
      const date = new Date(news.publish_date); 
      setYear(date.toLocaleDateString(locale, { year: "numeric" }));
      setMonth(date.toLocaleDateString(locale, { month: "long" }));
      setDay(date.toLocaleDateString(locale, { day: "numeric" }));
    }
  }, []);

  const goToOneNews = () => {
    if (news && news.id) {
      navigate({
        pathname: "/news/detail",
        search: `?id=${news.id}`,
      });
    }
  };

  return news ? (
    <div className="HomeNewsCard" onClick={goToOneNews} style={{direction:"ltr"}} >
      <div className="HomeNewsCard__img">
        <img src={news.picture.path} alt={news.picture.alt} />
      </div>
      <div className="HomeNewsCard__infobox">
        <div className="d-flex-align row_reverse">
          <img className="mx-2" src={eye} />

          <div  >{news.view_count ? news.view_count : 0 }</div>
        </div>
        <span className="mx-3">|</span>

        <div className="d-flex-align row_reverse">
          <img className="mx-2" src={calender} />

          <div className="d-flex-align date">
          {year} <div className="px-2">{month}</div>  {day} 
          </div>
        </div>
      </div>
      <div style={{overflowWrap : "anywhere"}}  >
        <h4>{news.name}</h4>
        <p>{news.summary}</p>
      </div>
      <div className="HomeNewsCard__infos">
        <Button varient="primary" onClick={goToOneNews}>
          <Text tid="Read more" />
        </Button>
      </div>
    </div>
  ) : null;
};

export default HomeNewsCard;
