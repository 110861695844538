import React from "react";
import cancel from "../../assets/img/icons/cancel-blue.png";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
const ModalStrap = ({ children, remove, isOpen, toggle , title="" , ...args }) => {
  const { userLanguage } = useContext(LanguageContext);
  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        style={{ direction: userLanguage == "en" ? "ltr" : "rtl" }}
        {...args}
      >
        <ModalHeader className="modal_strap_header" toggle={toggle}>
          {title}
        </ModalHeader>
        <ModalBody className="modal_strap_body" >{children}</ModalBody>
        {/* <ModalFooter>
      <Button color="primary" onClick={toggle}>
        Do Something
      </Button>{' '}
      <Button color="secondary" onClick={toggle}>
        Cancel
      </Button>
    </ModalFooter> */}
      </Modal>
    </>
  );
};

export default ModalStrap;
