import React, { useContext, useState } from "react";
import Logo from "../../../assets/img/logos/logo-typo.svg";
import location from "../../../assets/img/icons/location.svg";
import mail from "../../../assets/img/icons/mail.svg";
import phone from "../../../assets/img/icons/phone-green.svg";
import { useForm } from "react-hook-form";
import Input from "../../shared/Input";
import Textarea from "../../shared/Textarea";
import Button from "../../shared/Button";
import Social from "../../Common/Social";
import { LanguageContext, Text, TextFunc } from "../../../context/LanguageContext";
import ContactService from "../../../Services/ContactService";
import { ToastContainer, toast } from "react-toastify";
import { SyncOutlined } from '@ant-design/icons';
const ContactusContainer = () => {
  const { userLanguage } = useContext(LanguageContext);
  const [errorMessage, setErrorMessage] = useState({
    name: "",
    surname: "",
    email: "",
    description: "",
  });
  const [loading, setLoading] = useState();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm();

  const validateForm = () => {
    switch (userLanguage) {
      case "fa":
        setErrorMessage({
          name: "فیلد نام الزامی است",
          surname: "فیلد نام خانوادگی الزامی است.",
          email: "فیلد ایمیل الزامی است.",
          description: "فیلد توضیحات الزامی است.",
        });
        break;
      case "en":
        setErrorMessage({
          name: "The name field is required",
          surname: "The last name field is required.",
          email: "Email field is required",
          description: "The description field is required",
        });
        break;
      case "ar":
        setErrorMessage({
          name: "حقل الاسم مطلوب",
          surname: "حقل الاسم الأخير مطلوب.",
          email: "حقل البريد الإلكتروني مطلوب.",
          description: "حقل الوصف مطلوب.",
        });
        break;

      default:
        setErrorMessage({
          name: "فیلد نام الزامی است",
          surname: "فیلد نام خانوادگی الزامی است.",
          email: "فیلد ایمیل الزامی است.",
          description: "فیلد توضیحات الزامی است.",
        });
        break;
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await ContactService.contact(userLanguage, data);
    if (res.status == 200) {
      toast(res?.data?.message, {
        position: "top-right",
        type: "success",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      reset({ name: "", surname: "", email: "", description: "" });
      setLoading(false);
    } else {
      toast(res?.message, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    }
  };

  return (
    <div className="ContactusContainer">
      <div className="ContactusContainer__info">
        <img src={Logo} className="footer__logo mb-8" />
        <div className="d-flex-align  ">
          <img src={location} className="ml-4" />

          <p className="Footer-address">
            m ipsum) به متنی آزمایشی و بی‌معنی در باور باور صنعت چاپ، صفحه‌آرایی
            و طراحی گرافیک گفته می‌شود. طراح گرافیک از ای
          </p>
        </div>
        <div className="d-flex-align my-4">
          <img src={mail} className="ml-4" />

          <p className="Footer-mail"><a href="mailto:Sj.modarresi133@gmail.com"><span className="ml-4">Sj.modarresi133@gmail.com</span></a> </p>
        </div>
        <div className="d-flex-align">
          <img src={phone} className="ml-4" />

          <p className="Footer-phone">
            <a href="tel:+989354884885" className="d-flex-align dir-rtl " >
              <span>9354884885</span>
              <span className="ml-4">(+98)</span>
            </a>
          </p>
        </div>
        <div className="mt-8">
          <Social />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="center">
          <h5><Text tid="application form" /></h5>
        </div>
        <div className="contact-us-form-row">
          <Input
            name="name"
            control={control}
            errors={errors}
            register={{
              required: errorMessage.name,
            }}
            className="Footer__input"
            placeholder={TextFunc("Name")}
          />
          <Input
            name="surname"
            control={control}
            errors={errors}
            register={{
              required: errorMessage.surname,
            }}
            className="Footer__input"
            placeholder={TextFunc("Last Name")}
          />
        </div>
        <div>
          <Input
            name="email"
            control={control}
            errors={errors}
            register={{
              required: errorMessage.email,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: errorMessage.email,
              },
            }}
            className="Footer__input"
            placeholder={TextFunc("Email")}
          />
          <Textarea
            name="description"
            control={control}
            errors={errors}
            register={{
              required: errorMessage.description,
            }}
            className="Footer__input"
            placeholder={TextFunc("Message text")}
          />
        </div>
        <Button varient="primary" onClick={validateForm} disabled={loading} >
          {loading ? <SyncOutlined style={{ fontSize: "22px" }} spin /> : <Text tid="Send" />}
        </Button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ContactusContainer;
