import React from "react";
import img1 from "../../../assets/img/Home/Screen Shot 2021-12-23 at 8.36.12 PM.png";
import img2 from "../../../assets/img/Home/Screen Shot 2021-12-23 at 8.12.56 PM.png";
import check from "../../../assets/img/icons/Check-green.svg";
import naghleghol from "../../../assets/img/icons/Naghleghol.svg";
const AboutUscontainer = () => {
  return (
    <div className="AboutUscontainer">
      <div className="container">
        <div className="AboutUscontainer__con">
          <div className="AboutUscontainer__firstrow">
            <div className="AboutUscontainer__info">
              <h5>شادی بده شاد باش</h5>
              <p>
                او در توصبف لورم ایپسوم یا طرح‌نما (به انگلیسی: Lorem ipsum) به
                متنی آزمایشی و بی‌معنی در باور باور صنعت چاپ، صفحه‌آرایی و طراحی
                گرافیک گفته می‌شود. طراح گرافیک از این متن به عنوان عنصری از
                ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح
                سفارش گرفته شده استفاده می نماید، تا از نظر گرافیکی نشانگر
                چگونگی نوع و اندازه فونت و ظاهر متن باشد. معمولا طراحان گرافیک
                برای صفحه‌آرایی، نخست از متن‌های آزمایشی و بی‌معنی استفاده
                می‌کنند تا صرفا به مشتری یا صاحب کار خود نشان دهند که صفحه طراحی
                یا صفحه بندی شده بعد از اینکه متن در آن قرار گیرد چگونه به نظر
                می‌رسد و قلم‌ها و اندازه‌بندی‌ها چگونه در نظر گرفتهلورم ایپسوم
                یا طرح‌نما (به انگلیسی: Lorem ipsum) به متنی آزمایشی و بی‌معنی
                در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک
                از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و
                ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده می
                نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت و ظاهر
                متن باشد. معمولا طراحان گرافیک برای صفحه‌آرایی، نخست از متن‌های
                آزمایشی و بی‌معنی استفاده می‌کنند تا صرفا به مشتری یا صاحب کار
                خود نشان دهند که صفحه طراحی یا صفحه بندی شده بعد از اینکه متن در
                آن قرار گیرد چگونه به نظر می‌رسد و قلم‌ها و اندازه‌بندی‌ها چگونه
                در نظر گرفته
              </p>
              <div className="AboutUscontainer__chechlist">
                <div className="d-flex-align mt-4">
                  <img src={check} className="mx-4" />
                  <p>
                    نشان دهند که صفحه طراحی یا صفحه بندی شده بعد از اینکه متن در
                    در نظر گرفته
                  </p>
                </div>
                <div className="d-flex-align mt-4">
                  <img src={check} className="mx-4" />
                  <p>
                    نشان دهند که صفحه طراحی یا صفحه بندی شده بعد از اینکه متن در
                    در نظر گرفته
                  </p>
                </div>
                <div className="d-flex-align mt-4" >
                  <img src={check} className="mx-4" />
                  <p>
                    نشان دهند که صفحه طراحی یا صفحه بندی شده بعد از اینکه متن در
                    در نظر گرفته
                  </p>
                </div>
              </div>
            </div>
            <img src={img1} className="AboutUscontainer__img" />
          </div>
          <div className="d-flex-align aboutus--naghl">
            <img src={naghleghol} className="ml-8" />
            <p>
              سد که لورم ایپسوم یا طرح‌نما (به انگلیسی: Lorem ipsum) به متنی
              آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته
              می‌شود. طراح پیامبران گرافیک از این متن به عنوان عنصری از ترکیب
              بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش
            </p>
          </div>
          <div>
            <div className="AboutUscontainer__firstrow">
            <img src={img1} className="AboutUscontainer__img" />

              <div className="AboutUscontainer__info">
                <h5>شادی بده شاد باش</h5>
                <p>
                  او در توصبف لورم ایپسوم یا طرح‌نما (به انگلیسی: Lorem ipsum)
                  به متنی آزمایشی و بی‌معنی در باور باور صنعت چاپ، صفحه‌آرایی و
                  طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به عنوان
                  عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری
                  و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر گرافیکی
                  نشانگر چگونگی نوع و اندازه فونت و ظاهر متن باشد. معمولا طراحان
                  گرافیک برای صفحه‌آرایی، نخست از متن‌های آزمایشی و بی‌معنی
                  استفاده می‌کنند تا صرفا به مشتری یا صاحب کار خود نشان دهند که
                  صفحه طراحی یا صفحه بندی شده بعد از اینکه متن در آن قرار گیرد
                  چگونه به نظر می‌رسد و قلم‌ها و اندازه‌بندی‌ها چگونه در نظر
                  گرفتهلورم ایپسوم یا طرح‌نما (به انگلیسی: Lorem ipsum) به متنی
                  آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته
                  می‌شود. طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی
                  برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش
                  گرفته شده استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی
                  نوع و اندازه فونت و ظاهر متن باشد. معمولا طراحان گرافیک برای
                  صفحه‌آرایی، نخست از متن‌های آزمایشی و بی‌معنی استفاده می‌کنند
                  تا صرفا به مشتری یا صاحب کار خود نشان دهند که صفحه طراحی یا
                  صفحه بندی شده بعد از اینکه متن در آن قرار گیرد چگونه به نظر
                  می‌رسد و قلم‌ها و اندازه‌بندی‌ها چگونه در نظر گرفته
                </p>
                <div className="AboutUscontainer__chechlist">
                  <div className="d-flex-align mt-4">
                    <img src={check} className="mx-4" />
                    <p>
                      نشان دهند که صفحه طراحی یا صفحه بندی شده بعد از اینکه متن
                      در در نظر گرفته
                    </p>
                  </div>
                  <div className="d-flex-align mt-4">
                    <img src={check} className="mx-4" />
                    <p>
                      نشان دهند که صفحه طراحی یا صفحه بندی شده بعد از اینکه متن
                      در در نظر گرفته
                    </p>
                  </div>
                  <div className="d-flex-align mt-4">
                    <img src={check} className="mx-4" />
                    <p>
                      نشان دهند که صفحه طراحی یا صفحه بندی شده بعد از اینکه متن
                      در در نظر گرفته
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutus__footer mt-14">
          <p>
            او در توصبف لورم ایپسوم یا طرح‌نما (به انگلیسی: Lorem ipsum) به متنی
            آزمایشی و بی‌معنی در باور باور صنعت چاپ، صفحه‌آرایی و طراحی گرافیک
            گفته می‌شود. طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی
            برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده
            استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت
            و ظاهر متن باشد. معمولا طراحان گرافیک برای صفحه‌آرایی، نخست از
            متن‌های آزمایشی و بی‌معنی استفاده اندازه‌بندی‌ها چگونه در نظر گرفته
          </p>
          <p>
            او در توصبف لورم ایپسوم یا طرح‌نما (به انگلیسی: Lorem ipsum) به متنی
            آزمایشی و بی‌معنی در باور باور صنعت چاپ، صفحه‌آرایی و طراحی گرافیک
            گفته می‌شود. طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی
            برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده
            استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت
            و ظاهر متن باشد. معمولا طراحان گرافیک برای صفحه‌آرایی، نخست از
            متن‌های آزمایشی و بی‌معنی استفاده اندازه‌بندی‌ها چگونه در نظر گرفته
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUscontainer;
