import React, { useContext, useEffect } from "react";
import calender from "../assets/img/icons/calender.svg";
import Img from "../assets/img/Home/News/NewsPic.png";
import eye from "../assets/img/icons/eye.svg";
import img from "../assets/img/Home/News/people-old-young-africa-child-charity-790616-pxhere.com.png";
import GalleryCard from "../Components/layouts/Gallery/GalleryCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import GalleryService from "../Services/GalleryService";
import { LanguageContext } from "../context/LanguageContext";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import { toast, ToastContainer } from "react-toastify";
const GalleryVideoDetail = ({ setOpen }) => {
  const [searchParams] = useSearchParams();
  const { userLanguage } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    setOpen(false);
  }, []);
  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getOneVideo = async () => {
      setLoading(true);
      const res = await GalleryService.getOneVideo(
        userLanguage,
        searchParams.get("id")
      );
      if (res.status == 200) {
        DateSetting(res.data.data)
        setLoading(false);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    getOneVideo();
  }, [searchParams.get("id")]);

  const DateSetting = (video) => {
    let locale;
    switch (userLanguage) {
      case "fa":
        locale = "fa-IR";
        break;
      case "en":
        locale = "en-US";
        break;
      case "ar":
        locale = "ar-SA";
        break;

      default:
        locale = "fa-IR";
        break;
    }
    if (video) {
        const date = new Date(video.created_at);
        const year = date.toLocaleDateString(locale, { year: "numeric" });
        const month = date.toLocaleDateString(locale, { month: "long" });
        const day = date.toLocaleDateString(locale, { day: "numeric" });
        const finalData =  { ...video, created_at: { year, month, day } };
        if(video.related_videos){
          video.related_videos.map(relatedVideo => {
            const date = new Date(relatedVideo.publish_date);
            const year = date.toLocaleDateString(locale, { year: "numeric" });
            const month = date.toLocaleDateString(locale, { month: "long" });
            const day = date.toLocaleDateString(locale, { day: "numeric" });
            const relatedVideoData =  { ...video, publish_date: { year, month, day } };
            setData(relatedVideoData)
          })
        }
        setData(finalData);
    } else {
      setData([]);
    }
  };

  return (
    <div className="GalleryVideo">
      <div className="container">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <div className="center">
              {data.name ? (
                <h2 className="GalleryVideo__title">{data.name}</h2>
              ) : null}
            </div>
            {
              data && Object.keys(data).length != 0 ? <div className="center my-8">
              <div className="d-flex-align ">
                <img className="ml-4" src={eye} />

                <div> {data.view_count} </div>
              </div>
              <span className="mx-4">|</span>

              <div className="d-flex-align">
                <img className="ml-4" src={calender} />

                <div className="d-flex-align">
                  {" "}
                  {data.created_at.day}  <div className="px-2">{data.created_at.month}</div> {data.created_at.year}
                </div>
              </div>
            </div> :
            null
            }
            
            <div className="center video-detail-wrapper">
              {data.video ? (
                <video controls className="video-thubnail">
                  <source src={data.video} type="video/mp4" />
                </video>
              ) : null}
            </div>
            {data.description ? (
              <p className="my-10">{data.description}</p>
            ) : null}
            {data && data.tags && data.tags.length > 0 ? (
              <div className="mt-20">
                <h5 className="News__title">پیوست</h5>
                <div className="d-flex-align my-10 ">
                  {data.tags.map((tag , index) => (
                    <div key={index} className="Tag ml-8">{tag.name}</div>
                  ))}
                </div>
              </div>
            ) : null}
            {data && data.related_videos && data.related_videos.length > 0 ? (
              <div>
                <div className="center ">
                  <h3>ویدئو های مرتبط</h3>
                </div>
                <div className="LastNews__grid videoCardDetai">
                  {data.related_videos.map((video, index) => (
                    <GalleryCard
                      type="video"
                      item={video}
                      onClick={() =>
                        navigate({
                          pathname: "/gallery-video-detail",
                          search: `?id=${video.id}`,
                        })
                      }
                      key={index}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default GalleryVideoDetail;
