import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../shared/Input";
import Select from "../../shared/Select";
import Button from "../../shared/Button";
import {
  LanguageContext,
  Text,
  TextFunc,
} from "../../../context/LanguageContext";
import { DollarOutlined, SyncOutlined } from "@ant-design/icons";
import PaymentService from "../../../Services/PaymentService";
import { toast } from "react-toastify";
import AuthService from "../../../Services/AuthService ";
import { NumericFormat } from "react-number-format";
import { ThreeDots } from "react-loader-spinner";

const HomeHelpForm = () => {
  const currentUser = AuthService.getCurrentUser();
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const { userLanguage } = useContext(LanguageContext);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const [active, setActive] = useState();

  const onSubmit = async (data) => {
    setBtnLoading(true);
    const res = currentUser?.token
      ? await PaymentService.PaymentUserDonation(data, userLanguage)
      : await PaymentService.PaymentDonation(data, userLanguage);

    if (res?.status == 200) {
      try {
        const url = new URL(res.data);
        if (url?.protocol == "https:") {
          window.location.replace(res.data);
          // setBtnLoading(false);
        }
      } catch (err) {
        toast(res?.data, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setBtnLoading(false);
    } else {
      toast(res, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setBtnLoading(false);
    }
  };

  const handleChangeAmount = async (e) => {
    setLoading(true);
    const res = await PaymentService.getCurrencies();

    if (res?.status === 200) {
      const currency = res.data?.data?.currencies[0].amount;

      e.target.value >= 0
        ? setAmount(e.target.value * currency)
        : setAmount(null);

      setLoading(false);
    } else {
      toast(res, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    }
  };

  return (
    <div>
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        {!currentUser ? (
          <div>
            <Input
              name="first_name"
              control={control}
              errors={errors}
              inputClassName={`text-center ${
                userLanguage == "en" ? "dir-ltr" : "dir-rtl"
              } `}
              register={{
                required: TextFunc("Filling this field is required"),
              }}
              placeholder={TextFunc("First Name")}
            />
            <Input
              name="last_name"
              control={control}
              errors={errors}
              inputClassName={`text-center ${
                userLanguage == "en" ? "dir-ltr" : "dir-rtl"
              } `}
              register={{
                required: TextFunc("Filling this field is required"),
              }}
              placeholder={TextFunc("Last Name")}
            />
          </div>
        ) : null}
        <Input
          type="number"
          name="amount"
          control={control}
          errors={errors}
          inputIcon={<DollarOutlined />}
          minNumberValue={0}
          onInput={handleChangeAmount}
          iconClassName="text-5xl text-[#606060] "
          inputIconStyle={{ top: "4px", left: "10px", right: "unset" }}
          inputClassName="text-center dir-ltr"
          register={{
            required: TextFunc("Filling this field is required"),
            min: {
              value: 0, // Replace with your desired minimum value
              message: TextFunc("The amount entered is not correct"),
            },
          }}
          placeholder={TextFunc("Enter The Appropriate Amount")}
        />

      <div className="d-flex justify-content-center" >
      {loading ? (
          <ThreeDots 
          height="22" 
          width="20" 
          radius="9"
          color="#4fa94d" 
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
           />
        ) : (
          <div className="text-[green]">
            {amount ? (
              <NumericFormat
                displayType="text"
                value={amount}
                thousandSeparator=","
                decimalScale={2}
                suffix={"ریال"}
              />
            ) : null}
          </div>
        )}
      </div>

        {/* <div className="HomeHelp__con-box--moneyrow">
          <div
            onClick={() => setActive(1)}
            className={`HomeHelp__con-box--moneyrow-div ${
              active === 1 ? "HomeHelp__con-box--moneyrow-div--active" : ""
            }`}
          >
            $ 25
          </div>
          <div
            onClick={() => setActive(2)}
            className={`HomeHelp__con-box--moneyrow-div ${
              active === 2 ? "HomeHelp__con-box--moneyrow-div--active" : ""
            }`}
          >
            $ 50
          </div>
          <div
            onClick={() => setActive(3)}
            className={`HomeHelp__con-box--moneyrow-div ${
              active === 3 ? "HomeHelp__con-box--moneyrow-div--active" : ""
            }`}
          >
            $ 100
          </div>
          <div
            onClick={() => setActive(4)}
            className={`HomeHelp__con-box--moneyrow-div ${
              active === 4 ? "HomeHelp__con-box--moneyrow-div--active" : ""
            }`}
          >
            $ 250
          </div>
        </div> */}
        {/* <Select
          name="select"
          control={control}
          errors={errors}
          register={{
            required: true,
          }}
        >
          <option>1</option>
          <option>2</option>
          <option>3</option>
        </Select> */}
        <Input
          name="description"
          control={control}
          errors={errors}
          register={{
            required: TextFunc("Filling this field is required"),
          }}
          inputClassName={`text-center ${
            userLanguage == "en" ? "dir-ltr" : "dir-rtl"
          } `}
          placeholder={TextFunc("The Topic Of Financial Aid")}
        />
        <Button varient="primary" disabled={btnLoading} >
        {btnLoading ? (
                  <SyncOutlined style={{ fontSize: "22px" }} spin />
                ) : (
                  <Text tid="Donate Quickly" />
                )}  
        </Button>
      </form>
    </div>
  );
};

export default HomeHelpForm;
