import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://aytameamiralmomenin.com/api/";

class MessageService {
  getMessages = (pageNumber) => {
    return axios
      .get(API_URL + `chat-session/list?page=${pageNumber}`, {
        headers: authHeader(),
      })
      .catch((error) => {
        return error;
      });
  };

  getMessage = (chatSessionId) => {
    return axios
      .post(API_URL + `chat-session/show`, chatSessionId , { headers: authHeader() })
      .catch((error) => {
        return error;
      });
  };

  sendMessage = (data) => {
    return axios
      .post(API_URL + `chat-session/send`, data, { headers: authHeader() })
      .catch((error) => {
        return error;
      });
  };

  deleteMessage = (selectedIdList) => {
    return axios
      .post(
        API_URL + `messages-delete`,
        selectedIdList,
        {
          headers: {
            ...authHeader(),
          },
        }
      )
      .catch((error) => {
        return error;
      });
  };
}
export default new MessageService();
