import { Controller } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import persian from "react-date-object/calendars/persian";
import gregorian from "react-date-object/calendars/gregorian";
import arabic from "react-date-object/calendars/arabic";
import persian_fa from "react-date-object/locales/persian_fa";
import gregorian_en from "react-date-object/locales/gregorian_en";
import arabic_ar from "react-date-object/locales/arabic_ar"
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import calender from "../../assets/img/icons/calender.svg";
const DateInput = ({control , name , errors , register , ...rest}) => {
    const [dateType , setDateType] = useState({calender : "" , locale : "" })
    const { userLanguage, userLanguageChange } = useContext(LanguageContext);
    const error = errors[name];

    useEffect(()=>{
        switch (userLanguage) {
            case "fa":
              setDateType({calender : persian , locale :persian_fa })
            break;
            case "en":
              setDateType({calender : gregorian , locale :gregorian_en })
            break;
            case "ar":
              setDateType({calender : arabic , locale :arabic_ar })
            break;
        
          default:
            setDateType({calender : persian , locale :persian_fa })
        }
        
      } , [])
    return(
      <div className="relative" >
          <Controller
        control={control}
        rules={register}
        name={name}
        render={({
          field: { onChange, name, value },
          fieldState: { invalid, isDirty }, //optional
          formState: { errors }, //optional, but necessary if you want to show an error message
        }) => (
          <>
          <DatePicker
            calendar={dateType.calender}
            locale={dateType.locale}
            calendarPosition= {userLanguage=="en" ? "bottom-left" :  "bottom-right"}
            onChange={(date) => {
              onChange(date?.isValid ? date : "");
            }}
           {...rest} 
           value={value}
          />
          <img src={calender} className=" absolute top-[40%] date-input" />
            {error &&  <span className="danger" >{error.message}</span>}
          </>
        )}
      />
      </div>
    )
}

export default DateInput;