import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Text } from "../../context/LanguageContext";
import Arrow from "../../assets/img/icons/arrow-down.svg";
const Select = ({
  name,
  value,
  message,
  control,
  register,
  label,
  absolute,
  className,
  errors,
  defaultValue="",
  LabelIcon,
  inputIcon,
  children,
  handleChange = () => {},
  ...rest
}) => {
  const error = errors[name];
  return (
    <div className={`Select relative ${className}`}>
      <label className="d-flex">
        {label && <Text tid={label} />}
        {LabelIcon && (
          <div className={`${label ? "mx-4" : ""}`}>{LabelIcon}</div>
        )}
      </label>

      <Controller
        name={name}
        control={control}
        rules={register}
        defaultValue={defaultValue}
        value={value}
        render={({ field }) => (
          <select
          {...field}
           onChange={(e) => {
            field.onChange(e)
            handleChange()
          }} 
          
            {...rest} >
            {children}
          </select>
        )}
      />
      <div className="Select__Arrow">
        <img src={Arrow} />
      </div>
      {inputIcon && <div className="input__icon">{inputIcon}</div>}
      {error && <span className="input__message danger">{error.message}</span>}
    </div>
  );
};

Select.propTypes = {
  classes: PropTypes.any,
  control: PropTypes.any,
  errors: PropTypes.any,
  label: PropTypes.string,
  message: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.any,
  value: PropTypes.any,
};
export default Select;
