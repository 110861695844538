import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../Components/Common/DashboardSidebar";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import ResponsiveFootermenu from "../Components/Common/responsiveFootermenu";
import AuthService from "../Services/AuthService ";

const DashboardLayout = ({ children, setregister }) => {
  return (
    <>
      <div className="container px-0">
        <div className="DashboardLayout relative">
          <DashboardSidebar />

          <main id="target" reference="target" className="main-user">
            <Outlet />
          </main>
        </div>
      </div>
      {/* <ResponsiveFootermenu /> */}
    </>
  );
};

export default DashboardLayout;
