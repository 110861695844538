import React from "react";
import warning from "../../../../assets/img/icons/warning-mosala.png";
import Button from "../../../shared/Button";
import { ReactComponent as ArrowButton } from "../../../../assets/img/icons/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import { LanguageContext, Text } from "../../../../context/LanguageContext";
import { useContext } from "react";

const KafilStep4 = ({ nextStep, prevStep, finalSelectedOrphansList }) => {
  const { userLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();
  return (
    <div>
      <h3 className="text-[#313E45] text-[30px]">
        <Text tid="Orphans under your care" />{" "}
      </h3>
      <div className="d-flex ">
        <img src={warning} className="ml-[10px]" />
        <p className="text-[18px] mb-0">
          {userLanguage == "fa"
            ? `        از کمک به بچه‌های یتیم شما سپاسگزاریم.بسیار خوشحالم که شما به عنوان کفیل، به ${finalSelectedOrphansList.length} یتیم که به سرپرستی شما واگذار شده‌اند، کمک کرده‌اید.
            اما به دلیل اینکه  تصمیم‌گیری درباره سرپرستی یک یتیم، مسئولیت ویژه‌ای دارد، لطفا منتظر بمانید تا تایید نهایی این امر برای شما ارسال شود.`
            : userLanguage == "ar"
            ? `اشكركم على مساعدة الايتام وانا سعيد جدا انك ككفيل ساعدت الايتام ${finalSelectedOrphansList.length} الموكلين برعايتك. ولكن لأن قرار رعاية اليتيم مسؤولية خاصة ، يرجى الانتظار حتى يتم إرسال التأكيد النهائي إليك.`
            : `Thank you for helping the orphans. I am very happy that you, as a sponsor, have helped the ${finalSelectedOrphansList.length} orphans entrusted to your care. But because the decision to take care of an orphan is a special responsibility, please wait until the final confirmation is sent to you.`}
        </p>
      </div>
      <div className="center my-[40px] ">
        <Button varient="primary">
          <div className="flex">
            <div onClick={() => navigate(0)}><Text tid="Sponsorship page" />  </div>
            <div className="button-arrow">
              <ArrowButton />
            </div>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default KafilStep4;
