import { Card, Pagination, Select } from "antd";
import React, { useContext } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ReactComponent as Arrow } from "../assets/img/icons/arrow-down.svg";
import Button from "../Components/shared/Button";
import { LanguageContext, Text } from "../context/LanguageContext";
import tajobabi from "../assets/img/icons/tajob-abi.png";
import { useState } from "react";
import { useEffect } from "react";
import ReportsService from "../Services/ReportsService";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import useMultiDate from "../CustomHooks/useMultiDate";
import arrow from "../assets/img/icons/RIGHT-ARROW.png";
import AuthService from "../Services/AuthService ";

const ReportRow = ({ report }) => {
  const navigate = useNavigate();
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  const date = useMultiDate(report.created_at, "numeric", "numeric", "numeric");

  // const handleDetailClick = (orphan_id) => {
  //   setkafilpopup(true)
  //   setOrphanId(orphan_id)
  // };

  return (
    <div
      className="table-grid-4 text-[#313E45] bg-[#F4F8FA] rounded-[10px] p-4 my-2 cursor-pointer orphan_report_res"
      // onClick={() => {
      //   navigate(`/dashboard/report/${report.id}`);
      // }}
    >
      <div className="font-bold text-sm-[14px] font-res-header">
        {report?.title}
      </div>
      <div className="font-bold text-sm-[14px] font-res-header">
        {report.type.name}
      </div>
      <div className="text-sm-[14px] font-res-header ">{date}</div>
      <div className="flex justify-end DashboardMainPage-res--actions">
        {/* <div
          className="flex items-center cursor-pointer mx-md-4"
          // onClick={() => handleDetailClick(report.orphan_id)}
        >
          <img src={tajobabi} alt="" className="mx-2 w-[15px] h-[15px]" />
          <div className={`underline  text-[#1375A3]`}>جزئیات</div>
        </div> */}
        <div className="d-flex-align">
          {/* <Button
            onClick={(e) => {
              e.stopPropagation();
              // navigate({ pathname :"/dashboard/report" , search : `?orphanId=${report.orphan_id}` });
            }}
            varient="primary"
          >
            <Text tid="Submit a report" />
          </Button> */}
          <Button
            varient="primary"
            className={`button--green ${
              userLanguage === "en" ? "ml-8" : "mr-8"
            }`}
            onClick={() => navigate(`/dashboard/report/${report.id}`)}
          >
            <Text tid="Details" />
          </Button>
        </div>
      </div>
    </div>
  );
};

const OrphanReports = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({});
  const [current, setCurrent] = useState(1);
  const [searchParams] = useSearchParams();
  const { userLanguage } = useContext(LanguageContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { orphanId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getOrphanReports = async () => {
      setLoading(true);
      const res = await ReportsService.getOrphanReports(
        userLanguage,
        searchParams.get("page"),
        orphanId
      );
      if (res?.status == 200) {
        if (res?.data?.data?.length == 0) {
          setMessage(res?.data?.message);
        }
        setData(res?.data?.data?.data);
        setPage(res?.data?.data);
        setLoading(false);
      } else {
        toast(res?.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    setCurrent(
      searchParams.get("page")
        ? Number(Object.fromEntries([...searchParams]).page)
        : 1
    );
      getOrphanReports();
  }, [searchParams.get("page")]);

  const onChangePage = (pageNumber) => {
    setCurrent(pageNumber);
    navigate({
      pathname: `/dashboard/orphanReports/${orphanId}`,
      search: `?page=${pageNumber}`,
    });
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : data && data.length > 0 ? (
        <div>
          <div className="flex items-center justify-between flex-col-responsive mb-5 ">
            <div className="d-flex-align">
              <button
                className="blue-back center w-[50px] h-[50px] ml-10 "
                onClick={() => navigate(-1)}
              >
                <img src={arrow} />
              </button>
              <h3 className="text-[#313E45] text-sm-[30px] mb-0">
              <Text tid="Report list" />
              </h3>
              <div className="blue-back text-[#1375A3] mx-[30px] w-[40px] h-[40px] text-center">
                {page?.total}
              </div>
            </div>
            {/* <div className="primary-select">
            <Select suffixIcon={<Arrow />} placeholder="مرتب سازی بر اساس">
              <Option className="Select--option" value="farsialpabet">
                فهرست نام های فارسی
              </Option>

              <Option className="Select--option" value="enalphabet">
                فهرست نام های علمی یا انگلیسی
              </Option>
              <Option className="Select--option" value="Yiminghe">
                فهرست خانواده ها
              </Option>
              <Option className="Select--option" value="cat">
                دسته بندی بیماری ها
              </Option>
            </Select>
          </div> */}
          </div>
          <div>
            <div className="table-grid-4 text-[#6F838E] mb-[20px] orphan_report_res px-4">
              <div><Text tid="The title of the report"  /> </div>
              <div><Text tid="Report type" /></div>
              <div><Text tid="Date" /></div>
            </div>
            <div className="row_wrapper">
              {data &&
                data.length > 0 &&
                data.map((item) => <ReportRow key={item.id} report={item} />)}
            </div>
          </div>
          <div className="center">
            <div className="res-mr-20">
              {page.per_page ? (
                <div className="Pagination my-3">
                  <Pagination
                    current={current}
                    pageSize={page.per_page}
                    total={page.total}
                    onChange={onChangePage}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div className="my-5">
          <Card>
            <h4> {message} </h4>
          </Card>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default OrphanReports;
