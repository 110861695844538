import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://aytameamiralmomenin.com/api/";
class IdentifierService {
    
    getOverseer = () => {
      
        return axios.get(API_URL + `identifier/information` , { headers: authHeader() } ).catch((error) => {
            if (error) {
              return error;
            }
          });
    }
   
}

export default new IdentifierService();