
import axios from "axios";

const API_URL = "https://aytameamiralmomenin.com/api/";

class NewsService {
    getNews = (userLanguage , pageNumber = 1) => {
        return  axios.get(API_URL + `${userLanguage}/blog?page=${pageNumber}`).catch((error) => {
            if (error) {
              return error    
            }});
      } 

      getCategoriesNews = (userLanguage) => {
        return  axios.get(API_URL  + `${userLanguage}/blog/categories`).catch((error) => {
            if (error) {
              return error
              
            }});
      } 
      getTagsOfNews = (userLanguage) => {
        return  axios.get(API_URL  + `${userLanguage}/blog/tags`).catch((error) => {
            if (error) {
              return error
              
            }});
      } 

      getOneNews = (id) => {
        return  axios.get(API_URL + `blog/${id}`).catch((error) => {
            if (error.response) {
              return error 
            }});

      }

      getNewsByCategory = (userLanguage , id , pageNumber = 1 ) => {
        return  axios.get(API_URL  + `${userLanguage}/blog/categories/${id}?page=${pageNumber}`).catch((error) => {
            if (error.response) {
              return error   
            }});
      } 

      searchNewsByKeyword = (userLanguage , keyword , pageNumber = 1 ) => {
        return  axios.get(API_URL  + `${userLanguage}/blog/${keyword}?page=${pageNumber}`).catch((error) => {
            if (error.response) {
              return error
              
            }});
      }

      searchNewsByTag = (userLanguage , tag , pageNumber = 1 ) => {
        return  axios.get(API_URL  + `${userLanguage}/blog/tags/${tag}?page=${pageNumber}`).catch((error) => {
            if (error.response) {
              return error 
            }});
      }

      sortNewsByViewCount = (userLanguage , pageNumber = 1) => {
        return axios.get(API_URL + `${userLanguage}/blog/sort/view?page=${pageNumber}`).catch(error => {
          if (error.message) {
            return error.message;
          }
        })
      }
}

export default new NewsService();
