import React, { useContext, useState } from "react";
import check from "../../../assets/img/icons/Check-green.svg";
import calender from "../../../assets/img/icons/calender.svg";
import eye from "../../../assets/img/icons/eye.svg";
import naghleghol from "../../../assets/img/icons/Naghleghol.svg";
import Arrow from "../../../assets/img/icons/footer-arrow.svg";
import HomeNewsCard from "../Home/HomeNewsCard";
import HomeNewsSlider from "./HomeNewsSlider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import NewsService from "../../../Services/NewsService";
import { LanguageContext, Text } from "../../../context/LanguageContext";
import { useRef } from "react";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { toast } from "react-toastify";

const NewsDetailinfo = () => {
  const { userLanguage } = useContext(LanguageContext);
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const parser = new DOMParser();

  useEffect(() => {
    window.scrollTo(0, 0);
    let locale;
    switch (userLanguage) {
      case "fa":
        locale = "fa-IR";
        break;
      case "en":
        locale = "en-US";
        break;
      case "ar":
        locale = "ar-SA";
        break;

      default:
        locale = "fa-IR";
        break;
    }

    const getOneNews = async () => {
      setLoading(true);
      const res = await NewsService.getOneNews(searchParams.get("id"));
      if (res.status == 200) {
        const date = new Date(res.data.data.publish_date);
        setYear(date.toLocaleDateString(locale, { year: "numeric" }));
        setMonth(date.toLocaleDateString(locale, { month: "long" }));
        setDay(date.toLocaleDateString(locale, { day: "numeric" }));
        setDescription(
          parser.parseFromString(res.data.data.description, "text/html").body
            .innerText
        );
        setData(res.data.data);
        setLoading(false);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    getOneNews();
  }, [userLanguage , searchParams.get("id")]);


  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="NewsDetailinfo">
      <div className="HomeKefalat ">
        {data.slides && data.slides.length > 0 ? (
          <div>
            <HomeNewsSlider slides={data.slides} />
          </div>
        ) : null}

        <div className="NewsDetailinfo__infobox d-flex">
          <div className="d-flex-align ml-8">
            <img className="ml-4" src={calender} />

            <div className="d-flex-align">
              {" "}
              {day} <div className="px-2">{month}</div> {year} 
            </div>
          </div>
          <div className="d-flex-align ">
            <img className="ml-4" src={eye} />

            <div>{data.view_count} </div>
          </div>
        </div>

        <div className="HomeKefalat__info">
          <h4>{data.name}</h4>
         
        </div>
        <div className="d-flex-align my-14 aboutus--naghl">
          <img src={naghleghol} className="ml-8" />
          <p>{data.summary}</p>
        </div>
        <div className="center HomeKefalat__img my-20">
            {data.picture ? (
              <img src={data.picture.path} alt={data.picture.alt} />
            ) : null}
          </div>
      </div>

      <div style={{ overflowWrap: "anywhere" }}>{description}</div>

      {data.tags && data.tags.length ? (
        <div className="mt-20">
          <h5 className="News__title">پیوست</h5>
          <div className="d-flex-align my-10">
            {data.tags.map((tag, index) => (
              <div key={index} className="Tag ml-8">
                {tag}
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {data.related_blogs && data.related_blogs.length > 0 ? (
        <div>
          <div className="d-flex-align">
            <img src={Arrow} className="ml-4" />
            <h3 className="News__h3">اخبار مرتبط</h3>
          </div>

          <div className="LastNews__grid" >

            {data.related_blogs.map((related) => <HomeNewsCard key={related.id} news={related} /> ) }

          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NewsDetailinfo;
