import React,{useEffect} from "react";
import arrow from "../assets/img/icons/footer-arrow.svg";
import ContactusContainer from "../Components/layouts/contactus/ContactusContainer";
import { Text } from "../context/LanguageContext";

const Contactus = ({setOpen}) => {
  useEffect(() => {
    setOpen(false)
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="AboutUs">
      <div className="center-column ml-8">
        <h2 className="AboutUs__title"><Text tid="Be in touch with us" /></h2>
        <div className="breadcrumbs">
          <div className="d-flex-align">
            <div> <Text tid="Homepage"  /> </div>
            <img src={arrow} alt='' className="breadcrumbs_arrow" />
            <div> <Text tid="Contact us" /> </div>
          
          </div>
        </div>
      </div>
      <div className="container">
        <ContactusContainer />
      </div>
    </div>
  );
};

export default Contactus;
