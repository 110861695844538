import React, { useContext } from "react";
import arrow from "../assets/img/icons/RIGHT-ARROW.png";
import delet from "../assets/img/icons/DELETE-balck.png";
import Payam from "../assets/img/icons/message.png";
import clock from "../assets/img/icons/clock.png";
import user from "../assets/img/icons/user-black.png";
import cal from "../assets/img/icons/cal.png";
import { LanguageContext, Text } from "../context/LanguageContext";
import down from "../assets/img/icons/download.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReportsService from "../Services/ReportsService";
import { useEffect } from "react";
import { useState } from "react";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import { Card } from "antd";
import { ToastContainer, toast } from "react-toastify";
import useMultiDate from "../CustomHooks/useMultiDate";
import AuthService from "../Services/AuthService ";

const ReportDetail = ({ setremove }) => {
  const [data, setData] = useState(null);
  // const [date , setDate] = useState();
  const [loading, setLoading] = useState([]);
  const [message, setMessage] = useState("");
  const { userLanguage } = useContext(LanguageContext);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getReport = async () => {
      setLoading(true);
      const res = await ReportsService.getReportById(id);
      if (res?.status === 200) {
        setData(res?.data?.data);
        setMessage(res?.data?.message);
        setLoading(false);
      } else {
        toast(res?.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
      getReport();
  }, []);

  const date = useMultiDate(data?.report_date, "numeric", "numeric", "numeric");

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : data ? (
        <div>
          <div className="flex justify-between text-[#313E45]">
            <button
              className="blue-back center w-[50px] h-[50px]"
              onClick={() => navigate(-1)}
            >
              <img src={arrow} />
            </button>
            {/* <button className="blue-back center w-[50px] h-[50px]" onClick={setremove}>
        <img src={delet} />
      </button> */}
          </div>
          <div className="MessageDetailtable report-detail-grid">
            <div className=" text-[#6F838E] MessageDetailtable--header MessageDetailtable--grid">
              <div />
              <div>
                <Text tid="Report type" />
              </div>
              <div>
                <Text tid="Date" />
              </div>
              <div>
                <Text tid="Sender" />
              </div>
            </div>
            <div className=" MessageDetailtable--grid my-8">
              <div className="flex items-center">
                <div className="report-icon-box">
                  <img src={Payam} />
                </div>
                <h4 className="mx-4 font-bold text-[10px] sm:text-[12px] md:text-[14px] mb-0">
                  {data.title}
                </h4>
              </div>
              <div>{data.type?.name}</div>
              <div>{date}</div>
              <div>{`${data.senderData.name} ${data.senderData.surname}`}</div>
            </div>
          </div>
          <p className="text-[#313E45] "> {data?.description} </p>

          {data.attachment ? (
            <Link
              to={data.attachment}
              download="file"
              // target="_blank"
              rel="noreferrer"
            >
              <div className="d-flex-align text-[#313E45] text-[18px]">
                <div className="blue-back center w-[50px] h-[50px]">
                  <img src={down} />
                </div>
                <div className="mr-8 text-[#313E45] cursor-pointer">
                 <Text tid="Download file" />
                </div>
              </div>
            </Link>
          ) : null}
        </div>
      ) : (
        <div className="my-5">
          <Card>
            <h4> {message} </h4>
          </Card>
        </div>
      )}

      <ToastContainer />
    </>
  );
};

export default ReportDetail;
