import { useContext, useRef } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import HomeNewsCard from "../Home/HomeNewsCard";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import NewsService from "../../../Services/NewsService";
import { useState } from "react";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { Pagination } from "antd";
import axios from "axios";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";

const NewsContent = () => {
  const { userLanguage } = useContext(LanguageContext);
  const [newsData, setNewsData] = useState([]);
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getNews = async () => {
      setLoading(true);
      let res;
      if (searchParams.get("sort_by")) {
        res = await NewsService.sortNewsByViewCount(
          userLanguage,
          searchParams.get("page")
        );
      } else {
        res = await NewsService.getNews(userLanguage, searchParams.get("page"));
      }
      if (res && res.status === 200) {
        setNewsData(res.data.data.data);
        setPage(res.data.data);
        setLoading(false);
      } else {
        toast(res, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    setCurrent(
      searchParams.get("page")
        ? Number(Object.fromEntries([...searchParams]).page)
        : 1
    );
    getNews();
  }, [searchParams.get("page"), searchParams.get("sort_by")]);

  // useEffect(() => {
  //   if (filterData.news_filter){
  //     navigate({ pathname: "/news" , search: `?page=${1}` });
  //   } 
  // }, [filterData.news_filter]);



  const onChangePage = async (pageNumber) => {
    setCurrent(pageNumber);
    navigate({ pathname: "/news", search: `?page=${pageNumber}&sort_by=${searchParams.get("sort_by")}`});
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : newsData && newsData.length > 0 ? (
        <div className="row">
          {/* <div className="NewsContainer__grid"> */}
            {newsData.map((news) => (
              <div key={news.id} className="col-md-4 mb-5 NewsContainer__grid p-0">
                <HomeNewsCard  news={news} />
              </div>
            ))}
          {/* </div> */}
        </div>
      ) : null}

      {page.per_page ? (
        <div className="Pagination my-3">
          <Pagination
            current={current}
            pageSize={page.per_page}
            total={page.total}
            onChange={onChangePage}
          />
        </div>
      ) : null}

      <ToastContainer />
    </>
  );
};

export default NewsContent;
