import React from "react";
import { Text } from "../../context/LanguageContext";

const SubFooter = () => {
  return (
    <div className="SubFooter">
      <div className="container">
        <div className="SubFooter__flex d-flex-space copy_right">
          <div> <Text tid="Copyright is reserved for this group" /> </div>
          <div className="d-flex-align conditions">
            <span> <Text tid="Terms and Conditions" /> </span>
            <span className="mx-4">|</span>
            <span> <Text tid="Privacy policy" /> </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
