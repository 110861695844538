import en from './en.json';
import fa from './fa.json';
import ar from './ar.json';

export const dictionaryList = { en, fa ,ar};

export const languageOptions = {
  fa: 'Farsi',
  en: 'English',
  ar:'Arabic'
 
};