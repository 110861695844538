import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";
import { Text } from "../../context/LanguageContext";
const Textarea = ({
  name,
  value,
  message,
  control,
  register,
  label,
  absolute,
  className,
  errors,
  defaultValue,
  LabelIcon,
  inputIcon,
  ...rest
}) => {
  const error = errors[name];
  return (
    <div className={`Input relative ${className}`}>
     
        <label className="d-flex">
        {label && ( <Text tid={label} />   )}
          {
            LabelIcon && <div className={`${label ?"mx-4" :"" }`}>{LabelIcon}</div>
          }
        </label>
   
      <Controller
        name={name}
        control={control}
        rules={register}
        defaultValue={defaultValue}
        value = {value}
        render={({ field }) => <textarea {...rest} {...field} />}
      />
     {inputIcon && 
        <div className="input__icon">
          {inputIcon}
        </div>
      }
      {error && <span className="input__message danger">{error.message}</span>}
    </div>
  );
};

Textarea.propTypes = {
  classes: PropTypes.any,
  control: PropTypes.any,
  errors: PropTypes.any,
  label: PropTypes.string,
  message: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.any,
  value: PropTypes.any,
};
export default Textarea;
