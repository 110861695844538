import React, {useState, createContext, useContext} from 'react';

import {languageOptions, dictionaryList} from '../language';

export const LanguageContext = createContext({
  userLanguage: 'fa',
  dictionary: dictionaryList.fa,
});

export function LanguageProvider({children}) {
  const defaultLanguage = window.localStorage.getItem('rcml-lang');
  const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'fa');
 

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: (selected) => {
      const newLanguage = languageOptions[selected] ? selected : 'fa';
      setUserLanguage(newLanguage);
      window.localStorage.setItem('rcml-lang', newLanguage);
    },
  };

  return (
    <LanguageContext.Provider
      value={(provider)}
    >
      {children}
    </LanguageContext.Provider>
  );
}

// get text according to id & current language
export function Text({tid}) {
  const languageContext = useContext(LanguageContext);
  return languageContext.dictionary[tid] || tid;
}

// for some cases we can't use Text component for example in select boxes
export function TextFunc(tid) {
  const languageContext = useContext(LanguageContext);
  return languageContext.dictionary[tid] || tid;
}