import axios from "axios";

const API_URL = "https://aytameamiralmomenin.com/api/";

class HomeService {
    getHomeData = (userLanguage) => {
       const res = axios.get( API_URL + `${userLanguage}/home`).catch((error) => {
            return error
            });

            return res;
      } 


}

export default new HomeService();