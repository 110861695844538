import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
const SelectSearchable = ({
  name,
  control,
  register,
  className,
  selectClassName,
  errors,
  defaultValue = "",
  options = [],
  isClearable = false,
  handleChange = () => {},
  placeholder = "",
  noOptionsMessage = "",
}) => {
  const error = errors[name];

  return (
    <div className={` relative ${className}`}>
      <Controller
        control={control}
        rules={register}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select
            className={selectClassName}
            onChange={(e) => {
              onChange(e);
              handleChange(e);
            }}
            onBlur={onBlur}
            ref={ref}
            value={value}
            defaultValue={defaultValue}
            options={options}
            isClearable={isClearable}
            placeholder={placeholder}
            noOptionsMessage={noOptionsMessage}
          />
        )}
      />
      {error && <span className="input__message danger">{error.message}</span>}
    </div>
  );
};

SelectSearchable.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.any,
  name: PropTypes.string,
  register: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.array,
};
export default SelectSearchable;
