import React from "react";
import Purple from "../../../assets/img/icons/user-purple.svg";
import blue from "../../../assets/img/icons/users-blue.svg";
import pink from "../../../assets/img/icons/heart-red.svg";
import { Text } from "../../../context/LanguageContext";
const HomeNum = ({counts}) => {
  return (
  <>
       <div className="HomeNum__con">
      <div className="container">
       <div className="HomeNum">
         <div className="HomeNum__box ">
           <div className="HomeNum__circle  HomeNum__circle--1">
             <img src={Purple} alt="" />
           </div>
           <div className="HomeNum__box--num">{counts ? counts.users : 0}</div>
           <div className="HomeNum__box--title"> <Text tid='Active users' /> </div>
         </div>
         <div className="HomeNum__box  ">
           <div className="HomeNum__circle HomeNum__circle--2">
             <img src={pink} alt="" />
           </div>
           <div className="HomeNum__box--num">{counts ? counts.orphans : 0}</div>
           <div className="HomeNum__box--title"><Text tid='number of orphans' /></div>
         </div>
         <div className="HomeNum__box  ">
           <div className="HomeNum__circle HomeNum__circle--3">
             <img src={blue} alt="" />
           </div>
           <div className="HomeNum__box--num">{counts ? counts.sponsors : 0}</div>
           <div className="HomeNum__box--title"><Text tid='number of sponsors' /> </div>
         </div>
       </div>
     </div>
   </div>
    
    </>
    
  );
};

export default HomeNum;
