import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import ReactCodeInput from "react-code-input";
import { Controller, useForm } from "react-hook-form";
import Button from "../../shared/Button";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import { SyncOutlined } from '@ant-design/icons';
import AuthService from "../../../Services/AuthService ";
import "react-toastify/dist/ReactToastify.css";
import { LanguageContext, Text } from "../../../context/LanguageContext";

const CodeRagister = ({ username, setStep , message }) => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [currentTimeIndex, setCurrentTimeIndex] = useState(0);
  const {userLanguage} = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (message) {
      toast(message, {
        position: "top-right",
        type: "success",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, []);

  const Completionist = () => <a onClick={handleResendCode}><Text tid="Get The Code" /></a>;

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state

      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  const CountdownComponent = useMemo(
    () => (
      <Countdown
        date={Date.now() + 120000}
        renderer={renderer}
        key={currentTimeIndex}
        zeroPadTime={2}
      >
        {/* <Completionist /> */}
      </Countdown>
    ),
    [currentTimeIndex]
  );

  // const {
  //   control,
  //   handleSubmit,
  //   formState: { errors, isValid },
  // } = useForm({
  //   mode: "onChange",
  //   reValidateMode: "onChange",
  // });



  const handleChangeValue = (value) => {
    inputRef.current = value;
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const res = await AuthService.login(userLanguage , username, inputRef.current);

    if(res){
      if (res.status == 200) {
          switch (res.data?.data?.role) {
            case "کفیل":
              navigate("/dashboard/kafil");
              break;
              case "یتیم":
              navigate("/dashboard/tarakonesh");
              break;
              case "ناظر":
              navigate("/dashboard");
              break;
            default:
              navigate("/");
              break;
          }
        
      } else {
        toast(res, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    }
   
  };
  const props = {
    className: "reactCodeInput",
    inputStyle: {
      margin: "4px",
      width: "55px",
      borderRadius: "3px",
      fontSize: "20px",
      height: "55px",
      backgroundColor: "#F4F8FA",
      color: "#313E45",
      textAlign: "center",
    },
  };

  const handleResendCode = async (e) => {
    
    setCurrentTimeIndex(currentTimeIndex + 1);
    e.preventDefault();
    const res = await AuthService.register(userLanguage , username);
    if (res && res.status == 200) {
      toast(res, {
        position: "top-right",
        type: "success",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    
  };

  return (
    <div>
      <form>
        <div className="PhoneRegister CodeRagister__back">
          <h4>ورود | ثبت نام</h4>

          <div className="my-8">
            {/* <Controller
              name="code"
              control={control}
              render={({ field }) => (
                <ReactCodeInput {...field} fields={6} value="-" {...props} />
              )}
            /> */}
            <ReactCodeInput
              fields={6}
              value="_"
              onChange={handleChangeValue}
              {...props}
            />
          </div>

          <Button disabled = {loading} onClick={handleSubmit} varient="primary">
          { loading ?  <SyncOutlined style={{fontSize : "22px"}} spin /> :
            <div><Text tid="Send Code" /></div>}
            
          </Button>
          <div className="d-flex-space w-full mt-8 ">
            <div className="d-flex-align">
              <Text tid="Remaining time" />
              <div className="mx-3 font-bold">{CountdownComponent}</div>
            </div>
            <div onClick={() => setStep(1)} className="font-bold">
              <Text tid="Edit Number" />
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default CodeRagister;
