import React,{ useState} from 'react'


const Accordion = ({header,children}) => {
 const [open, setOpen] = useState(false)
  return (
    <div className={`Accordion blue-back ${open ? "Accordion--open":"Accordion--close"}`}>
        <div className='Accordion--header' onClick={()=>setOpen(!open)}>
          {header}
     

        </div>
        <div  className='Accordion--body'>
          {children}
        </div>
    </div>
  )
}

export default Accordion