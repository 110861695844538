import React from 'react'
import { Text } from '../../../context/LanguageContext'
import Button from "../../shared/Button"
const HomeGiveHappyBox = () => {
  return (
    <div className='HomeGiveHappyBox'>

      <div className='container'>
        <div className='HomeGiveHappyBox__con'>
          <div className='HomeGiveHappyBox__info'>
          <h2><Text tid='Be happy Be happy' />  </h2>
          <p> <Text tid='lorem' />    </p>
          </div>
          <Button className="mx-5" varient="secondary" >  <Text tid='Donate food package' /> </Button>
        </div>

      </div>
      
    </div>
  )
}

export default HomeGiveHappyBox