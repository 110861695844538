import React, { useContext, useEffect, useState } from "react";
import warnin from "../../../../assets/img/icons/redwarning.svg";
import Button from "../../../shared/Button";
import user from "../../../../assets/img/dashboard/blank.png";
import { NumericFormat } from "react-number-format";
import PaymentService from "../../../../Services/PaymentService";
import { ToastContainer, toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import LoadingSpinner from "../../../shared/LoadingSpinner";
import { SyncOutlined } from "@ant-design/icons";
import { LanguageContext, Text } from "../../../../context/LanguageContext";

const KafilPayPopoup = ({ toggle, kafilPayInfo }) => {
  const [count, setcount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [currency, setCurrency] = useState(null);

  const { userLanguage } = useContext(LanguageContext);
  useEffect(() => {
    const getCurrencies = async () => {
      setLoading(true);
      const res = await PaymentService.getCurrencies();
      if (res?.status === 200) {
        setCurrency(res.data?.data);
        setLoading(false);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    getCurrencies();
  }, []);

  const increase = () => {
    if (count < 12 - kafilPayInfo.paid_months) {
      setcount((prev) => prev + 1);
    }
  };
  const decrease = () => {
    if (count !== 1) {
      setcount((prev) => prev - 1);
    }
  };

  const handlePayment = async () => {
    let data;
    const totalAmount = parseFloat(
      (kafilPayInfo?.yearly_salary / 12) * count
    ).toFixed(2);
    data = {
      amount: totalAmount,
      description: "description",
      payment_type: "periodic",
      orphans: [
        {
          id: kafilPayInfo.id,
          net_amount: totalAmount * currency?.currencies[0]?.amount,
          period: count,
        },
      ],
    };
    setBtnLoading(true);
    const res = await PaymentService.Payment(data, userLanguage);
    if (res?.status == 200) {
      try {
        const url = new URL(res.data);
        if (url?.protocol == "https:") {
          window.location.replace(res.data);
          setBtnLoading(false);
        }
      } catch (err) {
        toast(res?.data, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setBtnLoading(false);
      }
    } else {
      toast(res, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setBtnLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="center-column px-5">
          <div className="w-[80px] h-[80px]">
            <img
              src={kafilPayInfo.avatar || user}
              alt={kafilPayInfo.name}
              style={{ borderRadius: "50%" }}
            />
          </div>
          <div className="text-[16px] text-[#313E45]">{`${kafilPayInfo.name}`}</div>
          <div className="d-flex-align mt-2">
            <img src={warnin} className="mx-2 w-[15px] h-[15px] " />
            <div className="text-[red] text-[10px] sm:text-[12px]">
              {userLanguage == "fa"
                ? ` هزینه ${12 - kafilPayInfo.paid_months} ماه پرداخت نشده است`
                : userLanguage == "ar"
                ? `لم يتم دفع رسوم ${12 - kafilPayInfo.paid_months} شهرًا`
                : `${
                    12 - kafilPayInfo.paid_months
                  } months fee has not been paid`}
            </div>
          </div>
          <div className="py-10 w-[100%] border-b-solid border-b-[1px]  border-b-[#D8E7EF]  d-flex-space payment_cost ">
            <div className="d-flex">
              <h3 className="text-[16px] text-[#313E45] mb-0">
                <Text tid="Number of months paid" />
              </h3>
              <div className="bg-[#1375A3] w-[30px] h-[30px] center text-[#fff] blue-back-2">
                {kafilPayInfo.paid_months}
              </div>
            </div>
            <div className="center-column">
              <div className="text-[#6F838E] text-[12px]">
                {" "}
                <Text tid="The cost of one month" />{" "}
              </div>
              <div className="font-bold text-[26px]">
                <NumericFormat
                  displayType="text"
                  value={Number(
                    parseFloat(
                      Number(kafilPayInfo?.yearly_salary) / 12
                    ).toFixed(2)
                  )}
                  thousandSeparator=","
                  prefix={"$"}
                />
              </div>
            </div>
          </div>
          <div className="d-flex-space w-full my-[10px] payment_cost">
            <div className="text-[#6F838E] text-[12px] sm:text-[14px] ">
              <Text tid="Number of months" />
              <div className="text-[10px] text-[red]">
                <Text tid="Maximum" /> :{12 - kafilPayInfo.paid_months}
              </div>
            </div>
            <div className="d-flex-align mx-8">
              <button
                className="blue-back center text-[30px] w-[40px] h-[40px] "
                onClick={() => increase()}
              >
                <div>+</div>
              </button>
              <div className="font-bold mx-4 text-[20px]">{count}</div>
              <button
                className="blue-back center text-[30px] w-[40px] h-[40px]"
                onClick={() => decrease()}
              >
                <div>-</div>
              </button>
            </div>
            <div className="center-column">
              <div className="text-[#6F838E] text-[12px]">
                {" "}
                <Text tid="total cost" />{" "}
              </div>
              <div className="font-bold text-[26px] text-[#2DCA73] text-center">
                <NumericFormat
                  displayType="text"
                  value={Number(
                    parseFloat(
                      (Number(kafilPayInfo?.yearly_salary) / 12) * count
                    ).toFixed(2)
                  )}
                  thousandSeparator=","
                  prefix={"$"}
                />
                <div className="currency text-center">
                  <NumericFormat
                    displayType="text"
                    value={
                      parseFloat(
                        (kafilPayInfo?.yearly_salary / 12) * count
                      ).toFixed(2) * currency?.currencies[0]?.amount
                    }
                    thousandSeparator=","
                    prefix={"ریال"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex-align my-[20px] w-full justify-content-between">
            <Button
              disabled={btnLoading}
              varient="primary"
              className="button--green w-[60%] payment_green_btn"
              onClick={handlePayment}
            >
              {btnLoading ? (
                <SyncOutlined style={{ fontSize: "22px" }} spin />
              ) : (
                <Text tid="Payment" />
              )}
            </Button>
            <Button
              className="blue-back payment_popup_cancel_btn"
              onClick={toggle}
              style={{ width: "150px" }}
            >
              <Text tid="Cancel" />
            </Button>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default KafilPayPopoup;
