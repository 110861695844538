import React, { useContext, useRef, useState } from "react";
import down from "../../assets/img/icons/download.svg";
import { LanguageContext, Text } from "../../context/LanguageContext";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
const UploadCover = ({
  id,
  text,
  name,
  control,
  register,
  errors,
  defaultValue,
  setFileName = () => {} // set file name
}) => {

  const error = errors?.[name];
  return (
    <div className="UploadFile">
      <Controller
        name={name}
        control={control}
        rules={register}
        render={({ field }) => (
          <input
            id={id}
            type="file"
            className="UploadFile__input"
            onChange={(e) =>{field.onChange(e.target.files[0]); setFileName(e.target.files[0]?.name); }}
          />
        )}
      />

      <label htmlFor={id} style={{width:"100%"}} >
        <div className="d-flex">
          <div className="UploadFile__button center-column">
            { text? text : <Text tid="Upload a file" />}
          </div>
          <div className="UploadIcon center ">
            <img src={down} />
          </div>
        </div>
      </label>
      {error && <span className="input__message danger">{error.message}</span>}
    </div>
  );
};

UploadCover.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  errors: PropTypes.object,
  register: PropTypes.object,
  text: PropTypes.any,
};

export default UploadCover;
