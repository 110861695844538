import React, { useContext, useState } from "react";
import avatar from "../../assets/img/dashboard/User.svg";
import home from "../../assets/img/dashboard/home.svg";
import logout from "../../assets/img/dashboard/logout.svg";
import vasighe from "../../assets/img/dashboard/vasighe.svg";
import help from "../../assets/img/dashboard/help.svg";
import hokm from "../../assets/img/dashboard/hokm.svg";
import tajrobe from "../../assets/img/dashboard/tajrobe.svg";
import info from "../../assets/img/dashboard/userinfo.svg";
import tablighat from "../../assets/img/dashboard/tablighat.svg";
import arrow from "../../assets/img/dashboard/arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { LanguageContext, Text } from "../../context/LanguageContext";
import { Button } from "antd";
import AuthService from "../../Services/AuthService ";
import { toast, ToastContainer } from "react-toastify";
import { useEffect } from "react";
import OrphanService from "../../Services/OrphanService";
import SponsorService from "../../Services/SponsorService";
import IdentifierService from "../../Services/IdentifierService";
import { SyncOutlined } from '@ant-design/icons';

const DashboardSidebar = () => {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user = AuthService.getCurrentUser();
  const list = [
    {
      title: <Text tid="Main Page" />,
      img: home,
      id: 1,
      url:
        user?.role == "کفیل"
          ? "kafil"
          : user?.role == "ناظر"
          ? ""
          : `tarakonesh`,
      auth:
        user?.role == "یتیم" || user?.role == "کفیل" || user?.role == "ناظر"
          ? true
          : false,
    },
    {
      title: <Text tid="Donations" />,

      img: vasighe,
      id: 2,
      url: "payment2",
      auth: user?.role == "کفیل" ? true : false,
    },
    {
      title: <Text tid="Submit artwork" />,
      img: help,
      id: 3,
      url: "art",
      auth: user?.role == "یتیم" ? true : false,
    },
    {
      title: <Text tid="Transactions" />,
      img: tajrobe,
      id: 5,
      url: "tarakonesh",
      auth: user?.role == "کفیل" ? true : false,
    },
    {
      title: <Text tid="Account" />,
      img: info,
      id: 6,
      url: user?.role == "کفیل" ? "info" : "orphanInfo",
      auth: user?.role == "یتیم" || user?.role == "کفیل" ? true : false,
    },

    {
      title: <Text tid="Messages" />,
      img: tablighat,
      id: 7,
      url: "message",
      auth:
        user?.role == "یتیم" || user?.role == "کفیل" || user?.role == "ناظر"
          ? true
          : false,
    },
    {
      title:  <Text tid="Reports" />,
      img: hokm,
      id: 4,
      url:
        user?.role == "یتیم"
          ? `orphanReports/${user?.user_id}`
          : user?.role == "ناظر"
          ? "reportlist"
          : "/register",
      auth: user?.role == "ناظر" || user?.role == "یتیم" ? true : false,
    },
  ];

  useEffect(() => {
    let res;
    const getUsersInfo = async () => {
      switch (user?.role) {
        case "یتیم":
          res = await OrphanService.getOrphanInfo();
          break;
        case "کفیل":
          res = await SponsorService.getSponsor();
          break;
        case "ناظر":
          res = await IdentifierService.getOverseer();
          break;

        default:
          res = null;
          break;
      }

      if (res?.status == 200) {
        setData(res?.data?.data);
      } else {
        toast(res?.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    getUsersInfo();
  }, []);

  const handleLogOut = async () => {
    setLoading(true);
    const res = await AuthService.logout(userLanguage);
    if (res) {
      if (res.status == 200) {
        setLoading(false);
        navigate("/");
      } else {
        toast(res || "خطایی رخ داده است", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    }
  };

  return (
    <div className="DashboardSidebar">
      <div className="DashboardSidebar__userbox">
        <img src={data?.avatar ? data?.avatar : avatar} style={{borderRadius : "50%" }} />
        {data?.name && data?.surname ? (
          <p className="mt-[10px] mb-[5px]">{`${data?.name} ${data?.surname}`}</p>
        ) : null}
        <div className="flex">
          <div className="ml-[20px]">
            {" "}
            <Text tid="ID Code" />
          </div>
          <div>{data?.code || data?.identification_code}</div>
        </div>
      </div>
      <ul className="list DashboardSidebar__ul">
        {list.map((i) =>
          i.auth ? (
            <Link key={i.id} to={`/dashboard/${i.url}`}>
              {" "}
              <li className=" d-flex-space DashboardSidebar__li">
                <div className="d-flex-align ">
                  <img
                    src={i.img}
                    className={`${userLanguage === "en" ? "mr-4" : "ml-4"}`}
                  />
                  <div>{i.title}</div>
                </div>
                {userLanguage === "en" ? (
                  <img src={arrow} style={{ transform: "rotate(180deg)" }} />
                ) : (
                  <img src={arrow} />
                )}
              </li>
            </Link>
          ) : null
        )}
        <li>
          <Button
            type="link"
            className="DashboardSidebar__logoutBtn"
            onClick={handleLogOut}
          >
            <div className="d-flex-align ">
              {loading ? (
                <SyncOutlined style={{fontSize : "22px"}} spin />
              ) : (
                <img
                  src={logout}
                />
              )}
              <div className={`${userLanguage === "en" ? "ml-4" : "mr-4"}`}>
                <Text tid="Logout" />
              </div>
            </div>
          </Button>
        </li>
      </ul>
      <ToastContainer />
    </div>
  );
};

export default DashboardSidebar;
