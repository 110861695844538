import React, { useContext, useEffect } from "react";
import { Controller, set, useForm } from "react-hook-form";
import Input from "../Components/shared/Input";
import Textarea from "../Components/shared/Textarea";
import UploadCover from "../Components/shared/UploadCover";
import Button from "../Components/shared/Button";
import { LanguageContext, Text, TextFunc } from "../context/LanguageContext";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import MessagesService from "../Services/MessagesService";
import { SyncOutlined } from "@ant-design/icons";
import DateInput from "../Components/shared/DateInput";
import AuthService from "../Services/AuthService ";
import Select from "../Components/shared/Select";

const AddMessage = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [adminList, setAdminList] = useState([]);

  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    watch,
    register,
    reset,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm();


  useEffect(() => {
    const getAdminList = async () => {
      setLoading(true);
      const res = await AuthService.getAdminList();
      if (res?.status === 200) {
        setAdminList(res?.data?.data);
        setLoading(false);
      } else {
        toast(res?.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
      getAdminList();
  }, []);

  const onSubmit = async (data, e) => {
    setLoadingBtn(true);
    const res = await MessagesService.sendMessage(data);
    if (res?.status == 200) {
      toast(res?.data?.message, {
        position: "top-right",
        type: "success",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      reset({
        receiver_id: "",
        message: "",
        title: "",
      });
      setLoadingBtn(false);
    } else {
      toast(res?.message, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingBtn(false);
    }
  };

  const resetForm = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <h3 className="text-[#313E45] text-[30px]">پیام</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              name="title"
              control={control}
              errors={errors}
              register={{
                required: "فیلد عنوان الزامی است.",
              }}
              className="Footer__input"
              placeholder="عنوان"
            />

            <Select
              name="receiver_id"
              control={control}
              errors={errors}
              defaultValue=""
              register={{
                required: "فیلد گیرنده پیام الزامی است",
              }}
            >
              <option value="" hidden>
                گیرنده
              </option>
              {adminList?.length > 0 &&
                adminList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.username}
                  </option>
                ))}
            </Select>

            <Textarea
              name="message"
              control={control}
              errors={errors}
              register={{
                required: "فیلد پیام الزامی است.",
              }}
              className="Footer__input"
              placeholder="متن پیام"
            />

            <div className="flex justify-start mt-[50px]">
              <button
                type="reset"
                onClick={resetForm}
                className={`text-[#6F838E] ${
                  userLanguage === "en" ? "mr-[20px]" : "ml-[20px]"
                }`}
              >
                <Text tid="Cancel" />
              </button>
              <Button
                varient="primary"
                className="button--green"
                disabled={loadingBtn}
              >
                {loadingBtn ? (
                  <SyncOutlined style={{ fontSize: "22px" }} spin />
                ) : (
                  <Text tid="Save changes" />
                )}
              </Button>
            </div>
          </form>
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default AddMessage;
