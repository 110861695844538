import { useContext } from "react";
import { Alert, Card, Pagination } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import NewsService from "../Services/NewsService";
import HomeNewsCard from "../Components/layouts/Home/HomeNewsCard";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import axios from "axios";

const NewsSearchResult = () => {
  const { userLanguage } = useContext(LanguageContext);
  const [searchParams] = useSearchParams();
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [page, setPage] = useState({});
  const [current, setCurrent] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getNews = async () => {
      setLoading(true);
      const res =  await NewsService.searchNewsByKeyword(
        userLanguage,
        searchParams.get("keyword"),
        searchParams.get("page")
      );
      if (res && res.status === 200) {
        setNewsData(res.data.data.data);
        setPage(res.data.data);
        if (res.data.data && res.data.data.length == 0) {
          setMessage(res.data.message);
        }
        setLoading(false);
      } else {
        toast(res?.message  , {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    setCurrent(
      searchParams.get("page")
        ? Number(Object.fromEntries([...searchParams]).page)
        : 1
    );
    searchParams.get("keyword") ?  getNews() : navigate("/news") ;
  }, [searchParams.get("page") , searchParams.get("keyword")]);

    const onChangePage = async(pageNumber) => {
      setCurrent(pageNumber);
      navigate({ pathname: "/news/search" , search: `?keyword=${searchParams.get("keyword")}&page=${pageNumber}`})

  };

  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : newsData && newsData.length > 0 ? (
        <div className="row" >
          {/* <div className="NewsContainer__grid"> */}
            {newsData.map((news) => (
              <div key={news.id} className="col-md-4 mb-5 NewsContainer__grid p-0">
              <HomeNewsCard key={news.id} news={news} />
              </div>
            ))}
          {/* </div> */}
        </div>
      ) : (
        <Card>
          <h4> {message} </h4>
        </Card>
      )}

      {page.per_page ? (
        <div className="Pagination my-3">
          <Pagination
            current={current}
            pageSize={page.per_page}
            total={page.total}
            onChange={onChangePage}
          />
        </div>
      ) : null}

      <ToastContainer />
    </>
  );
};

export default NewsSearchResult;
