import React, { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import GalleryCard from "../Components/layouts/Gallery/GalleryCard";
import { Card, Pagination, Select } from "antd";
import arrow from "../assets/img/icons/footer-arrow.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import GalleryService from "../Services/GalleryService";
import { toast, ToastContainer } from "react-toastify";
import { LanguageContext, Text } from "../context/LanguageContext";
import GalleryCat from "../Components/layouts/Gallery/GalleryCat";

const GalleryVideo = ({setOpen}) => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { userLanguage } = useContext(LanguageContext);
  const [message, setMessage] = useState("");
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState({});
  const [current, setCurrent] = useState(1);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const { Option } = Select;

  useEffect(() => {
    setOpen(false);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    const getVideos = async () => {
      setLoading(true);
      const res = searchParams.get("id")
        ? await GalleryService.getVideosByCategory(
            userLanguage,
            searchParams.get("id"),
            searchParams.get("page")
          )
        : await GalleryService.getVideos(
            userLanguage,
            searchParams.get("page")
          );
      if (res && res.status == 200) {
        setLoading(false);
        DateSetting(res.data.data.data);
        if (res.data.data && res.data.data.length == 0) {
          if (searchParams.get("id")) {
            navigate("/gallery-video");
          } else {
            setMessage(res.data.message);
          }
        }
        setPage(res.data.data);
      } else {
        toast(res, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    getVideos();
    setCurrent(
      searchParams.get("page")
        ? Number(Object.fromEntries([...searchParams]).page)
        : 1
    );
  }, [searchParams.get("page"), searchParams.get("id")]);

  const DateSetting = (videos) => {
    let locale;
    switch (userLanguage) {
      case "fa":
        locale = "fa-IR";
        break;
      case "en":
        locale = "en-US";
        break;
      case "ar":
        locale = "ar-SA";
        break;

      default:
        locale = "fa-IR";
        break;
    }
    if (videos) {
      const finalData = videos.map((item) => {
        const date = new Date(item.created_at);
        const year = date.toLocaleDateString(locale, { year: "numeric" });
        const month = date.toLocaleDateString(locale, { month: "long" });
        const day = date.toLocaleDateString(locale, { day: "numeric" });

        return { ...item, created_at: { year, month, day } };
      });
      setData(finalData);
    } else {
      setData([]);
    }
  };

  const onChangePage = async (pageNumber) => {
    setCurrent(pageNumber);
    searchParams.get("id")
      ? navigate({
          pathname: "/gallery-video",
          search: `?id=${searchParams.get("id")}&page=${pageNumber}`,
        })
      : navigate({ pathname: "/gallery-video", search: `?page=${pageNumber}` });
  };

  return (
    <div className="Gallery">
      <div className="center-column ">
        <h2 className="AboutUs__title"><Text tid="Video Gallery" /></h2>
        <div className="breadcrumbs">
          <div className="d-flex-align">
            <div> <Text tid="Home" /> </div>
            <img src={arrow} className="breadcrumbs_arrow" />
            <div> <Text tid="Video Gallery" /></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div>
          <div className=" Gallery__header center my-8">
            <GalleryCat type="video" />
          </div>
          {loading ? (
            <LoadingSpinner />
          ) : data && data.length > 0 ? (
            <div className="Gallery__container Gallery__video">
              {data.map((video, index) => (
                <GalleryCard
                  type="video"
                  item={video}
                  onClick={() =>
                    navigate({
                      pathname: "/gallery-video-detail",
                      search: `?id=${video.id}`,
                    })
                  }
                  key={video.id}
                />
              ))}
            </div>
          ) : (
            <div className="my-5">
              <Card>
                <h4> {message} </h4>
              </Card>
            </div>
          )}
          {page.per_page ? (
            <div className="Pagination my-3">
              <Pagination
                current={current}
                pageSize={page.per_page}
                total={page.total}
                onChange={onChangePage}
              />
            </div>
          ) : null}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default GalleryVideo;
