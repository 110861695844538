import React from "react";
import Slider from "react-slick";
import Button from "../../shared/Button";
import { ReactComponent as ArrowButton } from "../../../assets/img/icons/arrow-left.svg";
import { ReactComponent as ArrowButtonRight } from "../../../assets/img/icons/slider-right.svg";
import { ReactComponent as ArrowButtonLeft } from "../../../assets/img/icons/Slider-left.svg";
import { Text } from "../../../context/LanguageContext";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
const Homeslider = ({slides}) => {

  const navigate = useNavigate()

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowButtonRight />,
    prevArrow: <ArrowButtonLeft />,
    responsive: [
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
    ]
  };
  return (
    <div className="Homeslider">
       {
       slides && slides.length>0 ?

          <Slider {...settings}>
            {slides.map( (slide , index) => 
              <div key={index} >
                  <div
                 
                className="Homeslider__slides Homeslider__slides "
                style={{
                  backgroundImage: `url(${slide.picture.replace(/\ /g, "%20")})`
                }}

              >

                <div className="Homeslider__box ">
                  <h2> {slide.name}</h2>
                  <p className="Homeslider__box--p">
                  {slide.description}
                  </p>
                 {
                  slide && slide.link && slide.link != "" ?  <Button varient="primary" onClick = {() => { window.open( slide.link , '_blank') }} >
                  <div className="d-flex-align">
                    {" "}
                    <Text tid="Read more" />
                    <span className="mx-4 arrow">
                      <ArrowButton />
                    </span>{" "}
                  </div>
                </Button> : null
                 }
                </div>
              </div>
              </div>
                
            )}

          </Slider> : null
      }
    </div>
  );
};

export default Homeslider;
