import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import GalleryCard from "../Components/layouts/Gallery/GalleryCard";
import Selectss from "../Components/shared/Select";
import { Card, Pagination, Select, Space } from "antd";
import arrow from "../assets/img/icons/footer-arrow.svg";
import ImageViewer from "react-simple-image-viewer";
import { useContext } from "react";
import { LanguageContext, Text, TextFunc } from "../context/LanguageContext";
import GalleryService from "../Services/GalleryService";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import { toast, ToastContainer } from "react-toastify";
import GalleryCat from "../Components/layouts/Gallery/GalleryCat";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const Gallery = ({ setOpen }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { userLanguage } = useContext(LanguageContext);
  const [imageArray, setImageArray] = useState([]);
  const [message, setMessage] = useState("");
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [page, setPage] = useState({});
  const [current, setCurrent] = useState(1);
  const [clickedImagesCount, setClickedImagesCount] = useState(0);
  const [filterDefaultValue, setFilterDefaultValue] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  useEffect(() => {
    setOpen(false);
  }, []);

  const { Option } = Select;

  useEffect(() => {
    window.scrollTo(0, 0);
    const getFilterValue = getValues();
    const getPictures = async () => {
      setLoading(true);
      const res = searchParams.get("id")
        ? await GalleryService.getPicturesByCategory(
            userLanguage,
            searchParams.get("id"),
            searchParams.get("page")
          )
        : searchParams.get("sort_by")
        ? await GalleryService.sortPicByViewCount(
            userLanguage,
            searchParams.get("page")
          )
        : await GalleryService.getPictures(
            userLanguage,
            searchParams.get("page")
          );

      if (res && res.status == 200) {
        setLoading(false);
        //  -------Date Settings--------------------------------
        DateSetting(res.data.data.data);

        if (res.data && res.data.data && res.data.data.data) {
          setImageArray(res.data.data.data.map((image) => image.picture));
        }
        if (res.data.data && res.data.data.length == 0) {
          setMessage(res.data.message);
        }
        setPage(res.data.data);
      } else {
        toast(res, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    setCurrent(
      searchParams.get("page")
        ? Number(Object.fromEntries([...searchParams]).page)
        : 1
    );
    setFilterDefaultValue(
      searchParams.get("sort_by") ? searchParams.get("sort_by") : ""
    );
    getPictures();
  }, [
    searchParams.get("page"),
    searchParams.get("id"),
    searchParams.get("sort_by"),
    clickedImagesCount,
  ]);

  const DateSetting = (pics) => {
    let locale;
    switch (userLanguage) {
      case "fa":
        locale = "fa-IR";
        break;
      case "en":
        locale = "en-US";
        break;
      case "ar":
        locale = "ar-SA";
        break;

      default:
        locale = "fa-IR";
        break;
    }
    if (pics) {
      const finalData = pics.map((item) => {
        const date = new Date(item.created_at);
        const year = date.toLocaleDateString(locale, { year: "numeric" });
        const month = date.toLocaleDateString(locale, { month: "long" });
        const day = date.toLocaleDateString(locale, { day: "numeric" });

        return { ...item, created_at: { year, month, day } };
      });
      setData(finalData);
    } else {
      setData([]);
    }
  };

  const onChangePage = async (pageNumber) => {
    setCurrent(pageNumber);
    const filterValue = getValues();
    searchParams.get("id")
      ? navigate({
          pathname: "/gallery",
          search: `?id=${searchParams.get("id")}&page=${pageNumber}`,
        })
      : navigate({
          pathname: "/gallery",
          search: `?page=${pageNumber}&sort_by=${filterValue.filter_gallery}`,
        });
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleClickImage = async (index, id) => {
    setLoading(true);
    const res = await GalleryService.getOnePicture(userLanguage, id);
    if (res && res.status == 200) {
      openImageViewer(index);
      setClickedImagesCount(clickedImagesCount + 1);
      setLoading(false);
    } else {
      toast(res, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    }
  };
  const handleChangeFilter = async () => {
    const filterValue = getValues();
    navigate({
      pathname: "/gallery",
      search: `?page=${
        searchParams.get("page") && searchParams.get("page")
      }&sort_by=${filterValue.filter_gallery} `,
    });
  };

  return (
    <div className="Gallery">
      <div className="center-column ">
        <h2 className="AboutUs__title">
          {" "}
          <Text tid="Photo Gallery" />{" "}
        </h2>
        <div className="breadcrumbs">
          <div className="d-flex-align">
            <div>
              <Text tid="Home" />
            </div>
            <img
              src={arrow}
              className="breadcrumbs_arrow"
            />
            <div>
              <Text tid="Photo Gallery" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className=" Gallery__header d-flex-space my-8">
          <GalleryCat type="picture" />
          {!searchParams.get("id") ? (
            <form onSubmit={handleSubmit()}>
              <Selectss
                name="filter_gallery"
                control={control}
                errors={errors}
                register={{
                  required: true,
                }}
                className="Footer__input"
                placeholder="مرتب کردن بر اساس ..."
                // value={filterDefaultValue}
                handleChange={handleChangeFilter}
              >
                <option value="" disabled>
                  {TextFunc("Sort By")}
                </option>
                <option value="count_view">{TextFunc("View Count")}</option>
              </Selectss>
            </form>
          ) : null}
        </div>
        <div>
          {loading ? (
            <LoadingSpinner />
          ) : data && data.length > 0 ? (
            <div>
              <div className="Gallery__container">
                {data.map((pic, index) => (
                  <GalleryCard
                    item={pic}
                    onClick={() => handleClickImage(index, pic.id)}
                    key={index}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="my-5">
              <Card>
                <h4> {message} </h4>
              </Card>
            </div>
          )}
          {isViewerOpen && (
            <ImageViewer
              src={imageArray}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          )}
          {page.per_page ? (
            <div className="Pagination my-3">
              <Pagination
                current={current}
                pageSize={page.per_page}
                total={page.total}
                onChange={onChangePage}
              />
            </div>
          ) : null}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Gallery;
