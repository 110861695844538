import { Pagination, Select } from "antd";
import React, { useContext, useState } from "react";
import user from "../assets/img/dashboard/blank.png";
import heart from "../assets/img/icons/red-heart.png";
import Button from "../Components/shared/Button";
import { LanguageContext, Text } from "../context/LanguageContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SponsorService from "../Services/SponsorService";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import { NumericFormat } from "react-number-format";
import PaymentService from "../Services/PaymentService";
import { Card, CardBody, CardSubtitle, CardText, CardTitle } from "reactstrap";
import { SyncOutlined } from "@ant-design/icons";

const OrphanCard = ({ data, setData }) => {
  const [price, setPrice] = useState(0);
  const [currency, setCurrency] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const { userLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();

  useEffect(() => {
    handlePrice();
  }, [data.length]);

  useEffect(() => {
    const getCurrencies = async () => {
      const res = await PaymentService.getCurrencies();
      if (res?.status === 200) {
        setCurrency(res.data?.data);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setErrorMessage(res.message);
      }
    };
    getCurrencies();
  }, []);

  const handlePrice = () => {
    // if(data.length > 0) {
    const totalPrice = data.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue.price * currentValue.count),
      0
    );
    setPrice(parseFloat(totalPrice).toFixed(2));
    // }
  };

  const increase = (id, count, paid_months) => {
    if (count < 12 - paid_months) {
      const index = data.findIndex((item) => item.id == id);
      const tempArray = data;
      tempArray[index].count++;
      setData([...tempArray]);
      handlePrice();
    }
  };
  const decrease = (id, count) => {
    if (count !== 1) {
      const index = data.findIndex((item) => item.id == id);
      const tempArray = data;
      tempArray[index].count--;
      setData([...tempArray]);
      handlePrice();
    }
  };

  const handleDeleteItem = (id) => {
    const orphanArray = data.filter((item) => item.id != id);
    setData([...orphanArray]);
    handlePrice();
  };

  const handlePayment = async () => {
    let paymentData;
    const orphanInfo = data.map((item) => ({
      id: item.id,
      net_amount: parseFloat(
        item.price * item.count * currency?.currencies[0]?.amount
      ).toFixed(2),
      period: item.count,
    }));
    paymentData = {
      amount: parseFloat(price).toFixed(2),
      description: "description",
      payment_type: "periodic",
      orphans: orphanInfo,
    };
    setBtnLoading(true);
    const res = await PaymentService.Payment(paymentData, userLanguage);
    if (res?.status == 200) {
      try {
        const url = new URL(res.data);
        if (url?.protocol == "https:") {
          window.location.replace(res.data);
          setBtnLoading(false);
        }
      } catch (err) {
        toast(res?.data, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setBtnLoading(false);
      }
    } else {
      toast(res, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setBtnLoading(false);
    }
  };

  return (
    <>
      {currency?.currencies[0]?.amount ? (
        <div>
          {data &&
            data.map((orphan) => (
              <div key={orphan.id} className="col-12 my-2">
                <Card
                  style={{
                    width: "100%",
                  }}
                >
                  <CardBody className="pb-0">
                    <div className="row border-bottom pb-3">
                      <div className="col-auto">
                        <div className=" relative ">
                          <img
                            className="rounded-[50%] w-[50px] h-[50px] border-[2px] border-[#fff] overflow-hidden res-profile"
                            src={orphan?.avatar || user}
                            alt={orphan?.name}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="d-flex justify-content-between ">
                          <div className="d-flex">
                            <div
                              className="text-[#313E45]
                 text-[14px] fw-bold"
                            >
                              {`${orphan?.name}`}
                            </div>
                            <div className="flex items-center mx-4">
                              <img src={heart} className="mx-1" />
                              {orphan?.priority}{" "}
                            </div>
                          </div>
                          <span
                            onClick={() => handleDeleteItem(orphan.id)}
                            className="fw-bold text-[18px]"
                          >
                            &times;
                          </span>
                        </div>

                        <div className="d-flex text-[12px] sm:text-[14px]">
                          <div>
                            {" "}
                            <Text tid="Annual fee" /> :{" "}
                          </div>
                          <span className="text-success px-1">
                            <NumericFormat
                              displayType="text"
                              value={orphan.yearly_salary}
                              thousandSeparator=","
                              prefix={" $"}
                            />
                          </span>
                        </div>
                        <div className="d-flex text-[12px] sm:text-[14px]">
                          <div>
                            {" "}
                            <Text tid="Monthly fee" /> :{" "}
                          </div>
                          <span className="text-success px-1">
                            <NumericFormat
                              displayType="text"
                              value={parseFloat(
                                Number(orphan?.yearly_salary) / 12
                              ).toFixed(2)}
                              thousandSeparator=","
                              prefix={"$"}
                            />
                          </span>
                        </div>
                        <div className="d-flex text-danger text-[12px] sm:text-[14px]">
                          <div>
                            {" "}
                            <Text tid="Number of months allowed" /> :{" "}
                          </div>
                          <span className="fw-bold px-1">
                            {12 - orphan?.paid_months}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex justify-content-between align-items-center py-2 ">
                        <div className=" d-flex mx-2">
                          <div className="d-flex-align">
                            <button
                              className="blue-back center text-[20px] w-[30px] h-[30px] "
                              style={{ borderRadius: "5px" }}
                              onClick={() =>
                                increase(
                                  orphan.id,
                                  orphan.count,
                                  orphan?.paid_months
                                )
                              }
                            >
                              <div className="mb-1">+</div>
                            </button>
                            <div className="font-bold mx-3 text-center w-[20px] text-[16px]">
                              {orphan.count}
                            </div>
                            <button
                              className="blue-back center text-[20px] w-[30px] h-[30px]"
                              style={{ borderRadius: "5px" }}
                              onClick={() => decrease(orphan.id, orphan.count)}
                            >
                              <div className="mb-1">-</div>
                            </button>
                          </div>
                        </div>

                        <div className="fw-bold text-secondary text-[12px] sm:text-[14px] text-left ">
                          <NumericFormat
                            displayType="text"
                            value={Number(
                              (orphan?.yearly_salary / 12) * orphan.count
                            )}
                            decimalScale={2}
                            thousandSeparator=","
                            prefix={"$"}
                          />
                          <div
                            className="text-[#a6a6a6] text-[12px]"
                            style={{ fontWeight: "400" }}
                          >
                            <NumericFormat
                              displayType="text"
                              value={
                                parseFloat(
                                  (orphan?.yearly_salary / 12) * orphan.count
                                ).toFixed(2) * currency?.currencies[0]?.amount
                              }
                              thousandSeparator=","
                              decimalScale={2}
                              suffix={" ریال"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))}

          <div className="my-4">
            <Card
              style={{
                width: "100%",
              }}
            >
              <CardBody>
                <div className="d-flex justify-content-between align-items-center text-left">
                  <div className="fw-bold">
                    <Text tid="total cost" />
                  </div>
                  <div>
                    <NumericFormat
                      displayType="text"
                      value={price}
                      thousandSeparator=","
                      decimalScale={2}
                      prefix={"$"}
                    />
                    <div
                      className="text-[#a6a6a6] text-[12px]"
                      style={{ fontWeight: "400" }}
                    >
                      <NumericFormat
                        displayType="text"
                        value={price * currency?.currencies[0]?.amount}
                        thousandSeparator=","
                        decimalScale={2}
                        suffix={"ریال"}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="d-flex-space flex-col-responsive-payment">
            <div className="d-flex-align">
              <Button
                varient="primary"
                className="button--green ml-4"
                onClick={handlePayment}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <SyncOutlined style={{ fontSize: "22px" }} spin />
                ) : (
                  "پرداخت"
                )}
              </Button>
              <button onClick={() => navigate(-1)} className="Tag ">
                <Text tid="Cancel" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          {errorMessage ? (
            <Card>
              <CardBody>{errorMessage}</CardBody>
            </Card>
          ) : (
            <LoadingSpinner />
          )}
        </div>
      )}
      <ToastContainer />
    </>
  );
};

const Payment = ({ setkafilpopup, orphanChosenToPay }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getOrphans = async () => {
      setLoading(true);
      const res = await SponsorService.getSelectedOrphans({
        orphans: orphanChosenToPay,
      });
      if (res?.status === 200) {
        if (res?.data?.data.length == 0) {
          navigate("/dashboard/payment2");
        } else {
          const paymentsArray = res?.data?.data.map((payment) => ({
            ...payment,
            price: Number(payment?.yearly_salary) / 12,
            count: 1,
          }));
          // setPaymentList(paymentsArray);
          setData(paymentsArray);
          setLoading(false);
        }
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    getOrphans();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : data?.length > 0 ? (
        <div>
          <div className="flex items-center justify-between flex-col-responsive ">
            <div className="d-flex-align ">
              <h3 className="text-[#313E45] text-[30px] mb-0 title">
                {" "}
                <Text tid="List of orphans" />{" "}
              </h3>
              <div className="blue-back font-bold text-[#1375A3] mr-[30px] w-[40px] h-[40px] text-center ">
                {data.length}
              </div>
            </div>
          </div>

          <div className="card-payment">
            <div className="row mt-5">
              <OrphanCard data={data} setData={setData} />
            </div>
          </div>
        </div>
      ) : (
        <Card>
          <CardBody>شما یتیمی در لیست پرداخت خود ندارید</CardBody>
        </Card>
      )}
    </>
  );
};

export default Payment;
