import PropTypes from "prop-types";
import React, { useState } from "react";
import check from "../../assets/img/icons/check-green.png"
const Phase = ({ PhaseList, PhaseNum }) => {
  return (
    <div className="d-flex make__phase">
      {PhaseList.map((i, key) => (
        <React.Fragment key={key}>
          <div
            className="make__phase--box"
            data-tut={
              key === 0
                ? "reactour__start"
                : key === 1
                ? "reactour__secondphaze"
                : key === 2
                ? "reactour__Thirdphaze"
                : "reactour__Forthphaze"
            }
          >
            <div
              style={
                PhaseNum === i.PhaseNumber
                  ? { color: "#1375A3" }
                  : {} && PhaseNum > i.PhaseNumber
                  ? { color: "#1375A3" }
                  : null
              }
            >
             <div className="flex relative phase_text">  {
                PhaseNum > i.PhaseNumber && <img src={check} className=' absolute right-[-20px]'/>
              }{i.phaseName}
             </div>
            </div>
            <div
              className="make__phase-circle"
              style={
                PhaseNum === i.PhaseNumber
                  ? {background:"#F7941D" , border: "none",color:"#fff" }
                  : { border: "none" } && PhaseNum >= i.PhaseNumber
                  ? { backgroundColor: "#2DCA73"  ,color:"#fff"}
                  : null
              }
            >{i.PhaseNumber}</div>
          </div>
          <div
            className="make__phase-line "
            style={PhaseNum > i.PhaseNumber ? { borderColor: "#D8E7EF" } : null}
          ></div>
        </React.Fragment>
      ))}
    </div>
  );
};

Phase.propTypes = {
  PhaseList: PropTypes.array,
};

export default Phase;