import React from "react";
import { LanguageContext, Text } from "../../../../context/LanguageContext";
import Button from "../../../shared/Button";
import remove from "../../../../assets/img/icons/white-remove.svg";
import {Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useContext } from "react";


const MessageDeleteModal = ({onOk,toggle,text,confirmButtonText , isOpen, title , ...args}) => {
  const { userLanguage } = useContext(LanguageContext);
  return (
    <Modal
    isOpen={isOpen}
    toggle={toggle}
    style={{ direction: userLanguage == "en" ? "ltr" : "rtl" }}
    {...args}
  >
    <ModalHeader className="modal_strap_header" toggle={toggle}>
      {title}
    </ModalHeader>
    <ModalBody className="modal_strap_body" >
    <div className="center-column">
      <h3 className="text-[23px] text-[#313E45]">
        <Text tid="warning" />
      </h3>
     {text  ? <p>{text}</p> : <p>آیا می خواهید این یتیم را از لیست حذف کنید؟</p>}
      <div className="flex justify-end mt-[20px]">
        <button className="text-[#6F838E] mx-[30px] cursor-pointer" onClick={toggle}><Text tid="Cancel" /></button>
        <Button varient="primary" className="button--red" onClick={onOk}>
          <div className="flex">
          
          {
            confirmButtonText ? <div>{confirmButtonText}</div> :<div>حذف یتیم</div> 
          }  
           <img src={remove} className="mx-[10px]" /> 
          </div>
        </Button>
      </div>
    </div>
    </ModalBody>
    {/* <ModalFooter>
  <Button color="primary" onClick={toggle}>
    Do Something
  </Button>{' '}
  <Button color="secondary" onClick={toggle}>
    Cancel
  </Button>
</ModalFooter> */}
  </Modal>
   
  );
};

export default MessageDeleteModal;
