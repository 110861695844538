import React, { useContext, useEffect, useState } from "react";
import calender from "../../../assets/img/icons/calender.svg";
import eye from "../../../assets/img/icons/eye.svg";
import { ImageViewer } from "react-image-viewer-dv";
import { LanguageContext } from "../../../context/LanguageContext";
import { useSearchParams } from "react-router-dom";

const GalleryCard = ({ item, onClick, type = "picture" }) => {
  const { userLanguage } = useContext(LanguageContext);

  return item ? (
    <div className="GalleryCard" onClick={onClick}>
      <div className="GalleryCard__img">
        {type == "picture" ? (
          <img src={item.picture} alt="" className="w-full" />
        ) : (
          <video width="100%">
            <source src={item.video} type="video/mp4"></source>
            <source src={item.video} type="video/ogg"></source>
          </video>
        )}
      </div>
      <div className="GalleryCard__info">
        <h2 className="GalleryCard__h2 mb-10">{item.name}</h2>
        <div className="HomeNewsCard__infobox ">
          <div className="d-flex-align">
            <img className="mx-2" src={eye} alt="" />
            <div>{item.view_count} </div>
          </div>
          <span className="mx-3">|</span>

          <div className="d-flex-align">
            <img className="mx-2" src={calender} alt="" />

            {item.created_at && item.created_at.day && item.created_at.month && item.created_at.year ? (
              <div className="d-flex-align">
                {item.created_at.day}{" "}
                <div className="px-2">{item.created_at.month}</div>{" "}
                {item.created_at.year}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default GalleryCard;
