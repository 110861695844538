import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://aytameamiralmomenin.com/api/";

class OrphanService {
  getOrphans = (userLanguage, pageNumber) => {
    return axios
      .get(API_URL + `${userLanguage}/orphans?page=${pageNumber || 1}`, {
        headers: authHeader(),
      })
      .catch((error) => {
        return error;
      });
  };

  getOrphanInfo = () => {
    return axios
      .get(API_URL + `orphan/information`, { headers: authHeader() })
      .catch((error) => {
        return error;
      });
  };

  postArtWorks = (data) => {
    return axios
      .post(API_URL + `artworks/store`, data, {
        headers: {
          ...authHeader(),
          "content-type": "multipart/form-data",
        },
      })
      .catch((error) => {
        if (error) {
          return error;
        }
      });
  };

  getOrphanById = (orphan_id) => {
    return axios.get(API_URL + `orphans/${orphan_id}`).catch((error) => {
      return error;
    });
  };

  searchOrphan = (data, pageNumber) => {
    return axios
      .post(API_URL + `orphans/search?page=${pageNumber || 1}`, data, {
        headers: authHeader(),
      })
      .catch((error) => {
        return error;
      });
  };

  orphanIncoming = (pageNumber) => {
    return axios
      .get(API_URL + `transactions/orphan/incoming?page=${pageNumber || 1}`, {
        headers: authHeader(),
      })
      .catch((error) => {
        return error;
      });
  };

  filterTransActions = (pageNumber, data) => {
    return axios
      .post(API_URL + `search-orphan-payments?page=${pageNumber || 1}`, data, {
        headers: authHeader(),
      })
      .catch((error) => {
        return error;
      });
  };
}

export default new OrphanService();
