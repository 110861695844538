import React,{useContext, useEffect} from 'react'
import { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Footer from '../Components/Common/Footer'
import HomeGiveHappyBox from '../Components/layouts/Home/HomeGiveHappyBox'
import HomeHelp from '../Components/layouts/Home/HomeHelp'
import HomeHelpResponsive from '../Components/layouts/Home/HomeHelpResponsive'
import HomeKefalat from '../Components/layouts/Home/HomeKefalat'
import HomeNews from '../Components/layouts/Home/HomeNews'
import HomeNum from '../Components/layouts/Home/HomeNum'
import HomeNumResponsive from '../Components/layouts/Home/HomeNumResponsive'
import Homeslider from '../Components/layouts/Home/Homeslider'
import LoadingSpinner from '../Components/shared/LoadingSpinner'
import { LanguageContext } from '../context/LanguageContext'
import HomeService from '../Services/HomeService';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

const Home = ({setOpen}) => {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const [data , setData] = useState(null);
  const [loading , setLoading] = useState(false);
  const [error , setError] = useState(false);
  useEffect(() => {
    setOpen(false)
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(()=>{
    setLoading(true)
    const getHomeData = async() => {
      const res = await HomeService.getHomeData(userLanguage);
      if (res && res.status === 200) {
        setData(res.data.data)
        setLoading(false)
      } else { 
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setError(true)
        setLoading(false)
      }
    }

    getHomeData()
     
  } , [userLanguage])
  return (
 <>
   { loading ? <LoadingSpinner></LoadingSpinner> : error ? null : <div>
    <Homeslider slides = {data && data.slides ? data.slides : []} />
    <HomeNum counts = {data && data.counts ? data.counts : {orphans: 0 , sponsors: 0 , users: 0}}  />
    <HomeNumResponsive counts = {data && data.counts ? data.counts : {orphans: 0 , sponsors: 0 , users: 0}} />
    <HomeHelpResponsive />

    <div className='Home__shareBack'>  
    <HomeHelp />
    <HomeKefalat />
    <HomeNews news = {data && data.articles && data.articles.data ? data.articles.data : []} />
    <HomeGiveHappyBox />
    </div>
</div>}
<ToastContainer />
</>
  )
}

export default Home