import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../Components/shared/Input";
import Textarea from "../Components/shared/Textarea";
import UploadCover from "../Components/shared/UploadCover";
import Button from "../Components/shared/Button";
import { LanguageContext, Text, TextFunc } from "../context/LanguageContext";
import OrphanService from "../Services/OrphanService";
import { ToastContainer, toast } from "react-toastify";
import { SyncOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import AuthService from "../Services/AuthService ";
const Art = () => {
  const {
    control,
    handleSubmit,
    watch,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm();
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const [loading, setLoading ] = useState(false)
  const [fileName,setFileName] = useState("")
  const navigate = useNavigate();

  const onSubmit = async (data , e) => {
    setLoading(true)
    const res =  await OrphanService.postArtWorks( data  , data.attachment);
    if(res.status == 200){
      toast(res?.data?.message , {
        position: "top-right",
        type: "success",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        reset({title : "" , description:"" , attachment:"" });
        setFileName("");
        setLoading(false);
    } else{
      toast(res?.message , {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        setLoading(false)
    }
  };

  const resetForm = () => {
    navigate("/dashboard/tarakonesh")
  };

  return (
    <div>
      <h3 className="text-[#313E45] text-[30px] mb-[50px]"><Text tid="Registration of works of art"  /></h3>
      <form onSubmit={handleSubmit(onSubmit)}>

          <Input
            name="title"
            control={control}
            errors={errors}
            register={{
              required: TextFunc("The title field is required"),
            }}
            className="Footer__input"
            placeholder= { TextFunc("title") }
          />
       
        <Textarea
          name="description"
          control={control}
          errors={errors}
          register={{
            required: TextFunc("The description field is required") ,
          }}
          className="Footer__input"
          placeholder= { TextFunc("Description") }
        />
        <div className="grid sm:grid-cols-2  gap-[20px] my-[20px]">
          <UploadCover
            id="uploadArtWork"
            text= {fileName ? fileName : <Text tid="Upload a file" />  }
            name="attachment"
            control={control}
            errors={errors}
            setFileName = {setFileName}
            register={{
              required: TextFunc("File selection is required") ,
            }}
          />
        </div>
        <div className="flex justify-start mt-[50px]">
          <button
          type="reset"
          onClick={resetForm}
            className={`text-[#6F838E] ${
              userLanguage === "en" ? " mr-[20px]" : " ml-[20px]"
            }`}
          >
            <Text tid="Cancel"  />
          </button>
          <Button varient="primary" className="button--green" disabled = {loading}>
          { loading ? <SyncOutlined style={{fontSize : "22px"}} spin /> : <Text tid="Save changes"  /> }
          </Button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Art;
