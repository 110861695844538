import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { LanguageContext, Text } from "../../../context/LanguageContext";
import GalleryService from "../../../Services/GalleryService";
import { useNavigate } from "react-router-dom";


const GalleryCat = ({ type = "picture" }) => {
  const { userLanguage } = useContext(LanguageContext);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getCategories = async () => {
      const res =
        type == "picture"
          ? await GalleryService.getCategoriesPicture(userLanguage)
          : await GalleryService.getCategoriesVideo(userLanguage);
      if (res.status == 200) {
        const categories = res.data.data;
        setData(categories);
      } else {
        toast(res, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    getCategories();
  }, [userLanguage]);

  const navigateToGalleryCat = (id) => {
   navigate({
    pathname : type=="picture" ?  "/gallery" : "/gallery-video",
    search : `?id=${id}`
   })
  }

  const navigateToGallery = () => {
    navigate({
      pathname : type=="picture" ?  "/gallery" : "/gallery-video",
     })
  }


  return (
    <>
      {data && data.length > 0 ? (
        <div className="d-flex scroll-bar">
          <div className="Tag ml-4" onClick={navigateToGallery}><Text tid="all" /></div>
          {data.map((category) => (
            <div  key={category.id} className="Tag ml-4" onClick={() => navigateToGalleryCat(category.id)}  >
              {category.name}
            </div>
          ))}
        </div>
      ) : null}

      <ToastContainer />
    </>
  );
};

export default GalleryCat;
