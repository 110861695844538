import React, { useEffect, useState } from "react";
import user from "../../../../assets/img/icons/blue-user.svg";
import heart from "../../../../assets/img/icons/red heart.svg";
import check from "../../../../assets/img/icons/green check.svg";
import OrphanService from "../../../../Services/OrphanService";
import LoadingSpinner from "../../../shared/LoadingSpinner";
import { ToastContainer, toast } from "react-toastify";
import { Card } from "antd";
import { Text } from "../../../../context/LanguageContext";

const KafilPopup = ({ orphanId }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [message, setMessage] = useState("");

  useEffect(() => {
    const getOrphan = async () => {
      setLoading(true);
      const res = await OrphanService.getOrphanById(orphanId);
      if (res?.status == 200) {
        if (res?.data?.data?.length == 0) {
          setMessage(res?.data?.message);
        }
        setData(res?.data?.data);
        setLoading(false);
      } else {
        toast(res?.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    getOrphan();
  }, []);
  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : data && Object.keys(data).length > 0 ? (
        <div className="grid grid-cols-2 gap-[10px] m-[20px] grid-cols-1-responsive">
         <img src={data?.avatar || user} className="KafilPopup--img" />
          <div className="d-flex-space">
            <div className="d-flex-align">
              <img src={user} />
              <div className="mr-4 font-bold">{data?.gender == "male" ? <Text tid="boy" /> : <Text tid="girl" />}</div>
            </div>
            {data.identity_document ? (
              <div className="d-flex-align">
                <img src={check} />
                <div className="mr-4 font-bold"> <Text tid="There are documents" /> </div>
              </div>
            ) : (
              <div className="d-flex-align">
                <div className="mr-4 font-bold"><Text tid="There are no documents" /> </div>
              </div>
            )}
          </div>
          <div className="d-flex-align flex-wrap text-[#313E45] text-[14px]">
            <div> <Text tid="Birth Date" /> :</div>
            <div className="mr-4 font-bold">{data?.birth_date}</div>
          </div>
          <div className="d-flex-align flex-wrap text-[#313E45] text-[14px]">
            <div> <Text tid="Educational status" /> :</div>
            <div className="mr-4 font-bold">{data?.educational_status}</div>
          </div>
          <div className="d-flex-align flex-wrap text-[#313E45] text-[14px]">
            <div> <Text tid="health status" /> :</div>
            <div className="mr-4 font-bold">{data?.health_status}</div>
          </div>
          <div className="d-flex-align flex-wrap text-[#313E45] text-[14px]">
            <div> <Text tid="orphan" /> :</div>
            <div className="mr-4 font-bold">{data?.name}</div>
          </div>
          <div className="d-flex-align flex-wrap text-[#313E45] text-[14px]">
            <div> <Text tid="Cause of father's death" /> :</div>
            <div className="mr-4 font-bold">{data?.dad_death_cause}</div>
          </div>
          <div className="d-flex-align flex-wrap text-[#313E45] text-[14px]">
            <div> <Text tid="mother's name" /> :</div>
            <div className="mr-4 font-bold">{data?.mother_name}</div>
          </div>
          <div className="d-flex-align text-[#313E45] text-[14px]">
            <div className="d-flex-align">
              <img src={heart} className="ml-4" />
              <div> <Text tid="Priority" /> :</div>
            </div>
            <div className="mr-4 font-bold">{data?.priority}</div>
          </div>
          <div className="d-flex-align flex-wrap text-[#313E45] text-[14px]">
            <div> <Text tid="Identifier" /> :</div>
            <div className="mr-4 font-bold"> {`${data?.identifier?.name}` }</div>
          </div>
          <div className="d-flex-align text-[#313E45] text-[14px]">
            <div> <Text tid="Monthly fee"  /> :</div>
            <div className="mr-4 font-bold text-[#fff]  blue-back-2">$ {parseFloat(Number(data?.yearly_salary) / 12).toFixed(2)}</div>
          </div>
          <div className="d-flex-align flex-wrap text-[#313E45] text-[14px]">
            <div> <Text tid='Country' /> :</div>
            <div className="mr-4 font-bold">
              {data?.region?.parent?.parent?.name}
            </div>
          </div>
          <div className="d-flex-align text-[#313E45] text-[14px]">
            <div> <Text tid="Annual fee" /> :</div>
            <div className="mr-4 font-bold text-[#fff]  blue-back-2">
             $ {data?.yearly_salary}
            </div>
          </div>
          <div className="d-flex-align flex-wrap text-[#313E45] text-[14px]">
            <div> <Text tid="City" /> :</div>
            <div className="mr-4 font-bold">{data?.region?.name}</div>
          </div>
        </div>
      ) : (
        <div className="my-5">
          {message ? <Card>
            <h4> {message} </h4>
          </Card> : null}
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default KafilPopup;
