import axios from "axios";

const API_URL = "https://aytameamiralmomenin.com/api/";

class GalleryService {
  getPictures = (userLanguage, pageNumber) => {
    return axios
      .get(API_URL + `${userLanguage}/gallery/pictures?page=${pageNumber || 1}`)
      .catch((error) => {
        if (error.message) {
          return error.message;
        }
        
      });
  };

  getCategoriesPicture = (userLanguage) => {
    return axios
      .get(API_URL + `${userLanguage}/gallery/pictures/categories`)
      .catch((error) => {
        if (error.message) {
          return error.message;
        }
      });
  };

  getPicturesByCategory = (userLanguage, category_id, pageNumber=1) => {
    return axios
      .get(
        API_URL +
          `${userLanguage}/gallery/pictures/categories/${category_id}?page=${pageNumber || 1}`
      )
      .catch((error) => {
        if (error.message) {
          return error.message;
        }
      });
  };

  getOnePicture = (userLanguage, pic_id) => {
    return axios
      .get(API_URL + `${userLanguage}/gallery/pictures/${pic_id}`)
      .catch((error) => {
        if (error.message) {
          return error.message;
        }
      });
  };

  sortPicByViewCount = (userLanguage , pageNumber=1) => {
    return axios.get(API_URL + `${userLanguage}/gallery/pictures/sort/view-count?page=${pageNumber || 1}`).catch(error => {
      if (error.message) {
        return error.message;
      }
    })
  }

  getVideos = (userLanguage , pageNumber) => {
    return axios
      .get(API_URL + `${userLanguage}/gallery/videos?page=${pageNumber || 1}`)
      .catch((error) => {
        return error.message;
      });
  };

  getVideosByCategory = (userLanguage, category_id, pageNumber=1) => {
    return axios
      .get(API_URL + `${userLanguage}/gallery/videos/categories/${category_id}?page=${pageNumber || 1}`)
      .catch((error) => {
        if (error.message) {
          return error.message;
        }
      });
  };

  getOneVideo = (userLanguage, id) => {
    return axios
      .get(API_URL + `${userLanguage}/gallery/videos/${id}`)
      .catch((error) => {
        if (error.message) {
          return error.message;
        }
      });
  };

  getCategoriesVideo = (userLanguage) => {
    return axios
      .get(API_URL + `${userLanguage}/gallery/videos/categories`)
      .catch((error) => {
        if (error.response) {
          return error;
        }
      });
  };
}

export default new GalleryService();
