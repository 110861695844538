import axios from "axios";
import authHeader from "./auth-header";
import Cookies from "js-cookie";

const API_URL = "https://aytameamiralmomenin.com/api/";

class AuthService {
  register = (userLanguage , username) => {
    return axios
      .post(API_URL +`${userLanguage}/send-otp`, {
        username,
      })
      .catch((error) => {
          return error?.response?.data?.message;
       
      });
  };

  login = async (userLanguage , username, code) => {
    const res = await axios
      .post(API_URL + `${userLanguage}/verify-otp`, {
        username,
        code,
      })
      .catch((error) => {
          return error?.message;
      });
    if (res && res.status == 200) {
      Cookies.set('user', JSON.stringify(res.data.data));
    }
    
    return res;
  };

  logout = async(userLanguage) => {
    const userinfo = Cookies.get('user');
    const token = JSON.parse(userinfo).token;

    if (userinfo && token) {
      const res = await axios
        .post(
          API_URL + `${userLanguage}/logout`,
          {},
          {
            headers: {
              Authorization: "Bearer " + token,
              // "Content-Type": "application/json",
              // "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .catch((error) => {
          if (error.message) {
            return error.message;
          }
        });

      if (res && res.status == 200) {
        Cookies.remove('user') 
      }
      return res;
    }
  };

  getCurrentUser() {
    if(Cookies.get("user")){
      return JSON.parse(Cookies.get("user")) ;
    }
  }

  getAdminList= () => {
    return axios
      .get(API_URL + `admin-list`, { headers: authHeader() })
      .catch((error) => {
        return error;
      });
  };
}

export default new AuthService();
