import { Card, Pagination, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import profile from "../../../../assets/img/icons/person-people-girl-portrait-model-child-730652-pxhere.com.png";
import heart from "../../../../assets/img/icons/red-heart.png";
import tajob from "../../../../assets/img/icons/tajob-circle.png";
import tajobabi from "../../../../assets/img/icons/tajob-abi.png";
import checks from "../../../../assets/img/icons/check.png";
import { ReactComponent as Arrow } from "../../../../assets/img/icons/arrow-down.svg";
import Button from "../../../shared/Button";
import {
  LanguageContext,
  Text,
  TextFunc,
} from "../../../../context/LanguageContext";
import SponsorService from "../../../../Services/SponsorService";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../../../shared/LoadingSpinner";
import user from "../../../../assets/img/dashboard/blank.png";
import MessageDeleteModal from "../Message/MessageDeleteModal";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const OrphanRow = ({
  setkafilpopup,
  orphan,
  setOrphanId,
  setIsOpen,
  setDeselectOphanId,
}) => {
  const [check, setCheck] = useState(true);

  const handleDetailClick = (e, orphan_id) => {
    e.stopPropagation();
    setkafilpopup(true);
    setOrphanId(orphan_id);
  };

  const handleSelectOrphnas = (orphan_id) => {
    setIsOpen(true);
    setDeselectOphanId(orphan_id);
  };

  return (
    <>
      <div
        className={`orphan-table-grid rounded-[10px] ${
          check ? "bg-[#1375A3] text-[#fff]" : "bg-[#F4F8FA] text-[#313E45]"
        } p-8 my-2 kafilrow-res DashboardMainPage-res`}
        onClick={() => handleSelectOrphnas(orphan.id)}
      >
        <div className="d-flex-align">
          <div className=" relative ">
            {/* <div className="rounded-[50%] w-[20px] h-[20px] bg-[#FF1D25] text-[#fff] absolute right-0 top-0  badge-res">
            1
          </div> */}
            <img
              className="rounded-[50%] w-[50px] h-[50px] border-[2px] border-[#fff] overflow-hidden res-profile"
              src={orphan?.avatar || user}
              alt={orphan?.name}
            />
          </div>
          <div className="mx-3 text-right">
            <div
              style={{ fontWeight: "500" }}
              className={`${
                check ? "text-[#fff] " : "text-[#313E45]"
              }  font-res-header f-w-500`}
            >
              {`${orphan?.name}`}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <img src={heart} className="mx-1" />
          {orphan?.priority}
        </div>
        <div className="identifire_code">{orphan.identification_code}</div>
        <div className="dir-ltr">
          <span className="yearly_salary">$ {orphan.yearly_salary} / </span>
          <span>
            $ {parseFloat(Number(orphan?.yearly_salary) / 12).toFixed(2)}
          </span>
        </div>
        <div className="flex justify-between add_orphan_table_action">
          <div
            className="flex items-center cursor-pointer"
            onClick={(e) => handleDetailClick(e, orphan.id)}
          >
            <img
              src={check ? tajob : tajobabi}
              alt=""
              className="mx-2 w-[15px] h-[15px]"
            />
            <div
              className={`underline ${
                !check ? "text-[#1375A3]" : "text-[#fff]"
              } details`}
            >
              <Text tid="Details" />
            </div>
          </div>
          <div className="d-flex-align select_btn">
            <div className="text-[#2DCA73]"><Text tid="Select" /></div>
            <img className="mx-2" src={checks} />
          </div>
        </div>
      </div>
    </>
  );
};
const KafilStep2 = ({
  nextStep,
  prevStep,
  setkafilpopup,
  selectedOrphansList,
  setOrphanId,
  setFinalSelectedOrphansList,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [deselectOphanId, setDeselectOphanId] = useState(null);
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const [selectedOrphansSecondStep, setSelectedOrphansSecondStep] =
    useState(selectedOrphansList);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const getOrphans = async () => {
      setLoading(true);
      const res = await SponsorService.getSelectedOrphans({
        orphans: selectedOrphansList,
      });
      if (res?.status === 200) {
        if (res?.data?.data.length == 0) {
          prevStep();
        }
        setData(res?.data?.data);
        setLoading(false);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };

    getOrphans();
  }, [selectedOrphansSecondStep.length]);

  const toggle = () => {
    setIsOpen(false);
  };

  const handleConfirmDelete = () => {
    if (selectedOrphansList.indexOf(deselectOphanId) !== -1) {
      selectedOrphansList.splice(
        selectedOrphansList.indexOf(deselectOphanId),
        1
      );
      setSelectedOrphansSecondStep(selectedOrphansList);
    }
    setIsOpen(false);
  };

  const handleNextStep = () => {
    nextStep();
    setFinalSelectedOrphansList(selectedOrphansSecondStep);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : data && data.length > 0 ? (
        <div>
          <div className="flex items-center justify-between flex-col-responsive ">
            <div className="d-flex-align ">
              <h3 className="text-[#313E45] text-[30px] mb-0"> <Text tid="List of orphans" /> </h3>
              <div className="blue-back text-center text-[#1375A3] mx-[30px] w-[40px] h-[40px]">
                {data?.length}
              </div>
            </div>
          </div>
          <div className="KafilTable my-[40px]">
            <div className="orphan-table-grid text-[#6F838E] DashboardMainPage-res">
              <div />
              <div>
                <Text tid="Priority" />
              </div>
              <div className="identifire_code">
                <Text tid="ID Code" />{" "}
              </div>
              <div>
                <span className="yearly_salary">
                  <Text tid="yearly" />/
                </span>
                <span>
                  {" "}
                  <Text tid="Monthly fee" />{" "}
                </span>
              </div>
              <div />
            </div>
            <div className="row_wrapper">
              {data &&
                data.length > 0 &&
                data.map((item) => (
                  <OrphanRow
                    key={item.id}
                    setkafilpopup={setkafilpopup}
                    orphan={item}
                    setOrphanId={setOrphanId}
                    setIsOpen={setIsOpen}
                    setDeselectOphanId={setDeselectOphanId}
                  />
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="my-5">
          <Card>
            <h4> {data?.message} </h4>
          </Card>
        </div>
      )}
      <div className="d-flex-space flex-col-responsive">
        <div className="d-flex-align">
          <button className="Tag " onClick={() => prevStep()}>
            <Text tid="previous stage"/> 
          </button>
          <Button
            varient="primary"
            onClick={handleNextStep}
            className={`button--green ${
              userLanguage === "en" ? "ml-4" : "mr-4"
            } `}
          >
            <Text tid="next stage" />
          </Button>
        </div>
      </div>
      <MessageDeleteModal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        onOk={handleConfirmDelete}
      />

      <ToastContainer />
    </>
  );
};

export default KafilStep2;
