import React,{useEffect} from "react";
import arrow from "../assets/img/icons/footer-arrow.svg";
import AboutUscontainer from "../Components/layouts/About/AboutUscontainer";
const AboutUs = ({setOpen}) => {
  useEffect(() => {
    setOpen(false)
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="AboutUs">
      <div className="center-column ml-8">
        <h2 className="AboutUs__title">درباره ما بیشتر بدانید</h2>
        <div className="breadcrumbs">
          <div className="d-flex-align">
            <div>صفحه ی اصلی</div>
            <img src={arrow} className="breadcrumbs_arrow" />
            <div>درباره ما</div>
          </div>
        </div>
      </div>
      <AboutUscontainer />
      
    </div>
  );
};

export default AboutUs;
