import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { LanguageContext, Text, TextFunc } from '../../../context/LanguageContext';
import Input from '../../shared/Input'
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {SearchOutlined} from '@ant-design/icons';
import { useRef } from 'react';

const NewsSearch = () => {
  const [value , setValue] = useState("");
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors, isValid },
      } = useForm({
        defaultValues : {
          search : value 
        }
      });

      const navigate = useNavigate();
      const {userLanguage} = useContext(LanguageContext);
      

      const handleKeyUp = async (e) => {
        if (e.keyCode === 13) {
          handleSearchInput(e.target.value)
        }
        setValue(e.target.value)
      };

      const handleClickIcon = () => {
        handleSearchInput(value)
      }

      const handleSearchInput = (inputValue) => {
         navigate({
            pathname: "/news/search",
            search: `?keyword=${inputValue}`,
          });
        
      }

  return (
    <div className='NewsSearch blue-box p-10'>
        <h4 className='News__title'><Text tid='Quick search' /></h4>

        <Input
            name="search"
            control={control}
            errors={errors}
            rtl = "true"
            register={{
              required: true,
            }}
            inputIcon = {<SearchOutlined />}
            iconClassName = "news_search_icon"
            placeholder= { TextFunc("Quick search") }
            onKeyUp = {handleKeyUp}
            onClickIcon = {handleClickIcon} 
          />
        
    </div>
  )
}

export default NewsSearch