import React, { useState } from "react";
import HomeHelpForm from "./HomeHelpForm";

const HomeHelpResponsive = () => {
  const [tabs, setTabs] = useState(1); //1 2 3
  const changeTab = (value) => {
    setTabs(value);
  };
  return (
    <div className="HomeHelpResponsive mt-5">
      <div className="container">
        <div className="HomeHelpResponsive__tabbutton">
          <button
            className={`HomeHelpResponsive__tabbutton-btn  HomeHelpResponsive__tabbutton-btn--1 ${
              tabs === 1 ? "HomeHelpResponsive__tabbutton-btn--1-active" : ""
            }`}
            onClick={() => changeTab(1)}
          >
            کمک مالی
          </button>
          <button
            onClick={() => changeTab(2)}
            className={`HomeHelpResponsive__tabbutton-btn  HomeHelpResponsive__tabbutton-btn--2 ${
              tabs === 2 ? "HomeHelpResponsive__tabbutton-btn--2-active" : ""
            }`}
          >
            نذورات
          </button>
        </div>
        <div>
          {tabs === 1 ? (
            <div className="HomeHelpResponsive__tabcontent">
              <p className="HomeHelpResponsive__tabcontent--1 ">
                او در توصبف لورم ایپسوم یا طرح‌نما (به انگلیسی: Lorem ipsum) به
                متنی آزمایشی و بی‌معنی در باور{" "}
              </p>
              <HomeHelpForm />
            </div>
          ) : tabs === 2 ? (
            <div className="HomeHelpResponsive__tabcontent">
              <p className="HomeHelpResponsive__tabcontent--2 ">
                او در توصبف لورم ایپسوم یا طرح‌نما (به انگلیسی: Lorem ipsum) به
                متنی آزمایشی و بی‌معنی در باور{" "}
              </p>
              <HomeHelpForm />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default HomeHelpResponsive;
