import { Tabs } from "antd";
import React, { useEffect } from "react";
import GiftTarakonesh from "../Components/layouts/Dashboard/Tarakonesh/GiftTarakonesh";
import AuthService from "../Services/AuthService ";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;
const Tarakonesh = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();

  useEffect(() => {
    if(currentUser?.role != "یتیم" && currentUser?.role != "کفیل"){
      navigate("/register")
    }  
  },[]);
  const onChange = (key) => {
    // console.log(key);
  };
  return (
    <div>
      <div className="primary-tab">
        {/* <Tabs defaultActiveKey="1" onChange={onChange}> */}
          {/* <TabPane
            tab="تراکنش اهدایی
"
            key="1"
          > */}
            <GiftTarakonesh/>
          {/* </TabPane>
          <TabPane tab="تراکنش نذر" key="2">
            Content of Tab Pane 2
          </TabPane>
          <TabPane tab="تراکنش نذورات" key="3">
            Content of Tab Pane 3
          </TabPane>
          <TabPane tab=" تراکنش الخمس" key="4">
            Content of Tab Pane 3
          </TabPane>
          <TabPane tab=" تراکنش زكاة" key="5">
            Content of Tab Pane 3
          </TabPane>
          <TabPane tab="تراکنش الفطر" key="6">
            Content of Tab Pane 3
          </TabPane> */}
        {/* </Tabs> */}
      </div>
    </div>
  );
};

export default Tarakonesh;
