import React, { useState } from "react";
import warning from "../../../../assets/img/icons/warning-mosala.png";
import Button from "../../../shared/Button";
import { useEffect } from "react";
import SponsorService from "../../../../Services/SponsorService";
import { toast } from "react-toastify";
import { SyncOutlined } from "@ant-design/icons";
import { LanguageContext, Text } from "../../../../context/LanguageContext";
import { useContext } from "react";

const KafilStep3 = ({ nextStep, prevStep, finalSelectedOrphansList }) => {
  const [loading, setLoading] = useState(false);
  const { userLanguage } = useContext(LanguageContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAcceptOrphans = async () => {
    setLoading(true);
    const res = await SponsorService.saveAssignOrphans({
      orphans: finalSelectedOrphansList,
    });
    if (res.status == 200) {
      toast(res?.data?.message, {
        position: "top-right",
        type: "success",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    } else {
      toast(res?.message, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    }
    if (finalSelectedOrphansList.length > 0) {
      nextStep();
    }
  };
  return (
    <div>
      <h3 className="text-[#313E45] text-[30px] header-text">
        {" "}
        <Text tid="Orphans under your care" />{" "}
      </h3>
      <div className="d-flex ">
        <img src={warning} className="mx-[10px]" />
        <p className="text-[18px] mb-0">
          {userLanguage == "fa"
            ? ` شما ${finalSelectedOrphansList.length} یتیم را برای نگهداری انتخاب کرده
          اید. در صورت عدم پرداخت حق سرپرستی امتیاز شما کسر خواهد شد`
            : userLanguage == "ar"
            ? `لقد اخترت ${finalSelectedOrphansList.length} أيتام لرعايتهم. إذا لم تدفع رسوم الوصاية ، فسيتم خصم نقاطك`
            : `You have selected ${finalSelectedOrphansList.length} orphans to care for. If you do not pay the guardianship fee, your points will be deducted`}
        </p>
      </div>
      <div className="center my-[40px] accept-orphan">
        <button className="Tag " onClick={() => prevStep()}>
          <Text tid="previous stage" />
        </button>
        <Button
          varient="primary"
          disabled={loading}
          onClick={handleAcceptOrphans}
          className="button--green mx-4"
        >
          {loading ? (
            <SyncOutlined style={{ fontSize: "22px" }} spin />
          ) : (
            <Text tid="Confirm and submit" />
          )}
        </Button>
      </div>
    </div>
  );
};

export default KafilStep3;
