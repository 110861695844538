import { useEffect } from "react";
import PaymentService from "../Services/PaymentService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { Card, CardText, CardTitle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-regular-svg-icons";
import Button from "../Components/shared/Button";
import LoadingSpinner from "../Components/shared/LoadingSpinner";

const PaymentDonation = () => {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [loading , setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const PaymentVerify = async () => {
      setLoading(true)
      const res = await PaymentService.PaymentDonationVerify(
        searchParams.get("Authority"),
        searchParams.get("Status")
      );
      if (res?.status == 200) {
        setMessage(res.data?.data?.Message);
        setLoading(false)
      } else {
        setMessage(res.data?.Message ? res.data.Message : res.message);
        setLoading(false)
      }
      setStatus(res?.status);
    };
    PaymentVerify();
  }, []);
  return (
    <div
      className={` fixed w-full h-full flex justify-center items-center left-0 right-0 top-0 bottom-0 overflow-hidden z-[1000] ${
        status == 200 ? "bg-green-400" : "bg-[#fbf7f7]"
      } `}
    >
      <div className="row w-full justify-center">
       {
        loading ? <LoadingSpinner /> :  <div className=" col-12 col-sm-7 col-lg-6">
        <Card
          className="p-5 justify-center items-center"
          style={{ with: "100%", minHeight: "200px" }}
        >
          {status == 200 ? (
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="text-success text-[60px]"
            />
          ) : (
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="text-danger text-[60px]"
            />
          )}
          <CardText className="mt-2 fw-bold">{message}</CardText>
          <Button
            varient="primary"
            className="button--green"
            onClick={() => navigate("/")}
          >
            بازگشت به سایت
          </Button>
        </Card>
      </div>
       }
      </div>
    </div>
  );
};

export default PaymentDonation;
