import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { LanguageContext } from "../context/LanguageContext";

const useAlertPayment = (currentDate, deadlineDate) => {
  const [alertMessage, setAlertMessage] = useState("");
  const { userLanguage } = useContext(LanguageContext);
  useEffect(() => {
    const diffMonth =
      deadlineDate?.getMonth() -
      currentDate?.getMonth() +
      12 * (deadlineDate?.getFullYear() - currentDate?.getFullYear());

    if (currentDate?.getTime() > deadlineDate?.getTime()) {
      switch (userLanguage) {
        case "fa":
          Math.abs(diffMonth) == 0
            ? setAlertMessage(`زمان سررسید پرداخت گذشته است`)
            : setAlertMessage(
                ` ${Math.abs(diffMonth)} ماه از زمان سررسید پرداخت گذشته است `
              );
          break;
        case "en":
          Math.abs(diffMonth) == 0
          ? setAlertMessage(`The payment deadline has expired`)
          : setAlertMessage(
            `${Math.abs(diffMonth)} months have passed since the payment due date`
            );
          break;
        case "ar":
          Math.abs(diffMonth) == 0
          ? setAlertMessage("انتهى الموعد النهائي للدفع")
          : setAlertMessage(
              `انقضت ${Math.abs(diffMonth)} أشهر منذ تاريخ استحقاق الدفع `
            );
          break;

        default:
        case "fa":
          Math.abs(diffMonth) == 0
            ? setAlertMessage(`زمان سررسید پرداخت گذشته است`)
            : setAlertMessage(
              `${Math.abs(diffMonth)} ماه از زمان سررسید پرداخت گذشته است`
              );
          break;
      }
    } else if (
      currentDate?.getTime() <= deadlineDate?.getTime() &&
      Math.abs(diffMonth) <= 1
    ) {
      const diffDay = deadlineDate?.getDate() - currentDate?.getDate();
      switch (userLanguage) {
        case "fa":
          Math.abs(diffMonth) < 1
            ? setAlertMessage(` روز به زمان پرداخت باقیمانده است ${diffDay}`)
            : setAlertMessage(
                `${Math.abs(diffMonth)} ماه به زمان  پرداخت باقیمانده است  `
              );
          break;
        case "en":
          Math.abs(diffMonth) < 1
            ? setAlertMessage(`${diffDay} day left to pay `)
            : setAlertMessage(` ${Math.abs(diffMonth)  + `month left to pay`}  `);
          break;
        case "ar":
          Math.abs(diffMonth) < 1
            ? setAlertMessage(`${diffDay} اليوم المتبقي للدفع`)
            : setAlertMessage(`${Math.abs(diffMonth)} الشهر المتبقي للدفع`);
          break;

        default:
        case "fa":
          Math.abs(diffMonth) < 1
            ? setAlertMessage(` روز به زمان پرداخت باقیمانده است ${diffDay}`)
            : setAlertMessage(
                `ماه به زمان  پرداخت باقیمانده است  ${Math.abs(diffMonth)}`
              );
          break;
      }
    }
  }, []);

  return alertMessage;
};

export default useAlertPayment;
