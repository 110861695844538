import React, { useContext, useEffect } from "react";
import { Controller, set, useForm } from "react-hook-form";
import Input from "../Components/shared/Input";
import Textarea from "../Components/shared/Textarea";
import UploadMadrak from "../Components/Common/UploadMadrak";
import UploadCover from "../Components/shared/UploadCover";
import Button from "../Components/shared/Button";
import { LanguageContext, Text, TextFunc } from "../context/LanguageContext";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import OrphanService from "../Services/OrphanService";
import { ToastContainer, toast } from "react-toastify";
import Select from "../Components/shared/Select";
import ReportsService from "../Services/ReportsService";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../Components/shared/LoadingSpinner";
import DateInput from "../Components/shared/DateInput";
import { SyncOutlined } from "@ant-design/icons";
import { DateObject } from "react-multi-date-picker";
import arabic from "react-date-object/calendars/arabic";
import persian from "react-date-object/calendars/persian";
import AuthService from "../Services/AuthService ";
const Report = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [fileName, setFileName] = useState("");
  const [reportTypes, setReportTypes] = useState("");
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    watch,
    register,
    reset,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm();


  useEffect(() => {
    window.scrollTo(0, 0);
    const getOrphan = async () => {
      setLoading(true);
      const res = await OrphanService.getOrphanById(
        searchParams.get("orphanId")
      );
      if (res?.status === 200) {
        res?.data?.data?.name && setValue("orphan_id", res?.data?.data?.name);
        setLoading(false);
      } else {
        toast(res?.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
      getOrphan();
  }, []);

  useEffect(() => {
    const getReportsType = async () => {
      setLoading(true);
      const res = await ReportsService.getReportsType(userLanguage);
      if (res?.status === 200) {
        setReportTypes(res.data?.data);
        setLoading(false);
      } else {
        toast(` Report Type ${res?.message}`, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    getReportsType();
  }, []);

  const onSubmit = async (data, e) => {
    let date;
    switch (userLanguage) {
      case "fa":
        date = new DateObject({
          calendar: persian,
          date: `${data.report_date.year}/${data.report_date.month.number}/${data.report_date.day}`,
        })
          .convert()
          .format();
        break;

      case "ar":
         date = new DateObject({
          calendar: arabic,
          date: `${data.report_date.year}/${data.report_date.month.number}/${data.report_date.day}`,
        })
          .convert()
          .format();
        break;

      default:
        date = data.report_date.format()
    }
    setLoadingBtn(true);
    const res = await ReportsService.PostReport(userLanguage, {
      ...data,
      orphan_id: searchParams.get("orphanId"),
      report_date: date,
    });
    if (res?.status == 200) {
      toast(res?.data?.message, {
        position: "top-right",
        type: "success",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      reset({
        attachment: "",
        date: "",
        description: "",
        orphan: "",
        report_type: "",
        description: "",
        title: "",
      });
      setFileName("");
      setLoadingBtn(false);
    } else {
      toast(res?.message, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingBtn(false);
    }
  };

  const resetForm = () => {
  navigate(-1)
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <h3 className="text-[#313E45] text-[30px]">
            <Text tid="Report" />
          </h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-2 gap-[20px] my-[20px] grid-cols-1-responsive">
              <Input
                name="title"
                control={control}
                errors={errors}
                register={{
                  required: TextFunc("The title field is required") ,
                }}
                className="Footer__input"
                placeholder={TextFunc("The title of the report")}
              />
              <Select
                name="report_type"
                control={control}
                errors={errors}
                defaultValue=""
                register={{
                  required: TextFunc("The type field is required")
                }}
              >
                <option value="" hidden>
                  {TextFunc("Report type")}
                </option>
                {reportTypes &&
                  reportTypes.length > 0 &&
                  reportTypes.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
              </Select>
              {/* <Input
          name="type"
          control={control}
          errors={errors}
          register={{
            required: true,
          }}
          className="Footer__input"
          placeholder="نوع گزارش"
        /> */}
              <Input
                name="orphan_id"
                control={control}
                errors={errors}
                register={{
                  required: TextFunc("The name of the orphan is required") ,
                }}
                className="Footer__input"
                placeholder={TextFunc("Orphan's name")}
              />
              <div>
                <DateInput
                  control={control}
                  name="report_date"
                  placeholder={TextFunc("Date")}
                  errors={errors}
                  register={{
                    required: TextFunc("The date field is required"),
                  }}
                />
              </div>
            </div>
            <Textarea
              name="description"
              control={control}
              errors={errors}
              register={{
                required: TextFunc("The description field is required") ,
              }}
              className="Footer__input"
              placeholder={TextFunc("Description")}
            />
            <div className="grid grid-cols-2  gap-[20px] my-[20px] grid-cols-1-responsive">
              <UploadCover
                id="uploadReport"
                text={fileName ? fileName : <Text tid="Upload a file" />}
                name="attachment"
                control={control}
                errors={errors}
                setFileName={setFileName}
              />
            </div>
            <div className="flex justify-start mt-[50px]">
              <button
                type="reset"
                onClick={resetForm}
                className={`text-[#6F838E] ${
                  userLanguage === "en" ? "mr-[20px]" : "ml-[20px]"
                }`}
              >
                <Text tid="Cancel" />
              </button>
              <Button
                varient="primary"
                className="button--green"
                disabled={loadingBtn}
              >
                {loadingBtn ? (
                  <SyncOutlined style={{ fontSize: "22px" }} spin />
                ) : (
                  <Text tid="Save changes" />
                )}
              </Button>
            </div>
          </form>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Report;
