import React, { useState, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Footer from "../Components/Common/Footer";
import Menu from "../Components/Common/Menu";
import ResponsiveFootermenu from "../Components/Common/responsiveFootermenu";
import SubFooter from "../Components/Common/SubFooter";
import SubMenu from "../Components/Common/SubMenu";
import AboutUs from "../layout/AboutUs";
import Contactus from "../layout/Contactus";
import Dashboard from "../layout/Dashboard";
import DashboardHome from "../layout/DashboardHome";
import EditSponsor from "../layout/EditSponsor";
import DashboardLayout from "../layout/DashboardLayout";
import Faq from "../layout/Faq";
import Gallery from "../layout/Gallery";
import GalleryVideoDetail from "../layout/GalleryVideoDetail";
import Home from "../layout/Home";
import News from "../layout/News";
import NewsDetail from "../layout/NewsDetail";
import Register from "../layout/Register";
import Tarakonesh from "../layout/Tarakonesh";
import GalleryVideo from "../layout/GalleryVideo";
import Message from "../layout/Message";
import MessageDetail from "../layout/MessageDetail";
import DashboardInfos from "../layout/DashboardInfos";
import Kafil from "../layout/Kafil";
import KafilSteps from "../Components/layouts/Dashboard/kafil/KafilSteps";
import Report from "../layout/Report";
import ReportList from "../layout/ReportList";
import Art from "../layout/Art";
import DeletMessage from "../layout/DeletMessage";
import DashboardMainPage from "../layout/DashboardMainPage";
import Payment from "../layout/Payment";
import Payment2 from "../layout/Payment2";
import ReportDetail from "../layout/ReportDetail";
import ModalStrap from "../Components/shared/ModalStrap";
import KafilTablePopup from "../Components/layouts/Dashboard/kafil/KafilTablePopup";
import KafilPopup from "../Components/layouts/Dashboard/kafil/KafilPopup";
import MessageDeleteModal from "../Components/layouts/Dashboard/Message/MessageDeleteModal";
import NewsCategory from "../layout/NewsCategory";
import NewsContent from "../Components/layouts/News/NewsContent";
import NewsSearchResult from "../layout/NewsSearchResult";
import NewsTag from "../layout/NewsTag";
import { Modal } from "reactstrap";
import OrphanInfos from "../layout/OrphanInfos";
import OrphanReports from "../layout/OrphanReports";
import AddMessage from "../layout/AddMessage";
import AuthService from "../Services/AuthService ";
import PaymentResult from "../layout/PaymentResult";
import PaymentDonation from "../layout/PaymentDonation";
import PaymentUserDonation from "../layout/PaymentUserDonation";

function RequireAuth({ children, roles = [] }) {
  const currentUser = AuthService.getCurrentUser();
  return currentUser?.token && roles.includes(currentUser?.role) ? (
    children
  ) : (
    <Navigate to="/register" replace />
  );
}

const Router = () => {
  const [open, setOpen] = useState(false); //for menu responsive
  const [kafilpopup, setkafilpopup] = useState(false);
  const [orphanId, setOrphanId] = useState(null);
  const [removes, setremove] = useState(false);
  const [orphanChosenToPay, setOrphanChosenToPay] = useState([]);
  const toggle = () => setkafilpopup(!kafilpopup);
  const toggleDeleteModal = () => setremove(!removes);

  let location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, []);

  return (
    <div>
      <div>
        <Menu />
        <SubMenu open={open} setOpen={setOpen} />

        <div onClick={() => setOpen(false)}>
          <Routes>
            <Route path="/" element={<Home setOpen={setOpen} />} />
            <Route path="/register" element={<Register setOpen={setOpen} />} />
            <Route path="/About-us" element={<AboutUs setOpen={setOpen} />} />
            <Route
              path="/Contact-us"
              element={<Contactus setOpen={setOpen} />}
            />
            <Route path="/FAQ" element={<Faq setOpen={setOpen} />} />
            <Route 
            path="/verifyPaymentDonation"
            element={<PaymentDonation />}
            />
            <Route element={<News setOpen={setOpen} />}>
              <Route path="/news" element={<NewsContent />} />
              <Route
                path="news/detail"
                element={<NewsDetail setOpen={setOpen} />}
              />
              <Route
                path="news/category"
                element={<NewsCategory setOpen={setOpen} />}
              />
              <Route
                path="news/search"
                element={<NewsSearchResult setOpen={setOpen} />}
              />
              <Route path="news/tags" element={<NewsTag setOpen={setOpen} />} />
            </Route>

            <Route path="/gallery" element={<Gallery setOpen={setOpen} />} />
            <Route
              path="/gallery-video"
              element={<GalleryVideo setOpen={setOpen} />}
            />
            <Route
              path="/gallery-video-detail"
              element={<GalleryVideoDetail setOpen={setOpen} />}
            />
            <Route element={<DashboardLayout />}>
              <Route
                path="/dashboard/"
                element={
                  <RequireAuth roles={["ناظر"]}>
                    <DashboardMainPage
                      setkafilpopup={setkafilpopup}
                      setOrphanId={setOrphanId}
                    />
                  </RequireAuth>
                }
              />
              <Route path="/dashboard/dash" element={<DashboardHome />} />
              <Route
                path="/dashboard/editprofile"
                element={
                  <RequireAuth roles={["کفیل"]}>
                    <EditSponsor />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/info"
                element={
                  <RequireAuth roles={["کفیل"]}>
                    <DashboardInfos />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/tarakonesh"
                element={
                  <RequireAuth roles={["کفیل", "یتیم"]}>
                    <Tarakonesh setremove={() => setremove(true)} />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/kafil"
                element={
                  <RequireAuth roles={["کفیل"]}>
                    <Kafil
                      setkafilpopup={setkafilpopup}
                      setOrphanId={setOrphanId}
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/message"
                element={
                  <RequireAuth roles={["کفیل", "یتیم", "ناظر"]}>
                    <Message />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/addMessage"
                element={
                  <RequireAuth roles={["کفیل", "یتیم", "ناظر"]}>
                    <AddMessage />
                  </RequireAuth>
                }
              />

              <Route
                path="/dashboard/report/:id"
                element={
                  <RequireAuth roles={["کفیل", "یتیم", "ناظر"]}>
                    <ReportDetail />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/message/:id"
                element={
                  <RequireAuth roles={["کفیل", "یتیم", "ناظر"]}>
                    <MessageDetail />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/report"
                element={
                  <RequireAuth roles={["ناظر"]}>
                    <Report />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/reportlist"
                element={
                  <RequireAuth roles={["ناظر"]}>
                    <ReportList
                      setremove={() => setremove(true)}
                      setkafilpopup={setkafilpopup}
                      setOrphanId={setOrphanId}
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/orphanReports/:orphanId"
                element={
                  <RequireAuth roles={["کفیل", "یتیم", "ناظر"]}>
                    <OrphanReports />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/art"
                element={
                  <RequireAuth roles={["یتیم"]}>
                    <Art />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/addkafil"
                element={
                  <RequireAuth roles={["کفیل"]}>
                    <KafilSteps
                      setremove={setremove}
                      setkafilpopup={setkafilpopup}
                      setOrphanId={setOrphanId}
                    />
                  </RequireAuth>
                }
              />
              {/* <Route
                path="/dashboard/deletmessage"
                element={<DeletMessage />}
              /> */}
              <Route
                path="/dashboard/payment"
                element={
                  <RequireAuth roles={["کفیل"]}>
                    <Payment
                      setOrphanId={setOrphanId}
                      orphanChosenToPay={orphanChosenToPay}
                      setkafilpopup={setkafilpopup}
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/payment2"
                element={
                  <RequireAuth roles={["کفیل"]}>
                    <Payment2
                      setkafilpopup={setkafilpopup}
                      setOrphanChosenToPay={setOrphanChosenToPay}
                      setOrphanId={setOrphanId}
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/orphanInfo"
                element={
                  <RequireAuth roles={["یتیم"]}>
                    <OrphanInfos />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="/dashboard/verifyPayment"
              element={
                <RequireAuth roles={["کفیل"]}>
                  <PaymentResult />
                </RequireAuth>
              }
            />
              <Route 
            path="/dashboard/verifyPaymentUserDonation"
            element={<PaymentUserDonation />}
            />

          </Routes>
        </div>
        <ResponsiveFootermenu />
        <Footer />
        <SubFooter />
      </div>

      <ModalStrap isOpen={kafilpopup} toggle={toggle} centered={true}>
        <KafilPopup orphanId={orphanId} />
      </ModalStrap>

      {/* <Modal remove={() => setremove(false)}  >
          <MessageDeleteModal onCancel={() => setremove(false)} />
        </Modal> */}
    </div>
  );
};

export default Router;
