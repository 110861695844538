import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://aytameamiralmomenin.com/api/";
class PaymentService {
  getCurrencies = () => {
    return axios
      .get(API_URL + `payment/go-to-pay`, {
        headers: authHeader(),
      })
      .catch((error) => {
        if (error) {
          return error;
        }
      });
  };

  Payment = (data, userLanguage) => {
    return axios
      .post(API_URL + `${userLanguage}/payment`, data, {
        headers: {
          ...authHeader(),
        },
      })
      .catch((error) =>
        error.response.data.data.Message
          ? error.response.data.data.Message
          : error.message
      );
  };

  PaymentDonation = (data, userLanguage) => {
    return axios
      .post(API_URL + `${userLanguage}/payment-donation`, {...data , payment_type: "donation"})
      .catch((error) =>
        error.response.data.data.Message
          ? error.response.data.data.Message
          : error.message
      );
  };

  PaymentUserDonation = (data , userLanguage ) => {
    return axios
    .post(API_URL + `${userLanguage}/payment-user-donation`, {...data , payment_type: "donation" }, {
      headers: {
        ...authHeader(),
      },
    })
    .catch((error) =>
      error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }

  PaymentVerify = (Authority, Status) => {
    return axios
      .get(
        API_URL +
          `payment/verifyPayment?Authority=${Authority}&Status=${Status}`,
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error) {
          return error;
        }
      });
  };

  PaymentDonationVerify = (Authority, Status) => {
    return axios
      .get(
        API_URL +
          `payment/verifyPayment-donation?Authority=${Authority}&Status=${Status}`,
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error) {
          return error;
        }
      });
      
    };
    
    
  PaymentUserDonationVerify = (Authority, Status) => {
    return axios
      .get(
        API_URL +
          `payment-user/verifyPayment-donation?Authority=${Authority}&Status=${Status}`,
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error) {
          return error;
        }
      });
  };
}

export default new PaymentService();
