import { Card, Pagination } from "antd";
import React, { useContext, useEffect, useState } from "react";
import profile from "../../../../assets/img/icons/person-people-girl-portrait-model-child-730652-pxhere.com.png";
import heart from "../../../../assets/img/icons/red-heart.png";
import tajob from "../../../../assets/img/icons/tajob-circle.png";
import tajobabi from "../../../../assets/img/icons/tajob-abi.png";
import checks from "../../../../assets/img/icons/check.png";
import { ReactComponent as Arrow } from "../../../../assets/img/icons/arrow-down.svg";
import Button from "../../../shared/Button";
import {
  LanguageContext,
  Text,
  TextFunc,
} from "../../../../context/LanguageContext";
import { ToastContainer, toast } from "react-toastify";
import OrphanService from "../../../../Services/OrphanService";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../../shared/LoadingSpinner";
import Select from "../../../shared/Select";
import user from "../../../../assets/img/dashboard/blank.png";
import SponsorService from "../../../../Services/SponsorService";
import AuthService from "../../../../Services/AuthService ";

let selectedOrphansArray = [];
const OrphanRow = ({ setkafilpopup, orphan, setOrphanId }) => {
  const [check, setCheck] = useState(false);

  const navigate = useNavigate();

  const handleDetailClick = (e, orphan_id) => {
    e.stopPropagation();
    setkafilpopup(true);
    setOrphanId(orphan_id);
  };

  const handleSelectOrphnas = (orphan_id) => {
    if (selectedOrphansArray.indexOf(orphan_id) === -1) {
      selectedOrphansArray = [...selectedOrphansArray, orphan_id];
    } else {
      selectedOrphansArray.splice(selectedOrphansArray.indexOf(orphan_id), 1);
    }
    setCheck(!check);
  };

  return (
    <div
      className={`orphan-table-grid rounded-[10px] ${
        selectedOrphansArray.indexOf(orphan.id) != -1
          ? "bg-[#1375A3] text-[#fff]"
          : "bg-[#F4F8FA] text-[#313E45]"
      } p-8 my-2 kafilrow-res DashboardMainPage-res`}
      onClick={() => handleSelectOrphnas(orphan.id)}
    >
      <div className="d-flex-align">
        <div className=" relative ">
          {/* <div className="rounded-[50%] w-[20px] h-[20px] bg-[#FF1D25] text-[#fff] absolute right-0 top-0  badge-res">
            1
          </div> */}
          <img
            className="rounded-[50%] w-[50px] h-[50px] border-[2px] border-[#fff] overflow-hidden res-profile"
            src={orphan?.avatar || user}
            alt={orphan?.name}
          />
        </div>
        <div className="mx-3 text-right">
          <div
            style={{ fontWeight: "500" }}
            className={`${
              selectedOrphansArray.indexOf(orphan.id) != -1
                ? "text-[#fff] "
                : "text-[#313E45]"
            }  font-res-header f-w-500`}
          >
            {`${orphan?.name}`}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <img src={heart} className="mx-1" />
        {orphan?.priority}
      </div>
      <div className="identifire_code">{orphan.identification_code}</div>
      <div className="dir-ltr">
        <span className="yearly_salary">$ {orphan.yearly_salary} / </span>
        <span>
          $ {parseFloat(Number(orphan?.yearly_salary) / 12).toFixed(2)}
        </span>
      </div>
      <div className="flex justify-between add_orphan_table_action">
        <div
          className="flex items-center cursor-pointer"
          onClick={(e) => handleDetailClick(e, orphan.id)}
        >
          <img
            src={
              selectedOrphansArray.indexOf(orphan.id) != -1 ? tajob : tajobabi
            }
            alt=""
            className="mx-2 w-[15px] h-[15px]"
          />
          <div
            className={`underline details ${
              selectedOrphansArray.indexOf(orphan.id) == -1
                ? "text-[#1375A3]"
                : "text-[#fff]"
            }`}
          >
            <Text tid="Details" />
          </div>
        </div>
        <div className="d-flex-align select_btn">
          <div className="text-[#2DCA73]">
            <Text tid="Select" />
          </div>
          {selectedOrphansArray.indexOf(orphan.id) != -1 ? (
            <img className="mx-2" src={checks} />
          ) : (
            <span className="mx-2 uncheck"></span>
          )}
        </div>
      </div>
    </div>
  );
};
const KafilStep1 = ({
  nextStep,
  setkafilpopup,
  setOrphanId,
  setSelectedOrphansList,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({});
  const [current, setCurrent] = useState(1);
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [searchParams] = useSearchParams();
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const getOrphans = async () => {
      setLoading(true);
      const res = getValues().filter_orphan
        ? await OrphanService.searchOrphan(filterData, searchParams.get("page"))
        : await SponsorService.getAssignableOrphans(searchParams.get("page"));
      if (res?.status === 200) {
        setData(res?.data?.data?.data);
        setMessage(res?.data?.message);
        setPage(res?.data?.data);
        setLoading(false);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    setCurrent(
      searchParams.get("page")
        ? Number(Object.fromEntries([...searchParams]).page)
        : 1
    );
    getOrphans();
  }, [searchParams.get("page"), filterData]);

  const onChangePage = (pageNumber) => {
    setCurrent(pageNumber);
    navigate({
      pathname: "/dashboard/addkafil",
      search: `?page=${pageNumber}`,
    });
  };

  const handleChangeFilter = async () => {
    const filterValue = getValues();
    const data = { filter_orphans: filterValue.filter_orphan, assignable: 1 };
    setFilterData(data);
  };

  const handleNextStep = () => {
    if (selectedOrphansArray.length > 0) {
      nextStep();
    } else {
      toast(<Text tid="Please choose an orphan to take care of" />, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setSelectedOrphansList(selectedOrphansArray);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : data && data.length > 0 ? (
        <div>
          <div className="flex items-center justify-between flex-col-responsive ">
            <div className="d-flex-align ">
              <h3 className="text-[#313E45] text-[30px] mb-0 title">
                <Text tid="List of orphans" />
              </h3>
              <div className="blue-back font-bold text-center text-[#1375A3] mx-[30px] w-[40px] h-[40px]">
                {page?.total}
              </div>
            </div>
            <div className="primary-select filter-select-wrapper">
              <form>
                <Select
                  name="filter_orphan"
                  control={control}
                  errors={errors}
                  className="Footer__input filter-select"
                  value=""
                  handleChange={handleChangeFilter}
                >
                  <option value="" hidden disabled>
                    {TextFunc("Sort By")}
                  </option>
                  <option className="Select--option" value="">
                    {TextFunc("all")}
                  </option>
                  <option className="Select--option" value="is_sayyed">
                    {TextFunc("being a sayyed")}
                  </option>

                  <option className="Select--option" value="not_sayyed">
                    {TextFunc("not being a sayyed")}
                  </option>

                  <option className="Select--option" value="high_priority">
                    {TextFunc("Highest priority")}
                  </option>
                  <option className="Select--option" value="low_priority">
                    {TextFunc("Lowest priority")}
                  </option>
                  <option className="Select--option" value="female">
                    {TextFunc("being a girl")}
                  </option>
                  <option className="Select--option" value="male">
                    {TextFunc("being a boy")}
                  </option>
                </Select>
              </form>
            </div>
          </div>
          <div className="KafilTable my-[40px]">
            <div className="orphan-table-grid text-[#6F838E] DashboardMainPage-res">
              <div />
              <div>
                <Text tid="Priority" />
              </div>
              <div className="identifire_code">
                {" "}
                <Text tid="ID Code" />{" "}
              </div>
              <div>
                <span className="yearly_salary">
                  <Text tid="yearly" />/
                </span>
                <span>
                  {" "}
                  <Text tid="Monthly fee" />{" "}
                </span>
              </div>
              <div />
            </div>
            <div className="row_wrapper">
              {data &&
                data.length > 0 &&
                data.map((item) => (
                  <OrphanRow
                    key={item.id}
                    setkafilpopup={setkafilpopup}
                    orphan={item}
                    setOrphanId={setOrphanId}
                  />
                ))}
            </div>
          </div>
          <div className="d-flex-space flex-col-responsive">
            <div className="d-flex-align">
              {/* <button className="Tag ">مرحله قبل</button> */}

              <Button
                disabled={disabled}
                varient="primary"
                onClick={handleNextStep}
                className={`button--green ${
                  userLanguage === "en" ? "ml-4" : "mr-4"
                } `}
              >
                <Text tid="next stage" />
              </Button>
            </div>
            <div className="res-mr-20">
              <div className="Pagination my-3 ">
                <Pagination
                  current={current}
                  pageSize={page.per_page}
                  total={page.total}
                  onChange={onChangePage}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="my-5">
          <Card>
            <h4> {message} </h4>
          </Card>
        </div>
      )}

      {/* <ToastContainer /> */}
    </>
  );
};

export default KafilStep1;
