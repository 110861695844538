import React, { useContext, useState } from "react";
import Accordion from "../../../shared/Accordion";
import Payam from "../../../../assets/img/icons/message.png";
import { ReactComponent as Arrow } from "../../../../assets/img/icons/arrow-down.svg";
import { Card, Pagination } from "antd";
import { useEffect } from "react";
import OrphanService from "../../../../Services/OrphanService";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../../shared/LoadingSpinner";
import persian from "react-date-object/calendars/persian";
import gregorian from "react-date-object/calendars/gregorian";
import arabic from "react-date-object/calendars/arabic";
import persian_fa from "react-date-object/locales/persian_fa";
import gregorian_en from "react-date-object/locales/gregorian_en";
import arabic_ar from "react-date-object/locales/arabic_ar";
import { DateObject } from "react-multi-date-picker";
import { LanguageContext, Text } from "../../../../context/LanguageContext";
import SponsorService from "../../../../Services/SponsorService";

const TarakoneshTableSponsor = ({ filterData }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({});
  const [current, setCurrent] = useState(1);
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const { userLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getTransactions = async () => {
      setLoading(true);
      const res = filterData?.filter
        ? await SponsorService.filterTransActions(
            searchParams.get("page"),
            filterData
          )
        : await SponsorService.transactions(searchParams.get("page"));
      if (res?.status === 200) {
        if (filterData?.filter) {
          setData(res?.data?.data);
          setPage(res?.data);
          setMessage(res?.message);
        } else {
          setData(res?.data?.data?.transactions?.data);
          setPage(res?.data?.data?.transactions);
          setMessage(res?.data?.message);
        }

        setLoading(false);
      } else {
        toast(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    };
    setCurrent(
      searchParams.get("page")
        ? Number(Object.fromEntries([...searchParams]).page)
        : 1
    );
    getTransactions();
  }, [searchParams.get("page"), filterData?.filter]);

  const onChangePage = (pageNumber) => {
    setCurrent(pageNumber);
    navigate({
      pathname: "/dashboard/tarakonesh",
      search: `?page=${pageNumber}`,
    });
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : data && data.length > 0 ? (
        <div className="TarakoneshTable mt-5 ">
          <div className="TarakoneshTable--heade table_header tarakonesh-table-grid text-[#6F838E] TarakoneshTable-res">
            <div><span className="payment_id"><Text tid="Transaction ID" /> </span>  </div>
            <div className="payment_type"> <Text tid="payment type" /> </div>
            <div className="payment_date"><Text tid="Date" /></div>
            <div className="payment_amount"><Text tid="amount" /></div>
            <div />
          </div>
          <div className="row_wrapper">
            {data.map((item) => (
              <Accordion
                key={item.id}
                header={
                  <div className="TarakoneshTable--body tarakonesh-table-grid TarakoneshTable-res">
                    <div className="flex items-center">
                      <div className="icon-box">
                        <img src={Payam} />
                      </div>
                      <h4 className="mx-4 font-bold text-[14px] mb-0 font-res-header f-w-500 payment_id">
                        {item.id}
                      </h4>
                    </div>
                    <div className="payment_type">
                      {item?.payment_type?.name}
                    </div>
                    <div className="payment_date">
                      {item?.pay_date
                        ? userLanguage == "fa"
                          ? new DateObject(new Date(item.pay_date))
                              .convert(persian, persian_fa)
                              .format()
                          : userLanguage == "ar"
                          ? new DateObject(new Date(item.pay_date))
                              .convert(arabic, arabic_ar)
                              .format()
                          : new DateObject(new Date(item.pay_date)).format()
                        : "---"}
                    </div>
                    <div className="payment_amount d-flex dir-ltr">
                      <span> {item?.currency_type?.name} </span>
                      <span> {item?.price} </span>
                    </div>
                    <div className="flex items-center justify-end">
                      <div className="transaction-details" > <Text tid="Details" /></div>
                      <div className="square-icon mx-4">
                        <Arrow />
                      </div>
                    </div>
                  </div>
                }
              >
                <div className="grid grid-cols-3 gap-[15px] my-[20px] grid-cols-1-responsive">
                  <div className="d-flex-align text-[12px] sm:text-[14px]">
                    <div className="text-[#6F838E]"><Text tid="payment type" /> </div>{" "}
                    <div className="mx-4">:</div>{" "}
                    <div className="text-[#313E45] font-bold">
                      {item?.payment_type?.name}
                    </div>
                  </div>
                  <div className="d-flex-align text-[12px] sm:text-[14px]">
                    <div className="text-[#6F838E]"><Text tid="Date" /></div>{" "}
                    <div className="mx-4">:</div>{" "}
                    <div className="text-[#313E45] font-bold">
                      {item?.pay_date
                        ? userLanguage == "fa"
                          ? new DateObject(new Date(item.pay_date))
                              .convert(persian, persian_fa)
                              .format()
                          : userLanguage == "ar"
                          ? new DateObject(new Date(item.pay_date))
                              .convert(arabic, arabic_ar)
                              .format()
                          : new DateObject(new Date(item.pay_date)).format()
                        : "---" }
                    </div>
                  </div>
                  <div className="d-flex-align text-[12px] sm:text-[14px]">
                    <div className="text-[#6F838E]"><Text tid="amount" /></div>{" "}
                    <div className="mx-4">:</div>{" "}
                    <div className="text-[#313E45] d-flex font-bold dir-ltr">
                      <span> {item?.currency_type?.name} </span>
                      <span> {item?.price} </span>
                    </div>
                  </div>
                  {/* <div className="d-flex-align text-[12px] sm:text-[14px]">
                    <div className="text-[#6F838E]">شماره حساب</div>{" "}
                    <div className="mx-4">:</div>{" "}
                    <div className="text-[#313E45] font-bold">الكترونی</div>
                  </div> */}
                </div>
                <div className="grid grid-cols-3 grid-cols-1-responsive gap-[15px]">
                  <div className="d-flex-align text-[12px] sm:text-[14px]">
                    <div className="text-[#6F838E]"><Text tid="payment platform" /></div>{" "}
                    <div className="mx-4">:</div>{" "}
                    <div className="text-[#313E45] font-bold">
                      {item?.gateway?.name}
                    </div>
                  </div>
                  <div className="d-flex-align text-[12px] sm:text-[14px] col-span-2">
                    <div className="text-[#6F838E]" > <Text tid="Tracking Code" /> </div>{" "}
                    <div className="mx-4">:</div>{" "}
                    <div className="text-[#313E45] font-bold" style={{wordBreak : "break-word"}} >
                      {item?.ref_id}
                    </div>
                  </div>
                </div>
              </Accordion>
            ))}
          </div>
          <div className="my-[40px]">
            {page.per_page ? (
              <div className="Pagination my-3">
                <Pagination
                  current={current}
                  pageSize={page.per_page}
                  total={page.total}
                  onChange={onChangePage}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="my-5">
          <Card>
            <h4> {message} </h4>
          </Card>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default TarakoneshTableSponsor;
